<template>
  <div class="main">
    <div class="container">
      <div class="middle">
        <div id="login">
          <form @submit.prevent="sendLogin">
            <fieldset class="clearfix">
              <p>
                <span class="bi bi-person-fill"></span>
                <input v-model="usuario" type="text" placeholder="Correo electrónico">
              </p>
              <p>
                <span class="bi bi-lock-fill"></span>
                <input v-model="password" type="password" placeholder="Contraseña">
              </p>

              <!-- Mejora: Selección del tipo de usuario con un menú desplegable -->
              <p>
                <span class="bi bi-person-badge-fill"></span>
                <select v-model="userType">
                  <option disabled value="">Seleccione tipo de usuario</option>
                  <option value="jobbing_admin">Administrador Jobbing</option>
                  <option value="property_manager">Administrador de Propiedades</option>
                </select>
              </p>

              <div class="actions">
                <a class="small-text" href="#">Olvidé mi contraseña</a>
                <button type="submit" class="btn btn-entrar">
                  Entrar
                  <div v-if="loading" class="spinner-border text-light" style="height:15px; width:15px;" role="status"></div>
                </button>
              </div>
            </fieldset>
          </form>
        </div> <!-- end login -->

        <div class="logo">
          <img src="@/assets/logolight.webp" alt="">
        </div>
      </div>
    </div>

    <div class="footer">
      Jobbing.cl | Términos y condiciones
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'LoginView',
  data() {
    return {
      usuario: '',
      password: '',
      loading: false,
      userType: '', // Mantener vacío inicialmente
    };
  },
  methods: {
    ...mapActions(['loginpro', 'loginPropertyManager']),
    sendLogin() {
      if (this.loading) return;

      if (!this.usuario || !this.password || !this.userType) {
        this.$notify({
          group: 'foo',
          title: 'Error',
          type: 'warn',
          text: 'Por favor, complete todos los campos.',
        });
        return;
      }

      this.loading = true;
      const userData = { email: this.usuario, password: this.password };

      if (this.userType === 'jobbing_admin') {
        this.loginpro(userData)
          .then(() => {
            this.loading = false;
            this.$router.push('/Dashboard');
            this.$notify({
              group: 'foos',
              title: '¡Éxito!',
              type: 'success',
              text: '¡Has iniciado sesión!',
            });
          })
          .catch((e) => {
            this.loading = false;
            this.$notify({
              group: 'foo',
              title: '¡Error!',
              type: 'warn',
              text: e.response.data,
            });
          });
      } else if (this.userType === 'property_manager') {
        this.loginPropertyManager(userData)
          .then(() => {
            this.loading = false;
            this.$router.push('/Home');
            this.$notify({
              group: 'foos',
              title: '¡Éxito!',
              type: 'success',
              text: '¡Has iniciado sesión!',
            });
          })
          .catch((e) => {
            this.loading = false;
            this.$notify({
              group: 'foo',
              title: '¡Error!',
              type: 'warn',
              text: e.response.data,
            });
          });
      } else {
        this.loading = false;
        this.$notify({
          group: 'foo',
          title: 'Error',
          type: 'warn',
          text: 'Tipo de usuario inválido.',
        });
      }
    },
  },
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

.main {
  background: radial-gradient(ellipse at center, #0264d6 1%, #1c2b5a 100%);
  height: 133vh;
  width: 100%;
}

.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.middle {
  display: flex;
  width: 600px;
  margin: 0 auto;
}

#login {
  width: 60%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.logo {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  max-height: 150px;
}

fieldset {
  border: none;
}

p {
  position: relative;
  margin-bottom: 1.5em;
}

p span {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 20px;
}

p input,
p select {
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
}

p select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small-text {
  color: #fff;
}

.btn-entrar {
  background-color: #ff8c00;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
}

.btn-entrar:hover {
  background-color: #e57c00;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgba(0,0,0,0.9);
  color: #fff;
  text-align: center;
  line-height: 40px;
}

/* Estilos adicionales para iconos en campos de selección */
p select {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px 16px;
}

p select::-ms-expand {
  display: none;
}

/* Responsivo */
@media (max-width: 800px) {
  .middle {
    flex-direction: column;
    width: 90%;
  }

  #login, .logo {
    width: 100%;
  }

  .logo {
    margin-bottom: 20px;
  }
}
</style>
