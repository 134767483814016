<template>
<div style="text-align:left;">

  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>
  <ModalImg ref="modalimg2" :link_img="showing_img_link"/>
  <ModalResponder :solicitud_="solicitud" :profesionales_="profesionales" ref="modalresponder" />
  <ModalFiltros @cambiar-filtro="cambiarFlitro" :filtros="filtros"  ref="modalfiltros" />
  <ModalAgregarComentario :solicitud_="solicitud" :profesionales_="profesionales" ref="modalagregarcomentario" />
  <ModalComentarios :solicitud_="solicitud" :profesionales_="profesionales" ref="modalcomentarios" />


  <div class="column-right">
    <div v-if="admin_datos.superadmin" class="main-container">       
      <div v-if="loaded" class="articulo-principal">
        

        <!--HOVER PRESUPUESTOS-->
        <div style="position: fixed; background-color: white; border-radius: 4px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; padding: 10px; max-width: 600px;" :style="{top: mouseY+5+'px', left: mouseX-100+'px'}" v-if="hovering == 'presupuesto' && viendo_presupuesto != null">
          Presupuesto por
<b>


 {{profesionales.find(p=>{ return p.id == viendo_presupuesto.profesional_id}).nombre}}
                  {{profesionales.find(p=>{ return p.id == viendo_presupuesto.profesional_id}).apellido}}
</b>, creado el <b>{{new Date(viendo_presupuesto.fecha_creado).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}}</b> y enviado el 
          <b>{{new Date(viendo_presupuesto.fecha_enviado).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}}</b>, con los items:
          <br/>
          <br/>
          <div v-for="d in viendo_presupuesto.desgloce.items">{{ d.nombre }} por <b>${{Intl.NumberFormat('de-DE').format(d.precio) }}</b></div>
          <br/>
    
          En estado <b>{{ viendo_presupuesto.estado == 2 ? 'Aprobado' : viendo_presupuesto.estado == 10 ? 'Rechazado' : 'Enviado' }}</b>
        </div>

        <div @mouseleave="" style="position: fixed; background-color: white; border-radius: 4px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; padding: 10px; max-width: 600px;" :style="{top: mouseY+5+'px', left: mouseX-100+'px'}" v-if="hovering == 'entradas' && viendo_entradas != null">
          <b>Entradas de bot</b>
          <div v-for="e in viendo_entradas">
            <b>{{new Date(e.fecha.replace(" GMT", "")).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric' })}}</b>
            {{profesionales.find(p=>{ return p.id == e.profesional_id}).nombre}}
                  {{profesionales.find(p=>{ return p.id == e.profesional_id}).apellido}} 
              <span v-if="e.should_respond == 0">Decidió no responder más.</span>
              <span v-else >Respondió {{ e.message }}</span>
        
                  
          </div>

        </div>

        <div style="position: fixed; background-color: white; border-radius: 4px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; padding: 10px; max-width: 600px;" :style="{top: mouseY+5+'px', left: mouseX-100+'px'}" v-if="hovering == 'orden' && viendo_orden != null">
          Orden de
<b>


 {{profesionales.find(p=>{ return p.id == viendo_orden.profesional_id}).nombre}}
                  {{profesionales.find(p=>{ return p.id == viendo_orden.profesional_id}).apellido}} en {{ viendo_orden.direccion }}
</b>, creado el <b>{{new Date(viendo_orden.fecha_creado.a, viendo_orden.fecha_creado.m, viendo_orden.fecha_creado.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}}</b> con los items:
        <div style="height: 10px;"></div>
   
          <div :style="{'color': d.pagado_id ? '#3D9970' :'#FF4136'}"  v-for="d in viendo_orden.servicios">{{ d.nombre }} por <b>${{Intl.NumberFormat('de-DE').format(d.precio) }}</b></div>
          <div style="height: 10px;"></div>
          <b>Pagos hechos:</b>
          <div v-if="p.estado == 1" v-for="p in viendo_orden.pagos">
            El {{new Date( p.fecha_pagado.y, p.fecha_pagado.m-1, p.fecha_pagado.d ).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}
            por $<b>{{ Intl.NumberFormat('de-DE').format(p.monto) }}</b> mediante {{ p.metodo_pago }}.
          </div>
          <div style="height: 10px;"></div>
          En estado <b>{{ estados_orden[viendo_orden.estado] }}</b>
        </div>

        <button @click="initialize_()">Actualziar <b-icon style="color:white !important;" icon="recycle"></b-icon></button>
        <button style="margin-left: 5px;" @click="$refs.modalfiltros.show()">Ver filtros <b-icon style="color:white !important; " icon="filter"></b-icon></button>
        <br/>
        <input placeholder="Filtra por profesional, descripcion, comuna, etc..." style="margin-top: 10px; width: 400px; outline: none; font-size: 14px; border:1px solid grey; border-radius: 4px; padding:4px;" v-model="palabra_buscada" type="text">

        <div style="margin-left: 5px; display: inline-block;">
          <div style="background-color: rgba(255,140,0,0.4); color:rgb(255,140,0); margin-right: 4px; padding: 0px 5px; border-radius: 6px; display: inline-block;" v-for="f in filtros.filter(x=>x.activo)">{{f.nombre}}</div>

        </div>
        
        <div style="position:absolute; right:0px; top:0px;"> 
          Desde <input id="x2" type="date" v-model="dt_dia" >
        </div>
        <table class="tablex">
    <thead>
        <tr>
            <th></th>
            <th>Visible</th>
            <th>💬</th>
            <th>🤖</th>
            <th>ID</th>
            <th>Ultimo mensaje</th>
            <th>Estado</th>
            <th>Presupuestos</th>
            <th>Ordenes</th>
            <th>Fecha</th>
            <th>Título</th>
            <th>Descripción</th>
            <th>Comuna</th>
            <th>Nombre</th>
            <th>Telefono</th>
            <th>Email</th>
            <th>Acciones</th>

        </tr>
    </thead>
    <tbody>
      <template v-for="s, u in solicitudes_filtradas">
        
        <tr  :style="{'background-color': s.atributos.marcada == 1 ? '#FFDC00': ''}" v-on:click="(event)=> { if(!event.target.classList.contains('no-ver')) s.viendo = !s.viendo; }" >
            <td >
              
              <div v-if="utima_respuesta_cliente(s)" style="background-color: #FF4136; color:white; height: 30px; font-size: 25px; width: 30px; font-weight: 700; display: flex; justify-content: center;  align-items: center;   border-radius: 100%;"><!-- <b-icon icon="exclamation-circle"></b-icon -->!</div>
              
              </td>
              <td>
                <div class="purple-label" v-if="s.profesional_id"> 
                {{profesionales.find(p=>{ return p.id == s.profesional_id}).nombre}}
                {{profesionales.find(p=>{ return p.id == s.profesional_id}).apellido}}
                </div>
                <div class="blue-label" v-else>Publica </div>
              </td>
              <td><div v-on:click="mostrarModalComentariosS(s)" class="n-comentarios no-ver" v-if="s.atributos.comentarios != undefined">{{ s.atributos.comentarios.length }}</div></td>
              
              <td>
             
               
                <div @mouseover="hoverItem($event, 'entradas'); viendo_entradas = s.entradas_autorespuesta;"  @mouseleave="hoverItem($event, '');" class="n-comentarios-bot no-ver" v-if="s.entradas_autorespuesta.length != 0">{{ s.entradas_autorespuesta.length }}</div>
                </td>
      
              <td >{{s.id}} </td>
              <td>
                <div v-if="s.ultimo_mensaje_fecha">
                  hace {{ ( getDateDifference(new Date(s.ultimo_mensaje_fecha.replace(" GMT", "")), new Date()))}} 
                </div>
                <div style="color:#FF4136; font-weight: 700;" v-else>
                  sin respuestas

                </div>
                </td>
            <td >
              <div style="color:#2ECC40; font-weight: 700;" v-if="s.pagado">Pagado <span :style="{'color': s.monto_pagado == s.monto_agregado ? 'rgba(46, 204, 64, 1)' : '#FFDC00'}">({{s.monto_pagado}} de {{ s.monto_agregado }})</span></div>
              <div style="color:#FFDC00; font-weight: 700;" v-else-if="s.tiene_orden">Orden Enviada</div>
              <div  style="color:#FF4136; font-weight: 700;" v-else>Sin Orden</div>
            </td>
            <td>
              <div v-if="s.tiene_presupuesto ">
                <tempalte v-for="o in s.ordenes">
                 
                  <div @mouseover="hoverItem($event, 'presupuesto'); viendo_presupuesto = pr;"  @mouseleave="hoverItem($event, ''); viendo_presupuesto = null;" style="border-radius: 5px; padding: 0px 2px;  margin-bottom: 10px;":style="{'background-color': pr.estado == 2  ? 'rgba(46, 204, 64, 0.6)' :  pr.estado == 10 ? '#FF4136' : '#FFDC00'}" v-if="pr.estado != 0" v-for="pr in o.presupuestos">
                  <span v-if="profesionales.find(p=>{ return p.id == pr.profesional_id})">
                    {{profesionales.find(p=>{ return p.id == pr.profesional_id}).nombre}}
                  {{profesionales.find(p=>{ return p.id == pr.profesional_id}).apellido}}
                  </span>
                  <span v-else>
                    NO SE ENCONTRÓ PROFESIONAL
                  </span>
                  por ${{Intl.NumberFormat('de-DE').format(pr.monto_total)}} en estado {{ pr.estado == 2 ? 'Aprobado' : pr.estado == 10 ? 'Rechazado' : 'Enviado' }}
                  </div>

                </tempalte>

              </div>
              <!-- {{ s.tiene_presupuesto }} -->
            </td>
            <td>
              <div @mouseover="hoverItem($event, 'orden'); viendo_orden = o;"  @mouseleave="hoverItem($event, ''); viendo_orden = null;" style="border-radius: 5px; padding: 0px 2px; margin-bottom: 10px;" :style="{'background-color': o.estado != 0  ? 'rgba(46, 204, 64, 0.6)' : '#FFDC00'}" v-for="o in s.ordenes">
                Id: <b>{{ o.id }}</b>, de                   {{profesionales.find(p=>{ return p.id == o.profesional_id}).nombre}}
                  {{profesionales.find(p=>{ return p.id == o.profesional_id}).apellido}} en estado <b>{{ estados_orden[o.estado] }}</b>

              </div>
            </td>
            <td >{{new Date(s.fecha_creado).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}}</td>
            <td >{{s.titulo}}</td>
            <td >{{s.descripcion}}</td>
            <td >{{comunas.find(x=>x.id == s.comuna_id).Nombre}}</td>
            <td style="font-weight: 700;" :style="{'color':s.usuario.verificado == 1 ? '#3D9970': '#FF4136'}" >{{s.nombre}}</td>
            <td >{{s.telefono}}</td>
            <td>{{ s.email }}</td>
            <td style="text-align: center;">
              <button class="no-ver" @click.prevent="mostrarModal(s)">Responder</button>
              <div style="height: 5px;"></div>
              <button class="no-ver" @click.prevent="mostrarModalComentario(s)">Agregar comentario</button>
              <div style="height: 5px;"></div>
              <button class="no-ver" @click.prevent="marcar_solicitud(s.id)">Marcar</button>
            </td>

        </tr>
        <tr  v-if="s.viendo">
            
          <td colspan="17">
            <div>
            <div class="attach-img" v-for="foto in s.fotos.filter(f=>{return f.mensaje_cliente_id == null && f.mensaje_profesional_id == null})" v-on:click="showImg(foto.link)" :style="{'background-image':'url('+foto.link+')'}"   >
                    
                  </div>
            </div>
          <table class="tablex" style="width: 100%;">
            <thead >
              <th></th>
              <th>Fecha</th>
              <th>Autor</th>
              <th>Mensaje</th>
            </thead>
            <tr :style="{'background-color': mensaje.usuario_id != null ? '#a2c2df' : ''}" v-for="mensaje in s.mensajes">
              <td>
              <div style="text-align: center;" v-if="mensaje.usuario_id == null">
                <b-icon v-if="mensaje.estado == 1" style="color: #2ECC40; height: 30px; width: 30px; " icon="check-circle"></b-icon> 
                <b-icon v-else style="color: #AAAAAA; height: 30px; width: 30px;" icon="clock"></b-icon> 
              </div>
              </td>
              <td>{{ new Date(mensaje.fecha.replace(" GMT", "")).toLocaleDateString("es-ES", { year: 'numeric', month: 'numeric', day: 'numeric', hour:'numeric', minute:'numeric' }) }}</td>

              <td>{{ mensaje.usuario_id == null ? '(Profesional) '+profesionales.find(p=>{ return p.id == mensaje.profesional_id}).nombre+' '+profesionales.find(p=>{ return p.id == mensaje.profesional_id}).apellido : '(Cliente) '+s.nombre }}
              <div v-if="mensaje.usuario_id != null"> a <b>{{ profesionales.find(p=>{ return p.id == mensaje.profesional_id}).nombre }}</b> 
              

              </div>


              
              </td>
              <td>{{ mensaje.mensaje }} 
<br/>
                <div style="border: 3px solid rgb(255,140,0); margin-top: 10px; background-color: rgba(255,140,0,0.4); padding: 10px; display: inline-block; border-radius: 4px;" v-if="mensaje.atributos.orden != null"> 

                  <b>{{ profesionales.find(p=>{ return p.id == mensaje.profesional_id}).nombre }} </b>
                  envió una solicitud de pago para el cliente por 
                  <b>${{ Intl.NumberFormat('de-DE').format( mensaje.atributos.orden.monto_servicios) }}</b>
                  para el día 
                  <b>{{ new Date(mensaje.atributos.orden.fecha).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}</b>.
                  Los servicios son:
                  <br/>
                  <div style="margin-left: 20px;" v-for="s in mensaje.atributos.orden.servicios" >- {{ s.nombre }} por ${{ Intl.NumberFormat('de-DE').format( s.precio )}}</div>
                </div> 
                <div v-if="s.fotos.find(f=>{return f.mensaje_cliente_id == mensaje.id})">
            <div class="attach-img" v-for="foto in s.fotos.filter(f=>{return f.mensaje_cliente_id == mensaje.id})" v-on:click="showImg(foto.link)" :style="{'background-image':'url('+foto.link+')'}"   >
                    
                  </div>
            </div>
              </td>
            </tr>
          </table>
          </td>
        </tr>

      </template>


    </tbody>
</table>


        
      </div>
      <div v-else class="articulo-principal">
        cargando...

      </div>
    </div>
  </div>


</div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import ModalResponder from '@/views/superadmin/ModalResponder.vue';
import ModalAgregarComentario from '@/views/superadmin/ModalAgregarComentario.vue';
import ModalComentarios from '@/views/superadmin/ModalComentarios.vue';
import ModalFiltros from '@/views/superadmin/ModalFiltros.vue';
import ModalImg from '@/components/ModalImg.vue';

export default {
  name: 'HelloWorld',
  props: ['id'],
  components: {
    SideNav,
    ModalResponder,
    ModalFiltros,
    ModalAgregarComentario,
    ModalComentarios,
    ModalImg,
    TopNav,
  },
  data() {
    return {
      loaded: true,
      solicitudes: [],
      profesionales: [],
      filtros: [
        { nombre: 'pagado', activo: false },
        { nombre: 'con presupuesto aprobado', activo: false },
        { nombre: 'con presupuesto', activo: false },
        { nombre: 'con orden', activo: false },
        { nombre: 'marcadas', activo: false },
      ],
      viendo_presupuesto: null,
      viendo_orden: null,
      viendo_entradas: null,
      hovering: '',
      palabra_buscada: '',
      estados_orden: {
        0: 'pendiente',
        1: 'agendado',
        2: 'confirmado',
        3: 'en proceso',
        4: 'atendido',
      },
      solicitud: {},
      showing_img_link: '',
      dt_dia: '',
    };
  },
  created() {},
  methods: {
    ...mapActions(['initialize']),
    cambiarFlitro(v) {
      var filtro_a_cambiar = this.filtros.find(x => x.nombre === v.nombre);
      filtro_a_cambiar.activo = !filtro_a_cambiar.activo;
    },
    createAdjustedDate(hoursOffset) {
      let date = new Date();
      date.setHours(date.getHours() + hoursOffset);
      return date;
    },
    updateMousePosition(event) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    },
    hoverItem(event, hovering) {
      this.hovering = hovering;
    },
    getDateDifference(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const difference = end - start;
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      let result = '';
      if (days > 0) result += `${days} dias, `;
      if (days > 0 || hours > 0) result += `${hours} horas, `;
      result += `${minutes} minutos`;
      return result;
    },
    showImg(link) {
      this.showing_img_link = link;
      this.$refs.modalimg2.show();
    },
    mostrarModal(solicitud_) {
      this.solicitud = solicitud_;
      this.$refs.modalresponder.show();
    },
    mostrarModalComentario(solicitud_) {
      this.solicitud = solicitud_;
      this.$refs.modalagregarcomentario.show();
    },
    mostrarModalComentariosS(solicitud_) {
      this.solicitud = solicitud_;
      this.$refs.modalcomentarios.show();
    },
    utima_respuesta_cliente(solicitud_) {
      let ultimo_mensaje_pro = null;
      if (solicitud_.mensajes_profesional.length > 0) {
        ultimo_mensaje_pro = solicitud_.mensajes_profesional[solicitud_.mensajes_profesional.length - 1];
      }
      let ultimo_mensaje_cliente = null;
      if (solicitud_.mensajes_usuario.length > 0) {
        ultimo_mensaje_cliente = solicitud_.mensajes_usuario[solicitud_.mensajes_usuario.length - 1];
      }
      if (ultimo_mensaje_pro == null && ultimo_mensaje_cliente != null) return true;
      if (ultimo_mensaje_pro != null && ultimo_mensaje_cliente == null) return false;
      if (ultimo_mensaje_cliente == null && ultimo_mensaje_pro == null) return true;
      if (new Date(ultimo_mensaje_pro.fecha) < new Date(ultimo_mensaje_cliente.fecha)) return true;
      return false;
    },
    marcar_solicitud(solicitud_id) {
      let _solicitud = this.solicitudes.find(x => x.id === solicitud_id);
      this.status = -1;

      axios
        .post(window.hostname + 'api/superadmin/solicitud_marcar_pinneada', {
          solicitud_id: _solicitud.id,
        })
        .then(r => {
          this.status = 1;
          this.loading = false;
          this.$notify({
            group: 'foo',
            title: '¡Éxito!',
            text: r.data.menssage,
            type: 'success',
          });
          _solicitud.atributos.marcada = r.data.marca;
          this.$forceUpdate();
        })
        .catch(e => {
          this.$notify({
            group: 'foo',
            title: '¡Error!',
            text: '¡Ha ocurrido un error!',
            type: 'error',
          });

          this.status = 2;
          this.loading = false;
        });
    },
    initialize_() {
      this.loaded = false;
      axios
        .post(window.hostname + 'api/superadmin/initialize_responder_solicitudes', {
          desde: this.dt_dia,
        })
        .then(response => {
          this.profesionales = response.data.profesionales;
          var insertando;
          this.solicitudes = [];
          var profesionales_ins = [];
          var prof_;
          for (var f of response.data.solicitudes) {
            insertando = f;
            insertando.profesionales = [];
            insertando.tiene_presupuesto = false;
            insertando.tiene_presupuesto_aprobado = false;
            insertando.pagado = false;
            insertando.tiene_orden = false;
            insertando.viendo = false;
            insertando.mensajes = [];
            insertando.monto_pagado = 0;
            insertando.monto_agregado = 0;
            console.log("AAA", insertando);
            for (var o of insertando.ordenes) {
              if (o.presupuestos.length > 0) {
                for (var p of o.presupuestos) {
                  if (p.estado !== 0) {
                    insertando.tiene_presupuesto = true;
                  }
                  if (p.estado === 2) {
                    insertando.tiene_presupuesto_aprobado = true;
                    break;
                  }
                }
                for (var pags of o.pagos) {
                  if (pags.estado === 1) {
                    insertando.monto_pagado += pags.monto;
                  }
                }
                for (var it of o.servicios) {
                  insertando.monto_agregado += it.precio;
                }
                if (insertando.tiene_presupuesto_aprobado) {
                  break;
                }
              }
              console.log("LA ORDEN ES ", o);
            }
            profesionales_ins = [];
            for (var j of insertando.mensajes_profesional) {
              if (typeof j.atributos === 'object') {
                if ('orden' in j.atributos) {
                  insertando.tiene_orden = true;
                  if (j.atributos.orden.pagado === 1) {
                    insertando.pagado = true;
                  }
                  // Agregando al profesional para la busqueda
                  if (!profesionales_ins.includes(j.profesional_id)) {
                    profesionales_ins.push(j.profesional_id);
                    prof_ = this.profesionales.find(x => x.id === j.profesional_id);
                    if (prof_) {
                      insertando.profesionales.push((prof_.nombre + ' ' + prof_.apellido).toLowerCase());
                    }
                  }
                }
              }
              insertando.mensajes.push(j);
            }
            for (var j of insertando.mensajes_usuario) {
              insertando.mensajes.push(j);
            }
            insertando.mensajes.sort((a, b) => {
              return new Date(a.fecha) - new Date(b.fecha);
            });
            if (insertando.mensajes.length > 0) {
              insertando.ultimo_mensaje_fecha = insertando.mensajes[insertando.mensajes.length - 1].fecha;
            }
            this.solicitudes.push(insertando);
          }
          this.solicitudes.sort((a, b) => {
            var dateA = new Date(a.fecha_creado);
            var dateB = new Date(b.fecha_creado);
            if (a.ultimo_mensaje_fecha) dateA = new Date(a.ultimo_mensaje_fecha);
            if (b.ultimo_mensaje_fecha) dateB = new Date(b.ultimo_mensaje_fecha);
            return dateB - dateA; // Descending order
          });
          this.loaded = true;
        })
        .catch(e => {
          this.loaded = true;
          console.log("Error al cargar...", e);
        });
    },
  },
  computed: {
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos']),
    solicitudes_filtradas() {
      let ret = this.solicitudes;

      // Apply active filters
      if (this.filtros.find(x => x.nombre === 'pagado').activo) {
        ret = ret.filter(x => x.pagado);
      }
      if (this.filtros.find(x => x.nombre === 'con presupuesto aprobado').activo) {
        ret = ret.filter(x => x.tiene_presupuesto_aprobado);
      }
      if (this.filtros.find(x => x.nombre === 'con presupuesto').activo) {
        ret = ret.filter(x => x.tiene_presupuesto);
      }
      if (this.filtros.find(x => x.nombre === 'marcadas').activo) {
        ret = ret.filter(x => x.atributos.marcada);
      }
      if (this.filtros.find(x => x.nombre === 'con orden').activo) {
        ret = ret.filter(x => x.ordenes.some(y => y.estado !== 0));
      }

      // Apply search filter
      if (this.palabra_buscada.trim() !== '') {
        const search = this.palabra_buscada.toLowerCase();

        ret = ret.filter(item => {
          // Check description
          const descripcionMatch = item.descripcion
            ? item.descripcion.toLowerCase().includes(search)
            : false;

          // Check name
          const nombreMatch = item.nombre
            ? item.nombre.toLowerCase().includes(search)
            : false;

          // Check professionals
          const professionalsMatch = item.profesionales.some(prof =>
            prof.toLowerCase().includes(search)
          );

          // Check comuna (community)
          const comuna = this.comunas.find(c => c.id === item.comuna_id);
          const comunaMatch = comuna
            ? comuna.Nombre.toLowerCase().includes(search)
            : false;

          // Check ordenes (orders)
          const ordenMatch = item.ordenes.some(o => {
            const idMatch = o.id
              ? o.id.toString().toLowerCase().includes(search)
              : false;
            const direccionMatch = o.direccion
              ? o.direccion.toLowerCase().includes(search)
              : false;
            return idMatch || direccionMatch;
          });

          return (
            descripcionMatch ||
            nombreMatch ||
            professionalsMatch ||
            comunaMatch ||
            ordenMatch
          );
        });
      }

      return ret;
    }
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.updateMousePosition);
  },
  mounted() {
    window.addEventListener('mousemove', this.updateMousePosition);
    if (!this.admin_datos.superadmin) {
      this.$router.push('/');
    }
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    this.dt_dia = sevenDaysAgo.toISOString().split('T')[0];
  },
  watch: {
    dt_dia(val) {
      this.initialize_();
    },
  }
};
</script>


<style scoped>
.blue-label {
  display: inline-block;
  background-color: #007bff; /* Blue color */
  color: #fff; /* White text */
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 4px;
  text-align: center;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}

.blue-label:hover {
  background-color: #0056b3; /* Darker blue on hover */
  cursor: pointer;
}
.purple-label {
  display: inline-block;
  background-color: #6f42c1; /* Purple color */
  color: #fff; /* White text */
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 4px;
  text-align: center;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}

.purple-label:hover {
  background-color: #59339d; /* Darker purple on hover */
  cursor: pointer;
}


.n-comentarios{
  background-color: rgb(20, 134, 231);
  color: white;
  font-weight: 700;
  border-radius: 10px;
  padding: 3px 10px;
}
.n-comentarios-bot{
  background-color: rgb(120,120,120);
  color: white;
  font-weight: 700;
  border-radius: 10px;
  padding: 2px 6px;
  font-size: 14px;
}
.attach-img{
  margin: 4px;
  border-radius: 10px; 
  background-size: cover; 
  border: 3px solid rgb(20, 134, 231); 
  background-position: center; 
  cursor: pointer; 
  height:150px; 
  width: 150px;  
  overflow: hidden; 
  display: inline-block;
}
    .details {
        display: none;
    }
    body {
        font-family: Arial, sans-serif;
    }

    .tablex {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
    }

    .tablex th, .tablex td {
        text-align: left;
        padding: 8px;
        border-bottom: 1px solid #ddd;
 
    }

    .tablex th {
      background-color: rgb(38, 53, 67);
        color: white;
    }

    .tablex tr:nth-child(even) {
        background-color: #f2f2f2;
    }



    button {
        background-color: #4CAF50;
        color: white;
        padding: 6px 12px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
    }

    button:hover {
        background-color: #45a049;
    }

    
    
td{
  text-align: left;
  cursor: pointer;
}
.articulo-principal{
  position: relative;



}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

