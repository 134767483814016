<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div v-if="admin_datos.superadmin" class="main-container">
        <h2>Resultados de Encuesta</h2>
        
        <!-- Filters -->
        <div class="filter-container">
          <div class="input-cont">
            <label for="start-date" class="input-label">Fecha Inicio:</label>
            <input type="date" v-model="startDate" id="start-date" @change="fetchRespuestas(selectedEncuesta.id)">
          </div>
          <div class="input-cont">
            <label for="end-date" class="input-label">Fecha Fin:</label>
            <input type="date" v-model="endDate" id="end-date" @change="fetchRespuestas(selectedEncuesta.id)">
          </div>
          <div class="input-cont">
            <label for="professional-select" class="input-label">Profesional:</label>
            <select v-model="selectedProfessional" id="professional-select">
              <option value="">-- Todos los Profesionales --</option>
              <option 
                v-for="professional in professionals" 
                :key="professional.id" 
                :value="professional.id">
                {{ professional.nombre }} {{ professional.apellido }}
              </option>
            </select>
          </div>
        </div>

        <!-- Encuesta Selector -->
        <div class="input-cont">
          <label for="encuesta-select" class="input-label">Seleccione una encuesta:</label>
          <select v-model="selectedEncuesta" id="encuesta-select" @change="onEncuestaChange">
            <option disabled value="">-- Seleccione una encuesta --</option>
            <option 
              v-for="encuesta in encuestas" 
              :key="encuesta.id" 
              :value="encuesta">
              {{ encuesta.titulo }}
            </option>
          </select>
        </div>
        
        <div v-if="loadingEncuestas" class="loading">Cargando encuestas...</div>
        
        <!-- When an encuesta is selected -->
        <div v-if="selectedEncuesta">
          <h3>Encuesta: {{ selectedEncuesta.titulo }}</h3>
          
          <!-- Summary Section -->
          <div v-if="!loadingRespuestas">
            <h4>📊 Resumen de respuestas</h4>
            <div v-if="Object.keys(answerSummary).length === 0">
              No hay respuestas para resumir.
            </div>
            <div v-else>
              <div 
                v-for="pregunta in selectedEncuesta.preguntas" 
                :key="pregunta.question_id" 
                class="summary-block"
              >
                <h5>{{ pregunta.text }}</h5>
                <div class="summary-row">
                  <div class="summary-table">
                    <table class="tablex">
                      <thead>
                        <tr>
                          <th>Respuesta</th>
                          <th>Conteo</th>
                          <th>Porcentaje</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr 
                          v-for="(count, respuesta) in answerSummary[pregunta.question_id] || {}" 
                          :key="respuesta">
                          <td>{{ respuesta }}</td>
                          <td>{{ count }}</td>
                          <td>{{ percentageFor(pregunta.question_id, respuesta) }}%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="summary-chart">
                    <div class="pie-chart-container">
                      <pie-chart 
                        :data="getChartDataForQuestion(pregunta.question_id)" 
                        :options="chartOptions" 
                        :key="chartKey + pregunta.question_id"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Detailed Respuestas Section -->
          <div v-if="loadingRespuestas" class="loading">Cargando respuestas...</div>
          <div v-else>
            <h4>📝 Respuestas individuales</h4>
            <div v-if="filteredRespuestas.length === 0">
              No hay respuestas para esta encuesta.
            </div>
            <div 
              v-for="respuesta in filteredRespuestas" 
              :key="respuesta.id" 
              class="respuesta-card"
            >
              <h5>
                {{ getResponseTitle(respuesta) }}
                <span class="subtext">{{ formatDate(respuesta.orden && respuesta.orden.fecha ? respuesta.orden.fecha : (respuesta.visita && respuesta.visita.fecha ? respuesta.visita.fecha : '')) }}</span>
              </h5>
              <table class="tablex">
                <thead>
                  <tr>
                    <th>Pregunta</th>
                    <th>Respuesta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr 
                    v-for="pregunta in selectedEncuesta.preguntas" 
                    :key="pregunta.question_id">
                    <td>{{ pregunta.text }}</td>
                    <td>
                      {{ respuesta.respuestas[pregunta.question_id] || 'Sin respuesta' }}
                    </td>
                  </tr>
                </tbody>
              </table>
              
              <!-- Expandable Orden details -->
              <div v-if="respuesta.orden" class="detalle-container">
                <button @click="toggleOrdenDetails(respuesta.id)" class="toggle-btn">
                  {{ expandedOrden[respuesta.id] ? 'Ocultar detalles de Orden' : 'Mostrar detalles de Orden' }}
                </button>
                <div v-if="expandedOrden[respuesta.id]" class="detalle-content">
                  <strong>Orden</strong>
                  <p><strong>ID:</strong> {{ respuesta.orden.id }}</p>
                  <p><strong>Dirección:</strong> {{ respuesta.orden.direccion }}</p>
                  <p><strong>Comuna:</strong> {{ respuesta.orden.comuna_id }}</p>
                  <p><strong>Fecha:</strong> {{ respuesta.orden.fecha }}</p>
                  <p><strong>Estado:</strong> {{ respuesta.orden.estado }}</p>
                  <div v-if="respuesta.orden.profesional" class="detalle-sub">
                    <strong>Profesional</strong>
                    <p><strong>ID:</strong> {{ respuesta.orden.profesional.id }}</p>
                    <p><strong>Nombre:</strong> {{ respuesta.orden.profesional.nombre }}</p>
                    <p><strong>Apellido:</strong> {{ respuesta.orden.profesional.apellido }}</p>
                  </div>
                </div>
              </div>
              
              <!-- Expandable Visita details -->
              <div v-if="respuesta.visita" class="detalle-container">
                <button @click="toggleVisitaDetails(respuesta.id)" class="toggle-btn">
                  {{ expandedVisita[respuesta.id] ? 'Ocultar detalles de Visita' : 'Mostrar detalles de Visita' }}
                </button>
                <div v-if="expandedVisita[respuesta.id]" class="detalle-content">
                  <strong>Visita</strong>
                  <p><strong>ID:</strong> {{ respuesta.visita.id }}</p>
                  <p><strong>Orden ID:</strong> {{ respuesta.visita.orden_id }}</p>
                  <p><strong>Profesional ID:</strong> {{ respuesta.visita.profesional_id }}</p>
                  <p><strong>Estado:</strong> {{ respuesta.visita.estado }}</p>
                  <p><strong>Fecha:</strong> {{ respuesta.visita.fecha }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import PieChart from '@/views/PieChart.vue';

export default {
  name: 'EncuestaResults',
  components: { SideNav, TopNav, PieChart },
  data() {
    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(today.getMonth() - 1);
    
    return {
      encuestas: [],
      selectedEncuesta: "",
      respuestasEncuesta: [],
      selectedProfessional: "",
      chartKey: 0,
      loadingEncuestas: false,
      loadingRespuestas: false,
      expandedOrden: {},
      expandedVisita: {},
      startDate: lastMonth.toISOString().split('T')[0], // YYYY-MM-DD
      endDate: today.toISOString().split('T')[0],       // YYYY-MM-DD
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom' }
      }
    };
  },
  created() {
    this.fetchEncuestas();
  },
  watch: {
    selectedProfessional() {
      this.chartKey++; // Force chart re-render
    }
  },
  computed: {
    ...mapGetters(['admin_datos', 'comunas']),
    professionals() {
      const professionalsMap = new Map();
      this.respuestasEncuesta.forEach(respuesta => {
        const profesional = respuesta.orden && respuesta.orden.profesional;
        if (profesional && !professionalsMap.has(profesional.id)) {
          professionalsMap.set(profesional.id, profesional);
        }
      });
      return Array.from(professionalsMap.values());
    },
    filteredRespuestas() {
      if (!this.selectedProfessional) return this.respuestasEncuesta;
      return this.respuestasEncuesta.filter(respuesta => {
        const profesional = respuesta.orden?.profesional;
        return profesional && profesional.id === this.selectedProfessional;
      });
    },
    answerSummary() {
      const summary = {};
      this.selectedEncuesta.preguntas.forEach(pregunta => {
        summary[pregunta.question_id] = {};
        this.filteredRespuestas.forEach(respuesta => {
          const answer = respuesta.respuestas[pregunta.question_id] || "Sin respuesta";
          summary[pregunta.question_id][answer] = (summary[pregunta.question_id][answer] || 0) + 1;
        });
      });
      return summary;
    }
  },
  methods: {
    fetchEncuestas() {
      this.loadingEncuestas = true;
      axios.get(`${window.hostname}api/superadmin/encuestas`)
        .then(response => {
          this.encuestas = response.data;
          this.loadingEncuestas = false;
        })
        .catch(error => {
          console.error('Error fetching encuestas:', error);
          this.loadingEncuestas = false;
        });
    },
    onEncuestaChange() {
      if (this.selectedEncuesta && this.selectedEncuesta.id) {
        this.expandedOrden = {};
        this.expandedVisita = {};
        this.fetchRespuestas(this.selectedEncuesta.id);
      }
    },
    fetchRespuestas(encuestaId) {
      if (!encuestaId) {
        console.warn('No encuesta selected');
        return;
      }
      this.selectedProfessional = ''; // Reset professional filter when refetching
      this.loadingRespuestas = true;
      const params = {
        encuesta_id: encuestaId,
        start_date: this.startDate,
        end_date: this.endDate
      };
      axios.get(`${window.hostname}api/superadmin/respuestas_encuesta`, { params })
        .then(response => {
          this.respuestasEncuesta = response.data;
          this.loadingRespuestas = false;
        })
        .catch(error => {
          console.error('Error fetching respuestas:', error);
          this.loadingRespuestas = false;
        });
    },
    toggleOrdenDetails(respuestaId) {
      this.$set(this.expandedOrden, respuestaId, !this.expandedOrden[respuestaId]);
    },
    toggleVisitaDetails(respuestaId) {
      this.$set(this.expandedVisita, respuestaId, !this.expandedVisita[respuestaId]);
    },
    getResponseTitle(respuesta) {
      const profesional = respuesta.orden && respuesta.orden.profesional ? respuesta.orden.profesional : null;
      const profesionalName = profesional ? `${profesional.nombre} ${profesional.apellido}` : 'Sin Profesional';
      const direccion = respuesta.orden && respuesta.orden.direccion ? respuesta.orden.direccion : 'Sin Dirección';
      const comuna = respuesta.orden && respuesta.orden.comuna_id 
        ? (this.comunas.find(c => c.id === respuesta.orden.comuna_id) ? this.comunas.find(c => c.id === respuesta.orden.comuna_id).Nombre : 'Sin Comuna')
        : 'Sin Comuna';
      return `${profesionalName} - ${direccion}, ${comuna}`;
    },
    formatDate(dateStr) {
      if (!dateStr) return 'Sin Fecha';
      const date = new Date(dateStr);
      return date.toLocaleDateString('es-ES', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
      });
    },
    percentageFor(questionId, respuesta) {
      let total = 0;
      const summary = this.answerSummary[questionId] || {};
      for (const key in summary) {
        total += summary[key];
      }
      if (total === 0) return 0;
      return ((summary[respuesta] / total) * 100).toFixed(1);
    },
    getChartDataForQuestion(questionId) {
      const summary = this.answerSummary[questionId] || {};
      const labels = Object.keys(summary);
      const data = Object.values(summary);
      const backgroundColors = labels.map((_, index) => {
        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
        return colors[index % colors.length];
      });
      return {
        labels,
        datasets: [{
          data,
          backgroundColor: backgroundColors
        }]
      };
    }
  }
};
</script>

<style scoped>
.summary-block {
  background-color: rgb(250, 250, 250);
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 20px;
}
.column-left {
  float: left;
  width: 250px;
}
.column-right {
  margin-left: 250px;
  padding: 20px;
  background-color: rgb(242, 247, 251);
  min-height: 100vh;
}
.main-container {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
}
.input-cont {
  position: relative;
  max-width: 400px;
  margin: 20px 0;
}
.input-cont select,
.input-cont input[type="date"] {
  width: 100%;
  padding: 10px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
.input-label {
  position: absolute;
  top: -18px;
  left: 6px;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
}
.summary-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.summary-table, 
.summary-chart {
  flex: 1;
  min-width: 300px;
  padding: 10px;
}
.pie-chart-container {
  height: 300px;
  margin-bottom: 40px;
}
.tablex {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
.tablex th, 
.tablex td {
  border: 1px solid #ddd;
  padding: 8px;
}
.tablex th {
  background-color: rgb(38, 53, 67);
  color: white;
}
.detalle-container {
  margin-top: 10px;
}
.toggle-btn {
  background-color: #0074D9;
  color: white;
  padding: 6px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 6px;
}
.toggle-btn:hover {
  opacity: 0.9;
}
.detalle-content {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}
.detalle-sub {
  margin-top: 10px;
  padding-left: 10px;
  border-left: 2px solid #ccc;
}
.respuesta-card {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #fff;
}
.respuesta-card h5 {
  margin-bottom: 10px;
}
.subtext {
  display: block;
  font-size: 0.9em;
  color: #666;
  font-style: italic;
}
.loading {
  font-style: italic;
  margin: 10px 0;
}
.filter-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
@media (max-width: 800px) {
  .column-left {
    width: 100%;
    float: none;
  }
  .column-right {
    margin-left: 0;
  }
  .summary-row {
    flex-direction: column;
  }
  .filter-container {
    flex-direction: column;
    gap: 10px;
  }
}
</style>