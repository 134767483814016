<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>


  <div class="column-right">
    <div class="main-container">

      <div style="position:absolute; right:0px; top:10px; padding:20px; ">
        Desde <input
          id="x2"
          v-model="dt_dia"
          type="date"
        > Hasta: <input
          id="x2"
          v-model="dt_dia2"
          type="date"
        >
      </div>

      
      <div style="height:60px;"></div>
      <div style="display:flex; gap:20px;">



        <div style="display: flex; margin-bottom: 40px; position: relative; align-items:center; justify-content: start; background-color: #0074D9; border-radius: 5px; color:#fff; height: 100px; width: 300px;">
        <div style="display: flex; align-items:center; justify-content: start; ">
          <b-icon style="width:40px; height: 40px; position: absolute; right:20px;" icon="person"></b-icon>
<div style="padding-left: 20px;">
  <div style="font-size: 12px; width: 100%;">
          Clientes unicos
        </div>
<div style="font-size: 25px;">
<!--   {{ numero_clientes_con_pago }} -->
  {{ cliente_unicos.length }}
</div>
</div>
        </div>

        
        
      </div>


      <div style="display: flex; margin-bottom: 40px; position: relative; align-items:center; justify-content: start; background-color: #FF851B; border-radius: 5px; color:#fff; height: 100px; width: 300px;">
        <div style="display: flex; align-items:center; justify-content: start; ">
          <b-icon style="width:40px; height: 40px; position: absolute; right:20px;" icon="chat-right-text"></b-icon>
<div style="padding-left: 20px;">
  <div style="font-size: 12px; width: 100%;">
          Total de solicitudes
        </div>
<div style="font-size: 25px;">
  {{ solicitudes.length }}
</div>
</div>
        </div>

        
        
      </div>
      <div style="display: flex; margin-bottom: 40px; position: relative; align-items:center; justify-content: start; background-color: #85144b; border-radius: 5px; color:#fff; height: 100px; width: 300px;">
        <div style="display: flex; align-items:center; justify-content: start; ">
          <b-icon style="width:40px; height: 40px; position: absolute; right:20px;" icon="chat-right-text"></b-icon>
<div style="padding-left: 20px;">
  <div style="font-size: 12px; width: 100%;">
        Solicitudes sin respuesta
        </div>
<div style="font-size: 25px;">
  {{ solicitudes_sin_respuesta  }} ( {{ solicitudes.length != 0 ? (((solicitudes_sin_respuesta / solicitudes.length)*100).toFixed(2) + ' %') : 'Indef' }} )
</div>
</div>
        </div>

        
        
      </div>

      <div style="display: flex; margin-bottom: 40px; position: relative; align-items:center; justify-content: start; background-color: #7FDBFF; border-radius: 5px; color:#fff; height: 100px; width: 300px;">
        <div style="display: flex; align-items:center; justify-content: start; ">
          <b-icon style="width:40px; height: 40px; position: absolute; right:20px;" icon="clipboard"></b-icon>
<div style="padding-left: 20px;">
  <div style="font-size: 12px; width: 100%;">
          Total ordenes
        </div>
<div style="font-size: 25px;">
  {{ ordenes_con_pago.length }}
</div>
</div>
        </div>

        
        
      </div>





      </div>
      <div style="display:flex; gap:20px;">

      <div style="display: flex; margin-bottom: 40px; position: relative; align-items:center; justify-content: start; background-color: #3D9970; border-radius: 5px; color:#fff; height: 100px; width: 300px;">
        <div style="display: flex; align-items:center; justify-content: start; ">
          <b-icon style="width:40px; height: 40px; position: absolute; right:20px;" icon="currency-dollar"></b-icon>
<div style="padding-left: 20px;">
  <div style="font-size: 12px; width: 100%;">
          Ingreso Empresa
        </div>
<div style="font-size: 25px;">
  ${{Intl.NumberFormat('de-DE').format(total_pagos_empresa) }}
</div>
</div>
        </div>

        
        
      </div>
      <div style="display: flex; margin-bottom: 40px; position: relative; align-items:center; justify-content: start; background-color: rgba(52, 152, 219); border-radius: 5px; color:#fff; height: 100px; width: 300px;">
        <div style="display: flex; align-items:center; justify-content: start; ">
          <b-icon style="width:40px; height: 40px; position: absolute; right:20px;" icon="currency-dollar"></b-icon>
<div style="padding-left: 20px;">
  <div style="font-size: 12px; width: 100%;">
          Ingreso Clientes
        </div>
<div style="font-size: 25px;">
  ${{Intl.NumberFormat('de-DE').format(total_pagos_clientes) }}
</div>
</div>
        </div>

        
        
      </div>






      <div style="display: flex; margin-bottom: 40px; position: relative; align-items:center; justify-content: start; background-color: rgb(255, 10, 13); border-radius: 5px; color:#fff; height: 100px; width: 300px;">
        <div style="display: flex; align-items:center; justify-content: start; ">
          <b-icon style="width:40px; height: 40px; position: absolute; right:20px;" icon="currency-dollar"></b-icon>
<div style="padding-left: 20px;">
  <div style="font-size: 12px; width: 100%;">
          Total ingreso
        </div>
<div style="font-size: 25px;">
  ${{Intl.NumberFormat('de-DE').format(total_pagos) }}
</div>
</div>
        </div>

        
        
      </div>



      </div>


      <h4>Gráfico de ingresos</h4>
      <div v-if="loaded" class="articulo-principal">
        <div style="height:50px"></div>
        <line-chart
        v-if="true"
        :key="key+keyMain"
        :height="430"
      
        style=""
        :data="graficoLineaSumaPagos"
        :options="optionsLinea"
      />
<!--       <div style="background-color: rgba(46, 204, 64, 0.1); border-radius: 4px; color:#2ECC40; display: inline-block; padding: 4px 14px;">Total ingreso diario cobrado: <b>${{Intl.NumberFormat('de-DE').format(this.ordenes.reduce((a,b) => {return a + b.monto_pagado}, 0)) }}</b></div>  -->

<div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px; width:500px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Pagos por UTM Source
            </h4>
            <pie-chart
              v-if="solicitudes.length != 0"
              :key="key"
              :height="300"
              
              style="transform:translateY(-2px); width:400px;"
              :data="graficoPagosUtmSource"
              :options="chartOptions"
            />
            <div
              v-else
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>
      <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px; width:500px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Pagos por UTM Campaign
            </h4>
            <pie-chart
              v-if="solicitudes.length != 0"
              :key="key"
              :height="300"
              
              style="transform:translateY(-2px); width:400px;"
              :data="graficoPagosUtmCampaign"
              :options="chartOptions"
            />
            <div
              v-else
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>

<br/>
        <br/>
      <h4>Gráfico de Solicitudes</h4>

      <line-chart
        v-if="true"
        :key="key+keyMain"
        :height="430"
      
        style=""
        :data="graficoNumeroSolicitudes"
        :options="optionsLinea"
      />
      <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px; width:500px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Solicitudes por UTM Source
            </h4>
            <pie-chart
              v-if="solicitudes.length != 0"
              :key="key"
              :height="300"
              
              style="transform:translateY(-2px); width:400px;"
              :data="graficoSolicitudesUTMSource"
              :options="chartOptions"
            />
            <div
              v-else
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>
      <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px; width:500px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Solicitudes por UTM Campaign
            </h4>
            <pie-chart
              v-if="solicitudes.length != 0"
              :key="key"
              :height="300"
              
              style="transform:translateY(-2px); width:400px;"
              :data="graficoSolicitudesUTMCampaing"
              :options="chartOptions"
            />
            <div
              v-else
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>
      <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px; width:500px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Solicitudes por Clase
            </h4>
            <pie-chart
              v-if="solicitudes.length != 0"
              :key="key"
              :height="300"
              
              style="transform:translateY(-2px); width:400px;"
              :data="graficoSolicitudesClases"
              :options="chartOptions"
            />
            <div
              v-else
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>

      <WeekDensityChart 
      :ordenes="ordenes.filter(o=>{return o.estado != 0})" :bgColor="'rgb(20, 134, 231)'"
    />
      <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px; width:500px  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Ventas por servicio
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              
              style="transform:translateY(-2px); width:400px;"
              :data="graficoServicios"
              :options="chartOptions"
            />
            <div
              v-else
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>

    
      <div>
        <h4>Clientes</h4>
        <div v-for="c in cliente_unicos"> {{ new Date(c.fecha.a, c.fecha.m, c.fecha.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }} {{ c.nombre }} </div>
      </div>
        
      </div>

      
      <div v-else class="articulo-principal">
        cargando...

      </div>
    </div>
  </div>



</div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import WeekDensityChart from '@/components/WeekDensityChart.vue';
import LineChart from "./LineChart.vue";
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
    LineChart,
    PieChart,
    WeekDensityChart,
    // BarChart,
  },
  data() {
    return {
      loaded:true,
      dt_dia:'',
      dt_dia2:'',
      desde:{d:0, m:0, a:0},
      hasta:{d:0, m:0, a:0},



      solicitudes:[],
      pagos:[],

      id_orden:0,
      popup: false,
      mouseX:0,
      mouseY:0,
      resultado:'',
      estados:['Pendiente', 'Agendado',  'Confirmado', 'Atendido', 'Resultados Entregados', 'Evaluado'],
      key:0,
      keyMain:0,

      graf_sol:[],
      
      chartOptions: {
        responsive:true,
        hoverBorderWidth: 20,
        title: {
          display: false,
          text: 'Actividad en la próxima'
        },
                pieceLabel: {
            mode: 'value'
        },
        legend:{display:true, position:'right'},
      },
      optionsLinea: {
            maintainAspectRatio: false,
            height: 70,
            responsive: true,
            title:{
                display: false,
                text: "Chart.js Time Scale"
            },
            scales:{
                xAxes: [{
                    type: "time",
                    time: {unit: 'day', displayFormats: {day: 'MMM DD' }
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Día'
                }
                }],
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                  },
                    scaleLabel: {
                        display:     true,
                        labelString: 'Monto'
                    }
                }]
            }
      },

    }
  },
  created(){
  
  },

  methods:{
    ...mapActions(['initialize']),
    groupBy (xs, key) {

      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    extractDateMonthYear(isoDateString) {
  // Parse the ISO string
  const date = new Date(isoDateString);

  // Get the year, month, and day
  const year = date.getFullYear();
  // Adding 1 to getMonth() to convert from 0-based index to month number
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // Format month and day to ensure they are in 'MM' or 'DD' format
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;

  // Return the formatted string in "MM/DD/YYYY" format
  return `${formattedMonth}/${formattedDay}/${year}`;
    },


    async get_ordenes(load = true) {
      if (load) {
        this.loaded = false;
      }
      const params = {};
      params.start_date = new Date(this.desde.a, this.desde.m-1, this.desde.d).toISOString().split('T')[0];
      params.end_date = new Date(this.hasta.a, this.hasta.m-1, this.hasta.d).toISOString().split('T')[0];
      try {
        const response = await axios.get(`${window.hostname}api/admin/get_reporte`, { params });
        for(var sol of response.data.solicitudes){
            
            sol.fecha_int = this.extractDateMonthYear(sol.fecha_creado)
          }
        this.solicitudes = response.data.solicitudes
        this.pagos = response.data.pagos

      } catch (error) {
        console.error('Error al cargar solicitudes:', error);
      } finally {
        this.loaded = true;
      }
    },



  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'servicios']),
    solicitudes_sin_respuesta(){
      return this.solicitudes.filter(sol=>sol.mensajes_profesional_count == 0).length
    },
    graficoPagosUtmSource() {
  let labels = []
  let data = []
  let utmSources = []

  // 1) Collect unique UTM sources from pagos
  for (let pago of this.pagos) {
    // If "solicitud_cliente" or "utm_source" is missing/null, default to "Sin identificar"
    let source = (pago.solicitud_cliente && pago.solicitud_cliente.utm_source)
      ? pago.solicitud_cliente.utm_source
      : "Sin identificar"

    if (!utmSources.includes(source)) {
      utmSources.push(source)
    }
  }

  // 2) Sum the monto of pagos for each UTM source
  for (let source of utmSources) {
    const totalMonto = this.pagos
      .filter((pago) => {
        let s = (pago.solicitud_cliente && pago.solicitud_cliente.utm_source)
          ? pago.solicitud_cliente.utm_source
          : "Sin identificar"
        return s === source
      })
      .reduce((accumulator, pago) => accumulator + pago.monto, 0)

    data.push(totalMonto)
    labels.push(source)
  }

  // 3) Construct the chart dataset
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Monto total por UTM Source",
        backgroundColor: [
          "rgba(230, 25, 75, 0.8)",  // Red-ish
          "rgba(60, 180, 75, 0.8)",  // Green-ish
          "rgba(255, 225, 25, 0.8)", // Yellow-ish
          "rgba(0, 130, 200, 0.8)",  // Blue-ish
          "rgba(245, 130, 48, 0.8)", // Orange-ish
          "rgba(240, 50, 230, 0.8)", // Magenta-ish
        ].slice(0, labels.length),
        data: data,
      },
    ],
  }

  return chartData
},

graficoPagosUtmCampaign() {
  let labels = []
  let data = []
  let utmCampaigns = []

  // 1) Collect unique UTM campaigns from pagos
  for (let pago of this.pagos) {
    // If "solicitud_cliente" or "utm_campaign" is missing/null, default to "Sin identificar"
    let campaign = (pago.solicitud_cliente && pago.solicitud_cliente.utm_campaign)
      ? pago.solicitud_cliente.utm_campaign
      : "Sin identificar"

    if (!utmCampaigns.includes(campaign)) {
      utmCampaigns.push(campaign)
    }
  }

  // 2) Sum the monto of pagos for each UTM campaign
  for (let campaign of utmCampaigns) {
    const totalMonto = this.pagos
      .filter((pago) => {
        let c = (pago.solicitud_cliente && pago.solicitud_cliente.utm_campaign)
          ? pago.solicitud_cliente.utm_campaign
          : "Sin identificar"
        return c === campaign
      })
      .reduce((accumulator, pago) => accumulator + pago.monto, 0)

    data.push(totalMonto)
    labels.push(campaign)
  }

  // 3) Construct the chart dataset
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Monto total por UTM Campaign",
        backgroundColor: [
          "rgba(230, 25, 75, 0.8)",  
          "rgba(60, 180, 75, 0.8)",
          "rgba(255, 225, 25, 0.8)",
          "rgba(0, 130, 200, 0.8)",
          "rgba(245, 130, 48, 0.8)",
          "rgba(240, 50, 230, 0.8)",
        ].slice(0, labels.length),
        data: data,
      },
    ],
  }

  return chartData
},


    total_pagos(){
      const total = this.pagos
    .filter(pago => pago.estado === 1) // Filter pagos with estado === 1
    .reduce((sum, pago) => sum + pago.monto, 0); 
    return total;
    },
    total_pagos_clientes(){
      const total = this.pagos
    .filter(pago => pago.estado === 1 && !pago.empresa) // Filter pagos with estado === 1
    .reduce((sum, pago) => sum + pago.monto, 0); 
    return total;
    },
    total_pagos_empresa(){
      const total = this.pagos
    .filter(pago => pago.estado === 1 && pago.empresa) // Filter pagos with estado === 1
    .reduce((sum, pago) => sum + pago.monto, 0); 
    return total;
    },
    cliente_unicos(){
      let clientes_ = []
      let clientes_id = []
      let ins = {}
      for (var o of this.ordenes){
        console.log(o)
        if(o.estado >= 2 && o.total_pagos_realizados > 0 && !clientes_id.includes(o.cliente.id))
        {
          ins = o.cliente
          ins.fecha = o.fecha
          clientes_id.push(o.cliente.id)
          clientes_.push(ins)
        }

      }
      return clientes_;
  
    },

    numero_clientes_con_pago(){
      let clientes_id = []
      for (var o of this.ordenes){
        console.log(o)
        if(o.estado >= 2 && o.monto_pagado > 0 && !clientes_id.includes(o.cliente.id))
        {
          clientes_id.push(o.cliente.id)
        }

      }
      return clientes_id.length;
    },

    graficoComunas(){
      var ret = this.groupBy(this.ordenes, 'comuna_id')
      let keys = Object.keys(ret)
      var labs = []
      var data = []
      for(var k = 0; k< keys.length; k++){
        labs.push(this.comunas.filter(x=>{return x.id == keys[k]})[0].Nombre)
        data.push(ret[keys[k]].reduce((a, b)=>{return (a+b.monto || 0) }, 0))
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labs,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }
      return graph;
    },
      graficoCategoriasVenta(){
      var labels = []
      var data = []
      for(var i = 0; i < this.categorias.length; i++){
        data.push(
          this.ordenes.reduce((a, b)=>{
                return (a+b.servicios.filter(z=>{
                  return this.servicios.filter(m=>{return m.id == z.examen_id}).length > 0 ? (this.examenes.filter(m=>{return m.id == z.examen_id})[0].categoria_id == this.categorias[i].id) : false}).reduce((c, d)=>{return c+d.precio || 0}, 0) || 0) }, 0))
        labels.push(this.categorias[i].nombre)
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }
      return graph;
    },
      graficoServicios2(){
      var labels = []
      var data = []
      let servicios_id = []
      for(var o of this.ordenes){
        for(var s of o.servicios){

          if(!servicios_id.includes(s.id)){
            servicios_id.push(s.servicio_id)
          }
        }

      }

      for(var s of servicios_id){
        data.push(this.ordenes.reduce((x,y) => {

          return x + y.servicios.filter(z=>{return z.id == s}).reduce((a, b)=>{
            return a + b.precio;
           },0)

        }, 0));

        if( s != 0 && s != null)
          labels.push(this.servicios.find(z=>{return z.id == s}).nombre)
        
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }
      return graph;
    },
      graficoServicios(){
      var labels = []
      var data = []
      let servicios_nombres = []      
      let ordenes_ = this.ordenes.filter(x=>{return x.estado >= 2})
      for(var o of ordenes_){
        for(var s of o.servicios){

    
          if(!servicios_nombres.includes(s.nombre)){
            servicios_nombres.push(s.nombre)
          }
        }

      }

      for(var s of servicios_nombres){
        data.push(this.ordenes.reduce((x,y) => {

          return x + y.servicios.filter(z=>{return z.nombre == s}).reduce((a, b)=>{
            return a + b.precio;
           },0)

        }, 0));


          labels.push(s)
        
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }
      return graph;
    },
    graficoSolicitudesUTMSource() {
  let labels = []
  let data = []
  let utmSources = []

  // Collect unique UTM sources
  for (let solicitud of this.solicitudes) {
    // If utm_source is null or undefined or empty, rename to "Sin identificar"
    const source = solicitud.utm_source ? solicitud.utm_source : "Sin identificar"

    if (!utmSources.includes(source)) {
      utmSources.push(source)
    }
  }

  // Aggregate data by UTM source
  for (let source of utmSources) {
    data.push(
      this.solicitudes.filter((solicitud) => {
        const solicitudSource = solicitud.utm_source ? solicitud.utm_source : "Sin identificar"
        return solicitudSource === source
      }).length
    )
    labels.push(source)
  }

  // Define a custom list of background colors
  const backgroundColors = [
    "rgba(230, 25, 75, 0.8)",  // Red-ish
    "rgba(60, 180, 75, 0.8)",  // Green-ish
    "rgba(255, 225, 25, 0.8)", // Yellow-ish
    "rgba(0, 130, 200, 0.8)",  // Blue-ish
    "rgba(245, 130, 48, 0.8)", // Orange-ish
    "rgba(240, 50, 230, 0.8)", // Magenta-ish
  ]

  // If you have more UTM sources than colors, 
  // you may want to randomly assign or generate more colors.

  // Create the chart configuration
  let graph = {
    hoverBackgroundColor: "blue",
    hoverBorderWidth: 10,
    labels: labels,
    datasets: [
      {
        label: "Solicitudes por UTM Source",
        // Dynamically assign colors to match the length of your data 
        backgroundColor: backgroundColors.slice(0, data.length),
        data: data,
      },
    ],
  }

  return graph
},
    graficoSolicitudesUTMCampaing() {
  let labels = []
  let data = []
  let utmSources = []

  // Collect unique UTM sources
  for (let solicitud of this.solicitudes) {
    // If utm_source is null or undefined or empty, rename to "Sin identificar"
    const source = solicitud.utm_campaign ? solicitud.utm_campaign : "Sin identificar"

    if (!utmSources.includes(source)) {
      utmSources.push(source)
    }
  }

  // Aggregate data by UTM source
  for (let source of utmSources) {
    data.push(
      this.solicitudes.filter((solicitud) => {
        const solicitudSource = solicitud.utm_campaign ? solicitud.utm_campaign : "Sin identificar"
        return solicitudSource === source
      }).length
    )
    labels.push(source)
  }

  // Define a custom list of background colors
  const backgroundColors = [
    "rgba(230, 25, 75, 0.8)",  // Red-ish
    "rgba(60, 180, 75, 0.8)",  // Green-ish
    "rgba(255, 225, 25, 0.8)", // Yellow-ish
    "rgba(0, 130, 200, 0.8)",  // Blue-ish
    "rgba(245, 130, 48, 0.8)", // Orange-ish
    "rgba(240, 50, 230, 0.8)", // Magenta-ish
  ]

  // If you have more UTM sources than colors, 
  // you may want to randomly assign or generate more colors.

  // Create the chart configuration
  let graph = {
    hoverBackgroundColor: "blue",
    hoverBorderWidth: 10,
    labels: labels,
    datasets: [
      {
        label: "Solicitudes por UTM Campaign",
        // Dynamically assign colors to match the length of your data 
        backgroundColor: backgroundColors.slice(0, data.length),
        data: data,
      },
    ],
  }

  return graph
},
    graficoSolicitudesClases() {
  let labels = []
  let data = []
  let utmSources = []

  // Collect unique UTM sources
  for (let solicitud of this.solicitudes) {
    // If utm_source is null or undefined or empty, rename to "Sin identificar"
    const source = solicitud.clase ? solicitud.clase : "Sin identificar"

    if (!utmSources.includes(source)) {
      utmSources.push(source)
    }
  }

  // Aggregate data by UTM source
  for (let source of utmSources) {
    data.push(
      this.solicitudes.filter((solicitud) => {
        const solicitudSource = solicitud.clase ? solicitud.clase : "Sin identificar"
        return solicitudSource === source
      }).length
    )
    labels.push(source)
  }

  // Define a custom list of background colors
  const backgroundColors = [
    "rgba(230, 25, 75, 0.8)",  // Red-ish
    "rgba(60, 180, 75, 0.8)",  // Green-ish
    "rgba(255, 225, 25, 0.8)", // Yellow-ish
    "rgba(0, 130, 200, 0.8)",  // Blue-ish
    "rgba(245, 130, 48, 0.8)", // Orange-ish
    "rgba(240, 50, 230, 0.8)", // Magenta-ish
  ]

  // If you have more UTM sources than colors, 
  // you may want to randomly assign or generate more colors.

  // Create the chart configuration
  let graph = {
    hoverBackgroundColor: "blue",
    hoverBorderWidth: 10,
    labels: labels,
    datasets: [
      {
        label: "Solicitudes por UTM Campaign",
        // Dynamically assign colors to match the length of your data 
        backgroundColor: backgroundColors.slice(0, data.length),
        data: data,
      },
    ],
  }

  return graph
},

      graficoEstados(){
      let nCanceladas = this.ordenes.filter(x=>{return x.pagado == 0}).length
      let nAgendadas = this.ordenes.filter(x=>{return x.pagado == 1}).length
      let labels = this.estados
      var data = []
      for(var i = 0; i < this.estados.length; i++){
        data.push(this.ordenes.filter(x=>{return x.estado == i}).length)
      }          
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ],
            data: data
          }
        ]
      }
      return graph;
    },
    
      graficoPagadoNoPagadoVenta(){
      let nCanceladas = this.ordenes.filter(x=>{return x.pagado == 0}).reduce((a,b) =>{return a+b.monto || 0 }, 0)
      let nAgendadas = this.ordenes.filter(x=>{return x.pagado == 1}).reduce((a,b) =>{return a+b.monto || 0 }, 0)
      let labels = ['Pagadas', 'No Pagadas']
          
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#3D9970", "#FF4136"],
            data: [nAgendadas, nCanceladas]
          }
        ]
      }
      return graph;
    },
      graficoPagadoNoPagado(){
      let nCanceladas = this.ordenes.filter(x=>{return x.pagado == 0}).length
      let nAgendadas = this.ordenes.filter(x=>{return x.pagado == 1}).length
      let labels = ['Pagadas', 'No Pagadas']
          
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#3D9970", "#FF4136"],
            data: [nAgendadas, nCanceladas]
          }
        ]
      }
      return graph;
    },
    ordenes()
    {
      return this.solicitudes.flatMap(solicitud => 
      (solicitud.ordenes || []).map(orden => ({
          ...orden,
          total_pagos_realizados: (orden.pagos || [])
              .filter(pago => pago.estado === 1)
              .reduce((sum, pago) => sum + pago.monto, 0)
      }))
  );
    },
    ordenes_con_pago()
    {
      return this.ordenes.filter(x=> x.total_pagos_realizados > 0);
    },
    graficoLineaSumaPagos(){

  
      const pagosConEmpresa = this.pagos.filter(x=>x.empresa)
      const pagosSinEmpresa = this.pagos.filter(x=>!x.empresa)

      var retWithEmpresa = this.groupBy(pagosConEmpresa, 'fecha_creado_dt')    
      var retWithoutEmpresa = this.groupBy(pagosSinEmpresa, 'fecha_creado_dt')
      var retBoth = this.groupBy(this.pagos, 'fecha_creado_dt')

      console.log("JOJOJO", retBoth)
 
      let labels1 = []
      let labels2 = []
      let labels3 = []
      let fecha;
      let desde_ = new Date(this.desde.a, this.desde.m-1, this.desde.d)
      let hasta_ = new Date(this.hasta.a, this.hasta.m-1, this.hasta.d)
      for(var itDia = new Date(desde_.getFullYear(), desde_.getMonth(), desde_.getDate()); itDia <= hasta_; itDia.setDate(itDia.getDate() + 1)){
        fecha = itDia.toLocaleDateString("en-US", {year:"numeric", month:'2-digit', day:'2-digit'})

        if (retWithEmpresa.hasOwnProperty(fecha)) {
          labels1.push({x:fecha, y:retWithEmpresa[fecha].filter(x=>{return x.estado != 0}).reduce((a, b)=>{return  (a+b.monto || 0) }, 0)})
          
        } else {
          labels1.push({x:fecha, y:0})         
        }
        if (retWithoutEmpresa.hasOwnProperty(fecha)) {
          labels2.push({x:fecha, y:retWithoutEmpresa[fecha].filter(x=>{return x.estado != 0}).reduce((a, b)=>{return  (a+b.monto || 0) }, 0)})
          
        } else {
          labels2.push({x:fecha, y:0})         
        }
        if (retBoth.hasOwnProperty(fecha)) {
          labels3.push({x:fecha, y:retBoth[fecha].filter(x=>{return x.estado != 0}).reduce((a, b)=>{return  (a+b.monto || 0) }, 0)})
          
        } else {
          labels3.push({x:fecha, y:0})         
        }
        console.log("asopdjaoisdj", labels1, retWithEmpresa)


      }
      let graph = {          
        datasets: [
          {
            label: "Ingreso Empresas", 
            data: labels1,
            fill: false,
            backgroundColor: 'rgba(46, 204, 64, 0.2)',
            borderColor: 'rgba(46, 204, 64, 0.5)'
          },
          {
            label: "Ingreso Clientes", 
            data: labels2,
            fill: false,
            backgroundColor: 'rgba(52, 152, 219, 0.2)',
            borderColor: 'rgba(52, 152, 219, 0.5)'
          },
          {
            label: "Ingreso Ambos", 
            data: labels3,
            fill: false,
            borderColor: 'rgba(255, 10, 13, 0.5)',
            backgroundColor: "rgba(255, 10, 13, 0.2)"
          },
          /*
          {
            label: "Ventas programadas", 
            data: ventasProgramadasTotal,
            fill: false,
            backgroundColor: 'rgba(246, 237, 116, 0.2)',
            borderColor: 'rgba(246, 237, 116, 0.5)'
          }
          */
        ]
      }
      return graph;
    },
    graficoNumeroSolicitudes(){


      const solicitudesConEmpresa = this.solicitudes.filter(x=>x.empresa)
      const solicitudesSinEmpresa = this.solicitudes.filter(x=>!x.empresa)

      console.log(solicitudesSinEmpresa.length, solicitudesConEmpresa.length, "JOJO")

      var retConEmpresa = this.groupBy(solicitudesConEmpresa, 'fecha_int')
      var retSinEmpresa = this.groupBy(solicitudesSinEmpresa, 'fecha_int')
      var retTotal =  this.groupBy(this.solicitudes, 'fecha_int')

 
      let labels1 = []
      let labels2 = []
      let labels3 = []
      let fecha;
      let desde_ = new Date(this.desde.a, this.desde.m-1, this.desde.d)
      let hasta_ = new Date(this.hasta.a, this.hasta.m-1, this.hasta.d)

      for(var itDia = new Date(desde_.getFullYear(), desde_.getMonth(), desde_.getDate()); itDia <= hasta_; itDia.setDate(itDia.getDate() + 1)){
        fecha = itDia.toLocaleDateString("en-US", {year:"numeric", month:'2-digit', day:'2-digit'})
        if (retConEmpresa.hasOwnProperty(fecha)) {
          labels1.push({x:fecha, y:retConEmpresa[fecha].length})          
        } else {
          labels1.push({x:fecha, y:0})          
        }
        if (retSinEmpresa.hasOwnProperty(fecha)) {
          labels2.push({x:fecha, y:retSinEmpresa[fecha].length})          
        } else {
          labels2.push({x:fecha, y:0})          
        }
        if (retTotal.hasOwnProperty(fecha)) {
          labels3.push({x:fecha, y:retTotal[fecha].length})          
        } else {
          labels3.push({x:fecha, y:0})          
        }

      }

      let graph = {          
        datasets: [
        {
            label: "Solicitudes Empresa", 
            data: labels1,
            fill: false,
            backgroundColor: 'rgba(46, 204, 64, 0.2)',
            borderColor: 'rgba(46, 204, 64, 0.5)'
          },
          {
            label: "Solicitudes Clientes", 
            data: labels2,
            fill: false,
            backgroundColor: 'rgba(52, 152, 219, 0.2)',
            borderColor: 'rgba(52, 152, 219, 0.5)'
          },

          {
            label: "Solicitudes Total", 
            data: labels3,
            fill: false,
            borderColor: 'rgba(255, 10, 13, 0.5)',
            backgroundColor: "rgba(255, 10, 13, 0.2)"
          },


        ]
      }

      return graph;
    },
  },
  mounted() {
    var hoy = new Date()
    hoy.setDate(hoy.getDate() - 7);
    this.dt_dia = hoy.toISOString().split('T')[0]
    
    hoy = new Date()
    hoy.setDate(hoy.getDate() + 7);
    this.dt_dia2 = hoy.toISOString().split('T')[0]

  },
  watch:{
/*     solicitudes:{
      handler(newValue, oldValue){
        console.log("JAJAJAJAJA CAMBIOOO", newValue)
      var dt_;
      var date_str;
      for (let v of newValue){
        console.log(v)
        dt_ = new Date(v.fecha_creado_iso);
      }
      console.log("COMO??")

      },
    deep: true
    }, */

    dt_dia(val){
        var datos = val.split('-')
        this.desde = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
        this.get_ordenes()
      
    },
    dt_dia2(val){
        var datos = val.split('-')
        this.hasta = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
        this.get_ordenes()
      
    },
    ordenes(){
      this.key +=1; 
    }
  },

}
</script>


<style scoped>
.graphContainerTop{
padding-left:10px; padding-right:10px; padding-bottom:20px; width:33%;  display:inline-block; 
}
td{
  text-align: left;
}
.articulo-principal{



}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
  position: relative;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

