<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <!-- Check if admin_datos is loaded and is superadmin -->
      <div v-if="admin_datos && admin_datos.superadmin" class="main-container">
        <!-- Loading State -->
        <div v-if="!loaded" class="articulo-principal loading-indicator">
          <p>Cargando datos del panel...</p>
        </div>

        <!-- Error State -->
        <div v-else-if="errorLoading" class="articulo-principal error-message">
           <p>Error al cargar los datos del panel: {{ errorLoading }}</p>
           <button @click="fetchDashboardData">Intentar de nuevo</button>
        </div>

        <!-- Data Loaded Successfully -->
        <div v-else class="articulo-principal">
          <!-- Welcome Message (Optional) -->
          <div style="margin-bottom: 30px; padding: 15px; background-color: #eef7ff; border-radius: 8px; border: 1px solid #cce5ff;">
             <h2 style="margin-top:0;">¡Muy buenos días {{admin_datos.nombre}}...! <img src="@/assets/logolight.webp" style="height:40px; vertical-align: middle;" alt="Logo"></h2>
            El propósito de este panel es ayudarte en tu negocio 🚀.
          </div>

          <!-- Dashboard Sections -->
          <div class="dashboard-grid">

            <!-- Presupuestos Enviados -->
            <div class="dashboard-section">
              <h3>Presupuestos Enviados</h3>
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Profesional</th>
                      <th>Cliente</th>
                      <th>Dirección Cliente</th>
                      <th>Fecha</th>
                      <th>Monto</th>
                      <th>Monto Pagado</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="presupuesto in presupuestosEnviados" :key="presupuesto.id" :title="'Detalles:\n' + imprimirItems(presupuesto)">
                      <td>
                        <span
                          v-if="isPaymentDiscrepancy(presupuesto)"
                          class="warning-icon"
                          title="Pago asociado a la orden es menor al monto del presupuesto aceptado"
                        >
                        ⚠️
                        </span>
                      </td>
                      <td>{{ presupuesto.profesional_nombre }}</td>
                      <td>{{ presupuesto.cliente_nombre }}</td>
                      <td>{{ presupuesto.cliente_direccion_corta }}</td>
                      <td>{{ formatDisplayDate(presupuesto.fecha) }}</td>
                      <td>${{ presupuesto.monto.toLocaleString('es-CL') }}</td>
                      <td>${{ (presupuesto.monto_pagado_orden || 0).toLocaleString('es-CL') }}</td>
                      <td>
                        <span :class="['badge', mapPresupuestoStatus(presupuesto.estado).className]">
                          {{ mapPresupuestoStatus(presupuesto.estado).text }}
                        </span>
                      </td>
                    </tr>
                     <tr v-if="!presupuestosEnviados.length">
                       <td colspan="8">No hay presupuestos enviados recientemente.</td> <!-- Corrected colspan to 8 -->
                     </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Próximas Visitas -->
            <div class="dashboard-section">
              <h3>Próximas Visitas</h3>
               <div class="table-container">
                <table>
                   <thead>
                     <tr>
                      <th>Fecha y Hora</th>
                      <th>Dirección</th>
                      <th>Comuna</th>
                      <th>Profesional</th>
                      <th>Cliente</th>
                      <th>Estado</th>
                    </tr>
                   </thead>
                  <tbody>
                    <tr v-for="visita in proximasVisitas" :key="visita.id">
                       <td>{{ formatDisplayDate(visita.fecha, false) }} {{ visita.hora }}</td> 
                       <td>{{ visita.direccion }}</td>
                       <td>{{ visita.comuna }}</td>
                       <td>{{ visita.profesional_nombre }}</td>
                       <td>{{ visita.cliente_nombre }}</td>
                       <td>
                        <span :class="['badge', mapVisitaStatus(visita.estado).className]">
                        {{ mapVisitaStatus(visita.estado).text }}
                      </span>
                       </td>
                     </tr>
                     <tr v-if="!proximasVisitas.length">
                        <td colspan="6">No hay visitas programadas.</td> <!-- Corrected colspan -->
                     </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Presupuestos pendientes de enviar -->
            <div class="dashboard-section">
              <h3>Presupuestos pendientes de enviar</h3>
               <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>ID Orden</th>
                      <th>ID Solicitud</th>
                      <th>Profesional</th>
                      <th>Cliente</th>
                      <th>Dirección</th>
                      <th>Días Pendiente</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="presupuesto in presupuestosPendientesEnviar" :key="presupuesto.orden_id">
                      <td>
                        <span
                          v-if="presupuesto.diasPendiente >= 5"
                          class="warning-icon"
                          title="Plazo vencido"
                        >
                        ⚠️
                        </span>
                      </td>
                      <td>#{{ presupuesto.orden_id }}</td>
                      <td>{{ presupuesto.solicitud_id }}</td>
                      <td>{{ presupuesto.profesional_nombre }}</td>
                      <td>{{ presupuesto.cliente }}</td>
                      <td>{{ presupuesto.direccion }}</td>
                      <td>{{ presupuesto.diasPendiente }}</td>
                    </tr>
                     <tr v-if="!presupuestosPendientesEnviar.length">
                       <td colspan="5">No hay presupuestos pendientes de enviar.</td>
                     </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Solicitudes sin Respuesta -->
            <div class="dashboard-section">
              <h3>Solicitudes sin Respuesta</h3>
               <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Cliente</th>
                      <th>Título</th>
                      <th>Descripción (inicio)</th>
                      <th>Fecha</th>
                      <th>Categoría</th>
                      <th>Tipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="solicitud in solicitudesSinRespuesta" :key="solicitud.id">
                      <td>{{ solicitud.cliente_nombre }}</td>
                      <td>{{ solicitud.titulo }}</td>
                      <td>{{ solicitud.descripcion_corta }}...</td>
                      <td>{{ formatDisplayDate(solicitud.fecha) }}</td>
                      <td>{{ solicitud.categoria }}</td>
                      <td>
                        <span :class="['badge', mapSolicitudType(solicitud.tipo).className]">
                          {{ mapSolicitudType(solicitud.tipo).text }}
                        </span>
                        <span v-if="solicitud.tipo === 'Privada' && solicitud.profesional_asignado" class="profesional-detail">
                             ({{ solicitud.profesional_asignado }})
                        </span>
                      </td>
                    </tr>
                     <tr v-if="!solicitudesSinRespuesta.length">
                       <td colspan="6">No hay solicitudes sin respuesta.</td>
                     </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Últimos Pagos -->
            <div class="dashboard-section">
              <h3>Últimos Pagos Recibidos</h3>
               <div class="table-container">
                 <table>
                   <thead>
                     <tr>
                      <th>Cliente</th>
                      <th>Profesional</th>
                      <th>Dirección</th>
                      <th>Fecha</th>
                      <th>Monto</th>
                    </tr>
                   </thead>
                   <tbody>
                     <tr v-for="pago in ultimosPagos" :key="pago.id">
                       <td>{{ pago.cliente_nombre }}</td>
                       <td>{{ pago.profesional_nombre }}</td>
                       <td>{{ pago.direccion }}</td>
                       <td>{{ formatDisplayDate(pago.fecha) }}</td>
                       <td>${{ pago.monto.toLocaleString('es-CL') }}</td>
                     </tr>
                      <tr v-if="!ultimosPagos.length">
                        <td colspan="5">No hay pagos registrados recientemente.</td>
                      </tr>
                   </tbody>
                 </table>
               </div>
            </div>

            <!-- Ordenes en Curso -->
            <div class="dashboard-section">
              <h3>Órdenes en Curso</h3>
               <div class="table-container">
                 <table>
                   <thead>
                     <tr>
                      <th>ID Orden</th>
                      <th>Cliente</th>
                      <th>Profesional</th>
                      <th>Servicio</th>
                      <th>Estado</th>
                      <th>Fecha Inicio</th>
                    </tr>
                   </thead>
                   <tbody>
                     <tr v-for="orden in ordenesEnCurso" :key="orden.id_orden">
                       <td>#{{ orden.id_orden }}</td>
                       <td>{{ orden.cliente_nombre }}</td>
                       <td>{{ orden.profesional_nombre }}</td>
                       <td>{{ orden.servicio }}</td>
                       <td><span :class="['badge', 'status-badge', getStatusClass(orden.estado)]">{{ orden.estado }}</span></td>
                       <td>{{ formatDisplayDate(orden.fecha_inicio) }}</td>
                     </tr>
                      <tr v-if="!ordenesEnCurso.length">
                        <td colspan="6">No hay órdenes en curso actualmente.</td>
                      </tr>
                   </tbody>
                 </table>
               </div>
            </div>

            <!-- Últimas Calificaciones -->
            <div class="dashboard-section">
              <h3>Últimas Calificaciones</h3>
               <div class="table-container">
                 <table>
                   <thead>
                     <tr>
                      <th>Cliente</th>
                      <th>Profesional</th>
                      <th>Calificación</th>
                      <th>Comentario</th>
                      <th>Fecha</th>
                    </tr>
                   </thead>
                   <tbody>
                     <tr v-for="calificacion in ultimasCalificaciones" :key="calificacion.id">
                       <td>{{ calificacion.cliente_nombre }}</td>
                       <td>{{ calificacion.profesional_nombre }}</td>
                       <td>{{ '⭐'.repeat(calificacion.calificacion) }} ({{ calificacion.calificacion }}/5)</td>
                       <td>"{{ calificacion.comentario }}"</td>
                       <td>{{ formatDisplayDate(calificacion.fecha) }}</td>
                     </tr>
                      <tr v-if="!ultimasCalificaciones.length">
                        <td colspan="5">No hay calificaciones recientes.</td>
                      </tr>
                   </tbody>
                 </table>
               </div>
            </div>

          </div> <!-- End Dashboard Grid -->

        </div>

      </div>
       <!-- Optional: Message for non-superadmin users -->
      <div v-else class="main-container">
         <p>Acceso no autorizado.</p>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

// Optional: Date formatting library (e.g., date-fns or moment)
// import { format } from 'date-fns';
// import { es } from 'date-fns/locale'; // Spanish locale if needed

export default {
  name: 'AdminDashboard',
  props: ['id'],
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded: false,
      errorLoading: null,
      presupuestosEnviados: [],
      proximasVisitas: [],
      presupuestosPendientesEnviar: [], // Added for the new section
      solicitudesSinRespuesta: [],
      ultimosPagos: [],
      ordenesEnCurso: [],
      ultimasCalificaciones: [],
      ESTADO_PRESUPUESTO_ACEPTADO: 2, // Assuming 2 means 'Aceptado'
    }
  },
  created() {
    this.fetchDashboardData();
    // this.initialize(); // Uncomment if needed from vuex actions
  },
  methods: {
    ...mapActions(['initialize']), // Keep if used from vuex actions

    async fetchDashboardData() {
      this.loaded = false;
      this.errorLoading = null;
      try {
        // Replace with your actual API endpoint if different
        const response = await axios.get(window.hostname+'/api/superadmin/dashboard');
        if (response && response.data) {
          this.presupuestosEnviados = response.data.presupuestos_enviados || [];
          this.proximasVisitas = response.data.proximas_visitas || [];
          this.presupuestosPendientesEnviar = response.data.presupuestos_pendientes_enviar || []; // Populate new data
          this.solicitudesSinRespuesta = response.data.solicitudes_sin_respuesta || [];
          this.ultimosPagos = response.data.ultimos_pagos || [];
          this.ordenesEnCurso = response.data.ordenes_en_curso || [];
          this.ultimasCalificaciones = response.data.ultimas_calificaciones || [];
        } else {
          throw new Error("Respuesta inválida desde el servidor");
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        this.errorLoading = error.response?.data?.error || error.message || "Ocurrió un error desconocido.";
      } finally {
        this.loaded = true;
      }
    },

    isPaymentDiscrepancy(presupuesto) {
      // Check if status is Aceptado AND paid amount is less than budget amount
      return (
        presupuesto.estado === this.ESTADO_PRESUPUESTO_ACEPTADO &&
        (presupuesto.monto_pagado_orden || 0) < presupuesto.monto
      );
    },

    imprimirItems(presupuesto) {
      if (
        presupuesto &&
        presupuesto.desgloce &&
        Array.isArray(presupuesto.desgloce.items)
      ) {
        return presupuesto.desgloce.items
          .map(item => `${item.nombre}: $${(item.precio || 0).toLocaleString('es-CL')}`)
          .join('\n');
      } else {
        return 'No hay items en el presupuesto.';
      }
    },

    // --- HELPER METHODS FOR STATUS/TYPE MAPPING ---

    mapPresupuestoStatus(status) {
      // Backend constants (example):
      // 0: Pendiente, 1: Enviado, 2: Aceptado, 3: Completado, 10: Rechazado
      switch (status) {
        case 0: return { text: 'Pendiente', className: 'status-pending' };
        case 1: return { text: 'Enviado', className: 'status-sent' };
        case 2: return { text: 'Aceptado', className: 'status-accepted' };
        case 3: return { text: 'Completado', className: 'status-completed' };
        case 10: return { text: 'Rechazado', className: 'status-rejected' };
        default: return { text: `Desconocido (${status})`, className: 'status-default' };
      }
    },

    mapVisitaStatus(status) {
      // Backend constants (example):
      // 0: Pendiente, 1: Agendada, 2: Confirmada, 3: En proceso, 4: Completada
      switch (status) {
        case 0: return { text: 'Pendiente', className: 'status-pending' };
        case 1: return { text: 'Agendada', className: 'status-scheduled' }; // Changed class for consistency
        case 2: return { text: 'Confirmada', className: 'status-accepted' }; // Or a specific 'confirmed' class
        case 3: return { text: 'En proceso', className: 'status-inprogress' }; // Changed class for consistency
        case 4: return { text: 'Completada', className: 'status-completed' };
        default: return { text: `Desconocido (${status})`, className: 'status-default' };
      }
    },

    mapSolicitudType(type) {
      // type comes as 'Pública' or 'Privada'
      if (type === 'Privada') {
        return { text: 'Privada', className: 'type-privada' };
      } else {
        return { text: 'Pública', className: 'type-publica' }; // Default to Pública
      }
    },

    getStatusClass(status) {
      const statusLower = status ? status.toLowerCase() : '';
      switch (statusLower) {
        case 'en progreso': return 'status-inprogress';
        case 'agendado': return 'status-scheduled';
        case 'completado': return 'status-completed';
        case 'esperando materiales': return 'status-waiting';
        case 'cancelado': return 'status-cancelled';
        case 'pendiente': return 'status-pending';
        default: return 'status-default';
      }
    },

    // Optional: Date Formatting Helper
    formatDisplayDate(dateString, showTime = true) {
  if (!dateString) return 'N/A';

  try {
    // Match format YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss
    const match = dateString.match(/^(\d{4})-(\d{2})-(\d{2})(?:[T ](\d{2}):(\d{2}))?/);

    if (!match) return dateString;

    const [, year, month, day, hour = '00', minute = '00'] = match;

    if (!showTime) {
      return `${day}/${month}/${year}`;
    }

    return `${day}/${month}/${year} ${hour}:${minute}`;
  } catch (e) {
    console.warn("Could not format date:", dateString, e);
    return dateString;
  }
},

  },
  computed: {
    ...mapGetters(['comunas', 'categorias', 'regiones', 'tipos', 'admin_datos']),
  },
  mounted() {
    // Initial check on mount
    if (!this.admin_datos || !this.admin_datos.superadmin) {
       console.warn("User is not a superadmin or admin_datos not loaded initially, redirecting.");
       // Use nextTick to ensure component teardown/navigation happens cleanly
       this.$nextTick(() => {
         this.$router.push('/'); // Redirect to home or login page
       });
    }
  },
  watch: {
     // Watch for changes in admin_datos if it loads asynchronously after mount
     admin_datos(newVal, oldVal) {
       // Check if the new value is loaded and the user is NOT a superadmin
       if (newVal && !newVal.superadmin) {
          console.warn("Admin_datos updated, user is not superadmin, redirecting.");
          this.$nextTick(() => {
             this.$router.push('/');
          });
       }
       // Optional: Handle case where admin_datos becomes null/undefined after being valid
       // if (!newVal && oldVal && oldVal.superadmin) { ... }
     }
  }
}
</script>

<style scoped>
/* --- Existing Styles --- */
.warning-icon {
  display: inline-block; /* Allows margin */
  margin-left: 8px; /* Space between badge and icon */
  color: #ffc107; /* Yellow/Orange warning color */
  font-weight: bold;
  /* font-size: 1.1em; /* Slightly larger if needed */
  cursor: default; /* Indicate it's informational */
}
td, th {
  text-align: left;
  padding: 8px 12px;
  border-bottom: 1px solid #eee;
  vertical-align: middle; /* Center badges vertically */
}
th {
  background-color: #f8f9fa;
  font-weight: 600;
   vertical-align: middle;
}
tr:hover {
    background-color: #f1f1f1;
}
.articulo-principal {
    /* Add padding or margin if needed */
}
table {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
}
.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 80px; /* Adjust based on TopNav height */
  padding-left: 260px; /* Adjust based on SideNav width */
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box; /* Include padding in width/height calculation */
}
.main-container {
  background-color: white;
  margin: 20px;
  padding: 20px 20px 10px 20px; /* Adjust padding as needed */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr; /* Default to single column */
  gap: 25px;
}
@media (min-width: 1200px) {
  .dashboard-grid {
    grid-template-columns: 1fr 1fr; /* Two columns on larger screens */
  }
}
.dashboard-section {
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 6px;
  border: 1px solid #e9ecef;
  overflow: hidden; /* Prevents content spilling */
}
.dashboard-section h3 {
  margin-top: 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  color: #333;
  font-size: 1.1em; /* Slightly larger heading */
}
.table-container {
  max-width: 100%; /* Ensure container doesn't exceed parent */
  overflow-x: auto; /* Allow horizontal scrolling on small screens */
}

/* --- Base Badge Style --- */
.badge {
    display: inline-block; /* Allow padding and centering */
    padding: 4px 10px;
    border-radius: 12px; /* Pill shape */
    font-size: 0.8em;
    font-weight: 600; /* Make text bolder */
    line-height: 1; /* Prevent extra space */
    text-align: center;
    white-space: nowrap; /* Prevent wrapping */
    vertical-align: baseline; /* Align with text nicely */
    color: #fff; /* Default text color */
}

/* --- Status Badge Styles (Ordenes, Presupuestos, Visitas) --- */
/* .status-badge {} */ /* Base class if needed, but .badge often suffices */
.status-inprogress { background-color: #007bff; } /* Blue */
.status-scheduled { background-color: #ffc107; color: #212529; } /* Yellow */
.status-completed { background-color: #28a745; } /* Green */
.status-waiting { background-color: #fd7e14; } /* Orange */
.status-cancelled { background-color: #6c757d; } /* Grey */
.status-pending { background-color: #6c757d; } /* Grey (or choose another color) */
.status-default { background-color: #17a2b8; } /* Teal */
/* Presupuesto specific statuses */
.status-sent { background-color: #17a2b8; } /* Teal/Cyan */
.status-accepted { background-color: #20c997; } /* Teal Green */
.status-rejected { background-color: #dc3545; } /* Red */


/* --- Type Badge Styles (Solicitudes) --- */
.type-publica { background-color: #0dcaf0; } /* Light Blue/Info */
.type-privada { background-color: #6f42c1; } /* Purple */

/* Detail for assigned professional */
.profesional-detail {
    font-size: 0.9em;
    color: #555;
    margin-left: 5px;
}

/* --- Loading/Error Styles --- */
.loading-indicator, .error-message {
  text-align: center;
  padding: 40px 20px;
  color: #555;
}
.error-message {
  color: #dc3545; /* Bootstrap danger color */
  background-color: #f8d7da; /* Bootstrap danger background */
  border: 1px solid #f5c6cb; /* Bootstrap danger border */
  border-radius: 4px;
  margin-bottom: 20px;
}
.error-message button {
  margin-top: 10px;
  padding: 5px 15px;
  cursor: pointer;
  border: 1px solid #dc3545;
  background-color: #dc3545;
  color: white;
  border-radius: 4px;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.error-message button:hover {
  background-color: #c82333; /* Darker danger color */
  border-color: #bd2130;
}

/* --- Responsive Styles --- */
@media (max-width: 800px) {
  .column-right {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 70px; /* Adjust if TopNav shrinks */
  }
  .main-container {
    margin: 10px 0; /* Reduce margin on smaller screens */
  }
  .dashboard-grid {
    grid-template-columns: 1fr; /* Force single column */
  }
  td, th {
    font-size: 0.85em; /* Slightly smaller font in tables */
    padding: 6px 8px; /* Reduce padding */
  }
  .dashboard-section h3 {
    font-size: 1em; /* Adjust heading size */
  }
  .badge {
    font-size: 0.75em; /* Smaller badges */
    padding: 3px 8px;
  }
}
</style>