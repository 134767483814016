<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div v-if="admin_datos.user_type === 'property_manager' || admin_datos.user_type === 'jobbing_admin'" class="main-container">
        <div v-if="loaded" class="articulo-principal">
          <!-- Header con Título y Filtro de Fecha -->
          <div class="header-container">
            <h2>Solicitudes de Clientes</h2>
            <div class="date-filter-container">
              <label for="dateFilter" class="date-filter-label">Filtrar por fecha:</label>
              <select id="dateFilter" v-model="dateFilter" @change="fetchSolicitudes" class="date-filter-select">
                <option value="today">Hoy</option>
                <option value="last_7_days">Últimos 7 días</option>
                <option value="last_30_days">Últimos 30 días</option>
                <option value="last_2_months">Hace dos meses</option>
                <option value="last_6_months">Últimos 6 meses</option>
                <option value="last_12_months">Últimos 12 meses</option>
                <option value="all">Todos</option>
              </select>
            </div>
          </div>
        
          Puedes arrastrar la tabla hacia los lados con el cursor para ver más columnas.
           <!-- Search Bar -->
           <div style="margin-top: 10px; margin-bottom: 20px">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Buscar en comuna, título, estado, dirección o descripción..."
              style="width: 100%; padding: 8px; font-size: 16px;"
            />
          </div>

          <div class="tabs">
    <button
      :class="{'active-tab': currentTab === 'active'}"
      @click="currentTab = 'active'"
    >
      Activas
    </button>
    <button
      :class="{'active-tab': currentTab === 'accion requerida'}"
      @click="currentTab = 'accion requerida'"
    >
      Acción requerida
    </button>
    <button
      :class="{'active-tab': currentTab === 'inactive'}"
      @click="currentTab = 'inactive'"
    >
      Inactivas
    </button>
    <button
      :class="{'active-tab': currentTab === 'eliminadas'}"
      @click="currentTab = 'eliminadas'"
    >
      Eliminadas
    </button>
  </div>

  <!-- Table Container -->
  <div
    class="table-container"
    ref="tableContainer"
    @mousedown="dragStart"
    @mousemove="dragMove"
    @mouseup="dragEnd"
    @mouseleave="dragEnd"
    @touchstart="dragStart"
    @touchmove="dragMove"
    @touchend="dragEnd"
    @touchleave="dragEnd"
    @touchcancel="dragEnd"
  >
    <table class="tablex">
      <thead>
        <tr>
          <!-- Table Headers -->
          <th>ID</th>
          <th v-if="admin_datos.user_type === 'jobbing_admin'">Empresa</th>
          <th>Acción Requerida</th>
          <th>Fecha Solicitud</th>
          <th>Título</th>
          <th>Prioridad</th>
          <th style="min-width: 200px;">Dirección</th>
          <th style="min-width: 200px;">Ubicación Llaves</th>
          <th style="min-width: 320px;">Comentarios</th>
          <th>Estado</th>
          <th>Porcentaje completado</th>
          <th>Atendido por</th>
          <th>Fecha Visita</th>
          <th>Fecha Envío Presupuesto</th>
          <th>Fecha Aprobación</th>
          <th>Fecha Inicio</th>
          <th>Fotos</th>
          <th>Videos</th>
          <th>Comprobante de Pago</th>
          <th>Factura al Cliente</th>
          <th v-if="admin_datos.user_type === 'jobbing_admin'">Factura del Profesional</th>
          <th v-if="admin_datos.user_type === 'jobbing_admin'">Pago hacia el Profesional</th>
          <th><span class="nowrap">Monto Total</span> (con IVA)</th>
          <th><span class="nowrap">Monto Pagado</span> (con IVA)</th>
          <th><span class="nowrap">Monto por Cobrar</span> al Cliente (con IVA)</th>
          <th v-if="hasMargenCaptacion()"><span style="white-space: nowrap;">Monto por Cobrar</span> a Jobbing (con IVA)</th>
          <th v-if="hasMargenCaptacion()">Factura hacia Jobbing</th>
          <th>Fecha Calificación</th>
          <th>Calificación</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(solicitud, index) in filteredSolicitudes" >
          <tr
            :title="`Descripción: ${solicitud.descripcion != null ? solicitud.descripcion : 'No especificado'}`"
            @click="toggleDetails('details' + index, $event)"
            @contextmenu.prevent="openContextMenu($event, solicitud)"
          >
            <!-- Table Data -->
            <td>{{ solicitud.id }} <!-- <span v-if=" solicitud.orden">a{{ solicitud.orden.facturas }}a</span> --></td>
            <td v-if="admin_datos.user_type === 'jobbing_admin'">
              <span
                style="font-weight: 700;"
                :style="{ 'color': solicitud.empresa && solicitud.empresa.atributos.color ? solicitud.empresa.atributos.color : '#000' }"
              >
                {{ solicitud.empresa.nombre }}
              </span>
            </td>
            <td>
              <div class="custom-tooltip-wrapper">
                <img 
                  v-if="solicitudRequiereAccion(solicitud, 'Aprobar presupuesto')" 
                  alt="Pendiente" 
                  style="width: 60px; height:60px;" 
                  src="@/assets/iconos/pendiente-pres.svg" 
                />
                <span class="custom-tooltip-text">Requiere aprobar presupuesto</span>
              </div>

              <div class="custom-tooltip-wrapper">
                <img 
                  v-if="solicitudRequiereAccion(solicitud, 'Pagar')" 
                  alt="Pendiente" 
                  style="width: 60px; height:60px;" 
                  src="@/assets/iconos/dollar.svg" 
                />
                <span class="custom-tooltip-text">Requiere pagar</span>
              </div>
            </td>
            <td>{{ extractDate(solicitud.fecha_creado).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' }) }}</td>
            <td>{{ solicitud.titulo }}</td>
            <td><div v-html="getPrioridad(solicitud)"></div></td>
            <td>
              {{ solicitud.direccion }}<span v-if="solicitud.numero_apartamento != null"> (Departamento {{ solicitud.numero_apartamento }})</span>,
              {{ solicitud.comuna_nombre }}
            </td>
            <td>
              <div v-if="solicitud.atributos && solicitud.atributos.ubicacion_llaves && solicitud.atributos.ubicacion_llaves.length > 0">
                <div v-for="ubicacion in solicitud.atributos.ubicacion_llaves" :key="ubicacion.id">
                  <div style="white-space: pre-wrap;">
                    {{ formatFecha(ubicacion.fecha) }}
                    <b>{{ ubicacion.nombre }}</b>:
                    <span v-if="ubicacion.ubicacion">{{ ubicacion.ubicacion }}</span>
                    <span style="font-style: italic;" v-else>Desconocida</span>
                    <br />
                  </div>
                </div>
              </div>
              <div style="font-style: italic;" v-else>
                Desconocida
              </div>
            </td>
            <td>
              <div v-if="solicitud.atributos.comentarios && solicitud.atributos.comentarios.length > 0">
                <button
                  style="margin-bottom: 20px;"
                  class="download-button"
                  v-if="solicitud.atributos.comentarios.length > 2"
                  @click="toggleMostrarTodos(solicitud)"
                >
                  {{ solicitud.mostrar_todos_los_comentarios ? 'Esconder historial' : 'Mostrar historial' }}
                  ({{ solicitud.atributos.comentarios.length - 2 }})
                </button>
                <div
                  v-for="(comentario, idx) in (solicitud.mostrar_todos_los_comentarios ? solicitud.atributos.comentarios : solicitud.atributos.comentarios.slice(-2))"
                  :key="idx"
                  class="comentario"
                >
                  <div style="white-space: pre-wrap;">
                    {{ formatFecha(comentario.fecha) }}
                    <b>{{ comentario.autor_nombre }}</b>: {{ comentario.comentario }}
                    <br />
                  </div>
                </div>
              </div>
              <div style="font-style: italic;" v-else> Sin comentarios </div>
            </td>
            <td><div style="font-weight: 700;" :style="{ 'color': solicitud.estado_front.color }"> {{ solicitud.estado_front.estado }} </div></td>
            <td>{{ solicitud.estado_front.porcentaje }}%</td>
            <td>{{ getAtendidoPor(solicitud) }}</td>
            <td>{{ getFechaVisita(solicitud) }}</td>
            <td>{{ getFechaEnvioPresupuesto(solicitud) }}</td>
            <td><div v-html="getFechaAprobacion(solicitud)"></div></td>
            <td><div v-html="getFechaInicio(solicitud)"></div></td>
            <td>
              <div v-html="hasFotos(solicitud) ? getFotosAntes(solicitud).length + ' fotos de antes y ' + getFotosDespues(solicitud).length + ' fotos de después' : '<span style=\'white-space:nowrap;\'>No hay<span/> fotos'"></div>
            </td>
            <td>
              <div v-if="getVideo(solicitud) === 'Descargar'">
                <div v-for="archivo in solicitud.orden.archivos.filter(x => x.categoria == 'Video')" :key="archivo.id" style="margin-bottom: 4px;">
                  <button class="download-button" @click="handleClickArchivo($event, archivo)">Descargar (id: {{ archivo.id }})</button>
                </div>
              </div>
              <div class="yellowish" v-else>
                No tiene
              </div>
            </td>
            <td>
              <div v-if="getComprobantePago(solicitud) === 'Descargar'">
                <div v-for="archivo in solicitud.orden.archivos.filter(x => x.categoria == 'Comprobante de Pago')" :key="archivo.id" style="margin-bottom: 4px;">
                  <button class="download-button" @click="handleClickArchivo($event, archivo)">Descargar (id: {{ archivo.id }})</button>
                </div>
              </div>
              <div class="yellowish" v-else>
                No tiene
              </div>
            </td>
            <td>
              <div v-if="getFacturaCliente(solicitud) === 'Descargar'">
                <div v-for="archivo in solicitud.orden.archivos.filter(x => x.categoria == 'Factura al Cliente')" :key="archivo.id" style="margin-bottom: 4px;">
                  <button class="download-button" @click="handleClickArchivo($event, archivo)">Descargar (id: {{ archivo.id }})</button>
                </div>
              </div>
              <div class="yellowish" v-else>
                No tiene
              </div>
            </td>
            <th v-if="admin_datos.user_type === 'jobbing_admin'">
              <div v-if="solicitud.orden && solicitud.orden.archivos && solicitud.orden.archivos.some(x => x.categoria == 'Factura del Profesional')">
                <div v-for="archivo in solicitud.orden.archivos.filter(x => x.categoria == 'Factura del Profesional')" :key="archivo.id" style="margin-bottom: 4px;">
                  <button class="download-button" @click="handleClickArchivo($event, archivo)">Descargar (id: {{ archivo.id }})</button>
                </div>
              </div>
              <div class="yellowish" v-else>
                No tiene
              </div>
            </th>
            <th v-if="admin_datos.user_type === 'jobbing_admin'">
              <div v-if="solicitud.orden">
                ${{ Intl.NumberFormat('de-DE').format((1-solicitud.orden.profesional_comision)*getMontoPorPagar(solicitud))}}
              </div>

            </th>
            <td>{{ getMontoTotal(solicitud) }}</td>
            <td>{{ getMontoPagado(solicitud) }}</td>
            <td>{{ getMontoPorPagarString(solicitud) }}</td>
            <td v-if="hasMargenCaptacion()">${{ Intl.NumberFormat('de-DE').format(getMontoPorCobrarAJobbing(solicitud)) }}</td>
            <td v-if="hasMargenCaptacion()">
              <div v-if="getFacturaHaciaJobbing(solicitud) === 'Descargar'">
                <div v-for="archivo in solicitud.orden.archivos.filter(x => x.categoria == 'Factura hacia Jobbing')" :key="archivo.id" style="margin-bottom: 4px;">
                  <button class="download-button" @click="handleClickArchivo($event, archivo)">Descargar (id: {{ archivo.id }})</button>
                </div>
              </div>
              <div class="yellowish" v-else>
                No tiene
              </div>
            </td>
            <td>{{ getFechaCalificacion(solicitud) }}</td>
            <td>
              <div style="white-space: nowrap;" v-if="solicitud.orden && solicitud.orden.calificacion && solicitud.orden.calificacion.calificacion">
                <b-icon class="star" icon="star-fill" v-for="_ in solicitud.orden.calificacion.calificacion" :key="_"></b-icon>
              </div>
              <div v-else>
                Sin calificación
              </div>
            </td>
          </tr>

          <!-- Row for Details -->
          <tr :id="'details' + index" style="display: none; background-color: rgb(250,250,250);">
            <td colspan="22">
              <div class="details-container">
                <!-- Información de contacto -->
                <div class="sectionv">
                  <h2>Información de contacto 📋</h2>
                  <table style="min-width: 400px;">
                    <tr>
                      <td><b>Nombre</b></td>
                      <td>{{ solicitud.nombre }} {{ solicitud.apellido }}</td>
                    </tr>
                    <tr>
                      <td><b>Email</b></td>
                      <td>{{ solicitud.email }}</td>
                    </tr>
                    <tr>
                      <td><b>Teléfono</b></td>
                      <td>{{ solicitud.telefono }}</td>
                    </tr>
                  </table>
                </div>

                <!-- Información de la Orden -->
                <div class="sectionv" v-if="solicitud.orden">
                  <h2>Información de la orden 📦</h2>
                  <div v-if="solicitud.orden.servicios && solicitud.orden.servicios.length">
                    <h3>Servicios agregados:</h3>
                    <table style="min-width: 400px;">
                      <tr>
                        <th>Nombre</th>
                        <th>Precio (con IVA)</th>
                      </tr>
                      <tr v-for="s in solicitud.orden.servicios" :key="s.id">
                        <td>{{ s.nombre }}</td>
                        <td>${{ Intl.NumberFormat('de-DE').format(s.precio) }}</td>
                      </tr>
                    </table>
                    <br />
                  </div>
                  <div v-if="solicitud.orden.pagos && solicitud.orden.pagos.length">
                    <h3>Pagos</h3>
                    <table style="min-width: 400px;">
                      <tr>
                        <th>Id</th>
                        <th>Monto</th>
                        <th>Estado</th>
                        <th>Fecha</th>
                        <th>Método</th>
                        <th>Recibido por</th>
                        <th v-if="admin_datos.user_type === 'jobbing_admin'">Transferido</th>
                        <th v-if="admin_datos.user_type === 'jobbing_admin'">Acciones</th>
                      </tr>
                      <tbody>
                        <tr v-for="pago in solicitud.orden.pagos" :key="pago.id">
                          <td>{{ pago.id }}</td>
                          <td>${{ Intl.NumberFormat('de-DE').format(pago.monto) }}</td>
                          <td>
                            <div style="color: #2ECC40;" v-if="pago.estado == 1">
                              Pagado
                            </div>
                            <div style="color: #FF4136;" v-else-if="pago.estado == 2">
                              Devuelto
                            </div>
                            <div style="color: #AAAAAA;" v-else-if="pago.estado == 0">
                              No pagado
                            </div>
                          </td>
                          <td>{{ new Date(pago.fecha_pagado.y, pago.fecha_pagado.m - 1, pago.fecha_pagado.d).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' }) }}</td>
                          <td>{{ pago.metodo_pago }}</td>
                          <td>{{ pago.recibido_por == 'jobbing' ? 'Jobbing' : admin_datos.empresa.nombre }}</td>
                          <td v-if="admin_datos.user_type === 'jobbing_admin'">
                            <span style="color:#FF4136;" v-if="pago.transferencia_id  == null"> <b>No</b></span>
                            <span  style="color:#2ECC40;" v-else> <b>Si</b></span>
                          </td>
                          <td v-if="admin_datos.user_type === 'jobbing_admin'">
                          <button @click="eliminarPago(pago)" class="red-button">
                            Eliminar Pago <b-icon icon="trash"></b-icon>
                          </button>
                        </td>
                        </tr>
  
                      </tbody>
                    </table>
                    <br />
                  </div>

                  <!-- Visitas -->
                  <div v-if="solicitud.orden.visitas && solicitud.orden.visitas.length">
                    <h3>Visitas</h3>
                    <table style="min-width: 400px;">
                      <tr>
                        <th>Estado</th>
                        <th>Fecha</th>
                        <th>Hora</th>
                        <th>Servicios Realizados</th>
                      </tr>
                      <tbody>
                        <tr v-for="visita in solicitud.orden.visitas" :key="visita.id">
                          <td :style="{ 'color': visita.estado == 10 ? '#FF4136' : visita.estado == 4 ? '#2ECC40' : '' }">{{ getEstadoVisita(visita) }}</td>
                          <td>{{ extractDate(visita.fecha_).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }) }}</td>
                          <td>{{ timeConvert(visita.desde) }} hrs</td>
                          <td>
                            <div v-if="solicitud.orden && solicitud.orden.servicios && solicitud.orden.servicios.some(x => x.visita_id == visita.id)">
                              <div v-for="servicio in solicitud.orden.servicios.filter(x => x.visita_id == visita.id)" :key="servicio.id">
                                <b>{{ servicio.nombre }}</b>, por ${{ Intl.NumberFormat('de-DE').format(servicio.precio) }}
                              </div>
                            </div>
                            <div v-else>
                              No se especifica
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                  </div>

                  <!-- Presupuestos -->
                  <div v-if="solicitud.orden.presupuestos && solicitud.orden.presupuestos.length">
      <h3>Presupuestos</h3>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Fecha Creado</th>
            <th>Tiempo Ejec.</th>
            <th><span class="nowrap">Monto presupuesto</span><br /> (con IVA)</th>
            <th><span class="nowrap">Monto presupuesto</span><br /> (sin IVA)</th>
            <th><span class="nowrap">Monto Aprobado</span><br /> (con IVA)</th>
            <th><span class="nowrap">Monto no Ejecutado</span><br /> (con IVA)</th>
            <th><b><span class="nowrap">Total a cobrar</span><br /> (con IVA)</b></th>
            <th>Estado</th>
            <th>Fecha Aprobado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="presupuesto in solicitud.orden.presupuestos" :key="presupuesto.id"
              @mouseenter="presupuesto_showTooltip($event, presupuesto)"
              @mouseleave="presupuesto_hideTooltip">
            <td>{{ presupuesto.id }}</td>
            <td>
              {{ extractDate(presupuesto.fecha_creado_iso)
                  ? extractDate(presupuesto.fecha_creado_iso).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' })
                  : 'No especifica' }}
            </td>
            <td>
              <span style="font-weight: 700;" v-if="presupuesto.tiempo_de_ejecucion">
                {{ presupuesto.tiempo_de_ejecucion }} dia(s)
              </span>
              <span v-else>Desconocido</span>
            </td>
            <td>${{ Intl.NumberFormat('de-DE').format(presupuesto.monto_total) }}</td>
            <td>${{ Intl.NumberFormat('de-DE').format(Math.round(presupuesto.monto_total / 1.19)) }}</td>
            <td>
              ${{ Intl.NumberFormat('de-DE').format(getTotalApprovedPresupuesto(presupuesto)) }}
              ({{ presupuesto.monto_total > 0 ? Math.round((getTotalApprovedPresupuesto(presupuesto) / presupuesto.monto_total) * 100) : 0 }}%)
            </td>
            <td>${{ Intl.NumberFormat('de-DE').format(getTotalNoRealizadoPresupuesto(presupuesto)) }}</td>
            <td>
              <b>
                ${{ Intl.NumberFormat('de-DE').format(getTotalApprovedPresupuesto(presupuesto) - getTotalNoRealizadoPresupuesto(presupuesto)) }}
              </b>
            </td>
            <td>
              <div v-html="getEstadoPresupuesto(presupuesto)"></div>
            </td>
            <td>
              <div style="color: #2ECC40; font-weight: 700;" v-if="presupuesto.fecha_aprobado_iso">
                {{ extractDate(presupuesto.fecha_aprobado_iso)
                    ? extractDate(presupuesto.fecha_aprobado_iso).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' })
                    : 'No aprobado' }}
              </div>
              <div v-else>
                No aprobado
              </div>
            </td>
            <td>
              <button @click="descargarPresupuesto(presupuesto)" style="margin: 0px 2px" class="download-button">
                Descargar <b-icon icon="download"></b-icon>
              </button>
              <button
                v-if="presupuesto.estado == 1 || presupuesto.estado == 0"
                @click="reenviarPresupuesto(presupuesto)"
                style="margin: 0px 2px"
                class="download-button"
              >
                {{ presupuesto.estado == 0 ? 'Enviar' : 'Reenviar' }}
                <b-icon icon="arrow-counterclockwise"></b-icon>
              </button>
              <button
                v-if="presupuesto.estado >= 1 && presupuesto.estado < 10"
                @click="copiarLinkPresupuesto(presupuesto)"
                style="margin: 0px 2px"
                class="download-button"
              >
                Copiar link <b-icon icon="clipboard"></b-icon>
              </button>
              <button
                v-if="presupuesto.estado == 1 || presupuesto.estado == 0"
                @click="abirModalAprobadoPresupuesto(presupuesto)"
                style="margin: 0px 2px; background-color: #2ECC40;"
                class="download-button"
              >
                Aprobar <b-icon icon="check-circle"></b-icon>
              </button>
              <button
                v-if="presupuesto.estado == 1 || presupuesto.estado == 0"
                @click="abrirModalRechazarPresupuesto(presupuesto)"
                style="margin: 0px 2px;"
                class="red-button"
              >
                Rechazar <b-icon icon="x-circle"></b-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Tooltip element -->
    <div v-if="presupuesto_activeTooltip" class="tooltip-box" :style="{ top: presupuesto_tooltipY + 'px', left: presupuesto_tooltipX + 'px' }">
      <strong>Detalles:</strong>
      <div v-if="presupuesto_activeTooltip.desgloce && presupuesto_activeTooltip.desgloce.items && presupuesto_activeTooltip.desgloce.items.length">
        <ul>
          <li v-for="item in presupuesto_activeTooltip.desgloce.items" :key="item.id">
            <strong>{{ item.nombre }}</strong> - ${{ formatPrice(item.precio) }} -
            <span style="color: #2ECC40; font-weight: 700;" v-if="item.aprobado">Aprobado</span>
            <span style="color: #FF4136; font-weight: 700;" v-else>No Aprobado</span>
            <span v-if="typeof item.no_realizado !== 'undefined'">
              - <span v-if="item.no_realizado">No Realizado</span><span v-else>Realizado</span>
            </span>
          </li>
        </ul>
      </div>
      <div v-else>
        Sin detalles.
      </div>
    </div>
                </div>

                <!-- Fotos -->
                <div class="sectionv">
                  <h2>Fotos 📸</h2>
                  <div v-if="hasFotos(solicitud)">
                    <!-- Download Button -->
                    <div style="transform: translateY(-20px);">
                      <DownloadFotoOrden :ordenId="solicitud.orden.id" />
                    </div>

                    <!-- Antes Photos Section -->
                    <h3>Antes</h3>
                    <div class="fotos-container">
                      <div class="image-wrapper" v-for="foto in getFotosAntes(solicitud)" :key="foto.id">
                        <img
                          :src="obtenerURLFoto(foto)"
                          alt="Foto Antes"
                          @click="openImageModal(obtenerURLFoto(foto))"
                          class="clickable-image"
                        />
                        <div class="hover-message">
                          {{ formatDate(foto.fecha_creado) }}
                        </div>
                      </div>
                    </div>

                    <!-- Después Photos Section -->
                    <h3>Después</h3>
                    <div class="fotos-container">
                      <div class="image-wrapper" v-for="foto in getFotosDespues(solicitud)" :key="foto.id">
                        <img
                          :src="obtenerURLFoto(foto)"
                          alt="Foto Después"
                          @click="openImageModal(obtenerURLFoto(foto))"
                          class="clickable-image"
                        />
                        <div class="hover-message">
                          {{ formatDate(foto.fecha_creado) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-else style="color: red;">No hay fotos</p>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <!--  {{solicitudes}} -->
  </div>
        </div>
        <div v-else class="articulo-principal">
          Cargando...
        </div>
      </div>
    </div>
    <!-- Context Menu -->
    <div
      v-if="contextMenuVisible"
      :style="{ top: contextMenuY + 'px', left: contextMenuX + 'px' }"
      class="context-menu"
    >
      <ul>
        <li @click="abrirModalEditarSolicitud(selectedSolicitud)">Editar Solicitud</li>
        <li @click="abrirModalAgregarComentario(selectedSolicitud)">Agregar Comentario</li>
        <li @click="abrirModalSubirArchivo(selectedSolicitud, 'Comprobante de Pago')">Agregar Comprobante de Pago</li>
        <li @click="abrirModalSubirArchivo(selectedSolicitud, 'Factura al Cliente')">Agregar Factura al Cliente</li>
        <li v-if="this.admin_datos.user_type == 'jobbing_admin'" @click="abrirModalSubirArchivo(selectedSolicitud, 'Factura del Profesional')">Agregar Factura del Profesional</li>
        <li v-if="this.admin_datos.user_type == 'jobbing_admin'" @click="abrirModalAgregarPago(selectedSolicitud)">Agregar Pago</li>
        <li v-if="selectedSolicitud && selectedSolicitud.orden" @click="abrirModalEnviarPresupuesto(selectedSolicitud)">Enviar presupuesto</li>
        <li v-if="selectedSolicitud && selectedSolicitud.orden" @click="abrirModalCrearFactura(selectedSolicitud)">Agregar Datos Facturación</li>
        <li style="color: #FF4136;" @click="marcarSolicitudInactiva(selectedSolicitud.id)">Eliminar solicitud</li>
        <!-- Add more options here -->
      </ul>
    </div>

    <!-- Image Preview Modal -->
    <ImagePreviewModal 
      :imageSrc="currentModalImage" 
      :visible="isImageModalVisible" 
      @close="closeImageModal" 
    />

    <!-- Modal for Adding Comment -->
    <div v-if="modalVisible" class="modal-overlay" @click.self="cerrarModal">
      <div class="modall">
        <h3>Agregar Comentario</h3>
        <textarea v-model="nuevoComentario" placeholder="Escribe tu comentario aquí"></textarea>
        <div class="modal-buttons">
          <button @click="agregarComentario">Agregar</button>
          <button @click="cerrarModal">Cancelar</button>
        </div>
      </div>
    </div>

    <!-- Modal for Editing Solicitud -->
    <div v-if="modalEditarSolicitudVisible" class="modal-overlay" @click.self="cerrarModalEditarSolicitud">
  <div class="modall">
    <h3>Editar Solicitud</h3>
    <table class="modal-inputs">
      <tr>
        <td><label>Título:</label></td>
        <td><input type="text" v-model="editedSolicitud.titulo" /></td>
      </tr>
      <!-- New Descripción Field -->
      <tr>
        <td><label>Descripción:</label></td>
        <td><textarea v-model="editedSolicitud.descripcion"></textarea></td>
      </tr>
      <tr>
        <td><label>Nombre:</label></td>
        <td><input type="text" v-model="editedSolicitud.nombre" /></td>
      </tr>
      <tr>
        <td><label>Apellido:</label></td>
        <td><input type="text" v-model="editedSolicitud.apellido" /></td>
      </tr>
      <tr>
        <td><label>Email:</label></td>
        <td><input type="email" v-model="editedSolicitud.email" /></td>
      </tr>
      <tr>
        <td><label>Teléfono:</label></td>
        <td><input type="text" v-model="editedSolicitud.telefono" /></td>
      </tr>
      <tr>
        <td><label>Dirección:</label></td>
        <td><input type="text" v-model="editedSolicitud.direccion" /></td>
      </tr>
      <!-- Change Comuna to a Select Dropdown -->
      <tr>
        <td><label>Comuna:</label></td>
        <td>
          <select v-model="editedSolicitud.comuna_id">
            <option v-for="comuna in comunas" :value="comuna.id">{{ comuna.Nombre }}</option>
          </select>
        </td>
      </tr>
      <!-- Add other fields as needed -->
    </table>
    <div class="modal-buttons">
      <button @click="guardarCambiosSolicitud">Guardar</button>
      <button @click="cerrarModalEditarSolicitud">Cancelar</button>
    </div>
  </div>
</div>

    <!-- Modal for Uploading File -->
    <div v-if="modalSubirArchivoVisible" class="modal-overlay" @click.self="cerrarModalSubirArchivo">
      <div class="modall">
        <h3>Agregar {{ archivoTipo }}</h3>
        <input type="file" @change="onFileSelected">
        <div class="modal-buttons">
          <button @click="subirArchivo">Subir</button>
          <button @click="cerrarModalSubirArchivo">Cancelar</button>
        </div>
      </div>
    </div>

    <!-- Modal for Adding Payment -->
    <div v-if="modalAgregarPagoVisible" class="modal-overlay" @click.self="cerrarModalAgregarPago">
      <div class="modall">
        <h3>Agregar Pago</h3>
        <table class="modal-inputs">
          <tr>
            <td><label>Monto:</label></td>
            <td><input type="number" v-model="nuevoPagoMonto" /></td>
          </tr>
          <tr>
            <td><label>Fecha de Pago:</label></td>
            <td><input type="date" v-model="nuevoPagoFecha" /></td>
          </tr>
          <tr>
            <td><label>Recibido por:</label></td>
            <td>
              <select v-model="nuevoPagoRecibidoPor">
                <option value="jobbing">Jobbing</option>
                <option value="administradora">{{admin_datos.empresa && admin_datos.empresa.nombre ? admin_datos.empresa.nombre : 'Administradora'}}</option>
              </select>
            </td>
          </tr>
          <tr>
            <td><label>Comprobante de Pago (opcional):</label></td>
            <td><input type="file" @change="onPagoFileSelected" /></td>
          </tr>
        </table>
        <div class="modal-buttons">
          <button @click="agregarPago">Agregar</button>
          <button @click="cerrarModalAgregarPago">Cancelar</button>
        </div>
      </div>
    </div>

      <!-- New Approval Modal -->
      <div
      v-if="modalAprobadoPresupuestoVisible"
      class="modal-overlay"
      @click.self="cerrarModalAprobadoPresupuesto"
    >
      <div class="modall">
        <h3>Aprobar Presupuesto</h3>
        <div v-if="selectedPresupuesto">

          <p><strong>Notas adicionales:</strong> {{ selectedPresupuesto.notas_adicionales && selectedPresupuesto.notas_adicionales != '' ? selectedPresupuesto.notas_adicionales : 'sin notas.' }}</p>
          <p v-if="selectedPresupuesto.tiempo_de_ejecucion"><strong>Tiempo de ejecucion:</strong> {{ selectedPresupuesto.tiempo_de_ejecucion }} dia(s)</p>
          
          <h3>Servicios:</h3>
          <ul style="list-style-type: none;">
            <li v-for="item in selectedPresupuesto.items" :key="item.id">
              <label>
                <input
                  type="checkbox"
                  v-model="item.selected"
                />
                {{ item.nombre }} - {{ item.cantidad }} x {{ item.precio }}
              </label>
            </li>
          </ul>

          <div>
            <label for="comment">Comentario:</label>
            <textarea
              id="comment"
              v-model="comment"
              placeholder="Agregar un comentario (opcional)"
            ></textarea>
          </div>

          <div class="modal-buttons">
            <button @click="aprobarPresupuesto" :disabled="loading_aprobacion">
              {{ loading_aprobacion ? 'Aprobando...' : 'Aprobar' }}
            </button>
            <button @click="cerrarModalAprobadoPresupuesto">Cancelar</button>
          </div>

          <div v-if="aprobarError" class="error-message">
            {{ aprobarError }}
          </div>
          <div v-if="aprobarSuccess" class="success-message">
            Presupuesto aprobado exitosamente.
          </div>
        </div>
        <div v-else>
          <p>No se ha seleccionado ningún presupuesto.</p>
        </div>
      </div>
    </div>

    <!-- Modal for Enviar Presupuesto -->
    <div v-if="modalEnviarPresupuestoVisible" class="modal-overlay" @click.self="cerrarModalEnviarPresupuesto">
      <div class="modall">
        <h3>Enviar Presupuesto</h3>
        <table class="modal-inputs">
          <tr>
            <td><label>Nombre:</label></td>
            <td><input type="text" v-model="presupuestoNombre" /></td>
          </tr>
          <tr>
            <td><label>Notas Adicionales:</label></td>
            <td><textarea v-model="presupuestoNotasAdicionales"></textarea></td>
          </tr>
          <tr>
            <td><label>Items:</label></td>
            <td>
              <div v-if="selectedSolicitud && selectedSolicitud.orden && selectedSolicitud.orden.servicios">
                <div v-for="servicio in selectedSolicitud.orden.servicios" :key="servicio.id">
                  <label>
                    <input type="checkbox" :value="servicio" v-model="presupuestoItems" :checked="true" />
                    {{ servicio.nombre }} - ${{ Intl.NumberFormat('de-DE').format(servicio.precio) }}
                  </label>
                </div>
              </div>
              <div v-else>
                No hay servicios disponibles.
              </div>
            </td>
          </tr>
        </table>
        <div class="modal-buttons">
          <button @click="vistaPreviaPresupuesto">Vista Previa</button>
          <button @click="enviarPresupuesto">Enviar</button>
          <button @click="cerrarModalEnviarPresupuesto">Cancelar</button>
        </div>
      </div>
    </div>

    <!-- Modal for rechazarPresupuesto -->
<div v-if="modalRechazarPresupuestoVisible" class="modal-overlay" @click.self="cerrarModalRechazarPresupuesto">
  <div class="modall">
    <h3>Rechazar Presupuesto</h3>
    <table class="modal-inputs">
      <tr>
        <td><label>Comentario:</label></td>
        <td>
          <textarea v-model="modalRechazarPresupuestoComentario"></textarea>
        </td>
      </tr>
    </table>
    <div class="modal-buttons">
      <button class="red-button" @click="rechazarPresupuesto(selectedPresupuesto)">Rechazar</button>
      <button @click="cerrarModalRechazarPresupuesto">Cancelar</button>
    </div>
  </div>
</div>

    <!-- Modal para Subir Datos de Facturación -->
<!-- Modal para Crear Factura -->
<div v-if="modalCrearFacturaVisible" class="modal-overlay" @click.self="cerrarModalCrearFactura">
  <div class="modall">
    <h3>Crear Factura</h3>
    <table class="modal-inputs">
      <tr>
        <td><label>Nombre/Razón Social:</label></td>
        <td><input type="text" v-model="facturaNombreCliente" /></td>
      </tr>
      <tr>
        <td><label>RUT:</label></td>
        <td><input type="text" v-model="facturaRutCliente" /></td>
      </tr>
      <tr>
        <td><label>Email (opcional):</label></td>
        <td><input type="email" v-model="facturaEmailCliente" /></td>
      </tr>
    </table>

    <div class="checkbox-opcional">
      <label>
        <input 
          type="checkbox" 
          :disabled="!facturaEmailCliente.trim()" 
          v-model="enviarPorCorreo" 
        />
        Enviar factura por correo al cliente
      </label>
    </div>

    <div class="presupuestos-aprobados" v-if="selectedSolicitud && selectedSolicitud.orden && selectedSolicitud.orden.presupuestos">
      <h4>Presupuestos Aprobados</h4>
      <div 
        v-for="presupuesto in selectedSolicitud.orden.presupuestos" 
        :key="presupuesto.id"
        v-if="presupuesto.estado === 2"
      >
      <input 
                type="checkbox" 
                :checked="allItemsSelected(presupuesto)" 
                @change="toggleSelectAllItems(presupuesto)" 
              /> 
        <h5 @click="togglePresupuesto(presupuesto)" style="cursor: pointer; user-select: none; display: inline-block; margin-left: 4px;">
          
          Presupuesto #{{ presupuesto.id }} 
          <span v-if="presupuesto.desgloce && presupuesto.desgloce.items">
            (${{Intl.NumberFormat('de-DE').format(presupuesto.desgloce.items.reduce((suma, item)=> suma + item.precio, 0))}})
          </span> 
          
          <span v-if="presupuesto.expanded">[-]</span>
          <span v-else>[+]</span>
        </h5>
        
        <!-- Solo mostrar contenido si el presupuesto esta expandido -->
        <div v-if="presupuesto.expanded">

          <!-- Checkbox para seleccionar todos los ítems de este presupuesto -->
          <div>

          </div>

          <!-- Listado de ítems del presupuesto -->
          <div v-if="presupuesto.desgloce && presupuesto.desgloce.items && presupuesto.desgloce.items.length">
            <div v-for="item in presupuesto.desgloce.items" :key="item.id" style="margin-left:20px;">
              <label>
                <input 
                  type="checkbox" 
                  :value="item.id" 
                  v-model="facturaItems"
                />
                {{ item.nombre }} - ${{ Intl.NumberFormat('de-DE').format(item.precio) }}
              </label>
            </div>
          </div>
          <div v-else>
            <p>No hay ítems disponibles en este presupuesto.</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>No hay presupuestos disponibles.</p>
    </div>

    <div class="modal-buttons">
      <button @click="subirFactura">Generar Factura</button>
      <button @click="cerrarModalCrearFactura">Cancelar</button>
    </div>
  </div>
</div>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import DownloadFotoOrden from '@/components/DownloadFotoOrden.vue';
import ImagePreviewModal from '@/components/ImagePreviewModal.vue';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'SolicitudesCliente',
  components: {
    SideNav,
    TopNav,
    ImagePreviewModal,
    DownloadFotoOrden,
  },
  data() {
    return {

      presupuesto_activeTooltip: null, // holds the presupuesto for the tooltip
      presupuesto_tooltipX: 0,
      presupuesto_tooltipY: 0,

      currentTab: 'active',
      solicitudes: [],
      loaded: false,
      searchQuery: '', // Added searchQuery data property
      contextMenuVisible: false,
      contextMenuX: 0,
      contextMenuY: 0,
      selectedSolicitud: null,
      modalVisible: false,
      nuevoComentario: '',
      modalSubirArchivoVisible: false,
      modalRechazarPresupuestoVisible:false,
      modalRechazarPresupuestoComentario:'',
      archivoTipo: '',
      archivoSeleccionado: null,
      modalAgregarPagoVisible: false,
      nuevoPagoMonto: null,
      nuevoPagoFecha: null,
      nuevoPagoRecibidoPor: 'jobbing',
      pagoArchivoSeleccionado: null,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
      preventClick: false,
      modalEnviarPresupuestoVisible: false,
      presupuestoNombre: '',
      presupuestoNotasAdicionales: '',
      presupuestoItems: [],
      modalEditarSolicitudVisible: false,
      editedSolicitud: {},
      dateFilter: 'last_2_months', // Nuevo: Filtro de fecha por defecto "Hace dos meses"
      isImageModalVisible: false,    // Clearly named state for ImagePreviewModal
      currentModalImage: '',          // Holds the URL of the image to display

      // New Approval Modal Data
      modalAprobadoPresupuestoVisible: false,
      selectedPresupuesto: null,
      comment: '',
      loading_aprobacion: false,
      aprobarError: '',
      aprobarSuccess: false,


      //Modal datos de facturación
      modalCrearFacturaVisible: false,
      facturaNombreCliente: '',
      facturaRutCliente: '',
      facturaEmailCliente: '',
      enviarPorCorreo: false,
      facturaItems: [],
    };
  },
  computed: {
    ...mapGetters(['admin_datos', 'comunas']),

    
  
    filteredSolicitudes() {

      var ret_;
      if (this.currentTab === 'active') {
        ret_ = this.solicitudes.filter(solicitud => solicitud.estado_front.estado !== 'Terminada' && solicitud.estado != 10);
      } else if (this.currentTab === 'inactive') {
        ret_ = this.solicitudes.filter(solicitud => solicitud.estado_front.estado === 'Terminada' && solicitud.estado != 10);
      }
      else if (this.currentTab === 'eliminadas') {
        ret_ = this.solicitudes.filter(solicitud => solicitud.estado == 10);
      }
      else if (this.currentTab === 'accion requerida') {
        ret_ = this.solicitudes.filter(solicitud => (this.solicitudRequiereAccion(solicitud, "Aprobar presupuesto") || this.solicitudRequiereAccion(solicitud, "Pagar")) && solicitud.estado != 10);
      }
      if (!this.searchQuery) {
        return ret_;
      }
      const query = this.searchQuery.toLowerCase();
      return ret_.filter(solicitud => {
        const comuna = solicitud.comuna_nombre ? solicitud.comuna_nombre.toLowerCase() : '';
        const titulo = solicitud.titulo ? solicitud.titulo.toLowerCase() : '';
        const estado = solicitud.estado_front && solicitud.estado_front.estado ? solicitud.estado_front.estado.toLowerCase() : '';
        const direccion = solicitud.direccion ? solicitud.direccion.toLowerCase() : '';
        const descripcion = solicitud.descripcion ? solicitud.descripcion.toLowerCase() : '';
        const empresa = solicitud.empresa && solicitud.empresa.nombre ? solicitud.empresa.nombre.toLowerCase() : '';
        return (
          comuna.includes(query) ||
          titulo.includes(query) ||
          estado.includes(query) ||
          direccion.includes(query) ||
          empresa.includes(query) ||
          descripcion.includes(query)
        );
      });
    },
  
  },
  methods: {
    presupuesto_showTooltip(event, presupuesto) {
      // Capture the hovered presupuesto and set tooltip position with an offset

      const scaleFactor = 0.75;

      this.presupuesto_activeTooltip = presupuesto;
      this.presupuesto_tooltipX = (event.clientX + 10)/scaleFactor;
      this.presupuesto_tooltipY = (event.clientY + 10)/scaleFactor;
    },
    presupuesto_hideTooltip() {
      this.presupuesto_activeTooltip = null;
    },
    formatPrice(value) {
      return Intl.NumberFormat('de-DE').format(value);
    },
    calculateTotal() {
      let total = 0;
      if (this.selectedSolicitud && this.selectedSolicitud.orden && this.selectedSolicitud.orden.presupuestos) {
        this.selectedSolicitud.orden.presupuestos.forEach(presupuesto => {
          if (presupuesto.desgloce && presupuesto.desgloce.items) {
            presupuesto.desgloce.items.forEach(item => {
              if (this.facturaItems.includes(item.id)) {
                total += item.precio;
              }
            });
          }
        });
      }
      return total;
    },
    async subirFactura() {
      // Validate inputs
      if (!this.facturaNombreCliente.trim() || !this.facturaRutCliente.trim()) {
        alert('Nombre/Razón Social y RUT son obligatorios.');
        return;
      }

      if (!this.facturaItems.length) {
        alert('Debe seleccionar al menos un ítem para generar la factura.');
        return;
      }

      const total = this.calculateTotal();

      const payload = {
        item_ids: this.facturaItems,
        nombre_razon_social: this.facturaNombreCliente.trim(),
        rut: this.facturaRutCliente.trim(),
        email: this.facturaEmailCliente.trim(),
        enviar_por_email: this.enviarPorCorreo,
        total: total
      };

      try {
        const response = await axios.post(window.hostname + 'api/property_manager/crear_factura', payload);
        if (response.status === 200) {
          alert('Factura creada exitosamente. ID: ' + response.data.factura_id);
          this.cerrarModalCrearFactura();
          // Clear the form and selections
          this.facturaNombreCliente = '';
          this.facturaRutCliente = '';
          this.facturaEmailCliente = '';
          this.enviarPorCorreo = false;
          this.facturaItems = [];
        } else {
          alert('Error al crear la factura. Código de estado: ' + response.status);
        }
      } catch (error) {
        console.error(error);
        alert('Ocurrió un error al crear la factura.');
      }
      this.fetchSolicitudes(false);
    },

   errorMsg(msg){
    this.$notify({
          group: 'foo',
          title: '¡Error!',
          text: msg,
          type:'error'
          });  
   },
   solicitudRequiereAccion(solicitud, accion){

      if(accion == "Aprobar presupuesto"){
        return solicitud.estado_front && solicitud.estado_front.estado == "Pendiente aprobar presupuesto"
      }
      if(accion == "Pagar"){

        return solicitud.estado_front && solicitud.estado_front.estado == "Pendiente por cobrar"
      }
    
      return false
    },
    abrirModalCrearFactura(solicitud_) {
      if(solicitud_ && solicitud_.orden && solicitud_.orden.presupuestos && solicitud_.orden.presupuestos.some((pres_) => pres_.estado == 2))
      {
        this.modalCrearFacturaVisible = true;
        this.contextMenuVisible = false;
        // Limpiar campos al abrir el modal
        this.facturaNombreCliente = '';
        this.facturaRutCliente = '';
        this.facturaEmailCliente = '';
        this.enviarPorCorreo = false;
      }
      else{
        this.errorMsg('No hay presupuestos aprobados en esta solicitud')
      }
    },

    cerrarModalCrearFactura() {
      this.modalCrearFacturaVisible = false;
      this.facturaNombreCliente = '';
      this.facturaRutCliente = '';
      this.facturaEmailCliente = '';
      this.enviarPorCorreo = false;
    },
    togglePresupuesto(presupuesto) {
    presupuesto.expanded = !presupuesto.expanded;
    this.$forceUpdate();

  },
  allItemsSelected(presupuesto) {
    if (!presupuesto.desgloce || !presupuesto.desgloce.items || presupuesto.desgloce.items.length === 0) return false;
    const itemIds = presupuesto.desgloce.items.map(item => item.id);
    return itemIds.every(id => this.facturaItems.includes(id));
  },

  toggleSelectAllItems(presupuesto) {
    if (!presupuesto.desgloce || !presupuesto.desgloce.items || presupuesto.desgloce.items.length === 0) return;

    const itemIds = presupuesto.desgloce.items.map(item => item.id);
    const todosSeleccionados = itemIds.every(id => this.facturaItems.includes(id));

    if (todosSeleccionados) {
      // Si todos estaban seleccionados, removemos sus IDs
      this.facturaItems = this.facturaItems.filter(id => !itemIds.includes(id));
    } else {
      // Si no todos están seleccionados, agregamos los faltantes
      itemIds.forEach(id => {
        if (!this.facturaItems.includes(id)) {
          this.facturaItems.push(id);
        }
      });
    }
  },
  toggleCollapsePresupuesto(presupuestoId) {
    this.$set(this.collapsedPresupuestos, presupuestoId, !this.collapsedPresupuestos[presupuestoId]);
  },

    toggleMostrarTodos(solicitud) {
      if(!solicitud.mostrar_todos_los_comentarios)
        solicitud.mostrar_todos_los_comentarios = true;
      else
        solicitud.mostrar_todos_los_comentarios = false;
      this.$forceUpdate();
    },
    getPrioridad(solicitud) {
      const prioridades = {
        'alta': { 'color': '#FF4136', 'nombre': 'Alta' },
        'media': { 'color': '#FFDC00', 'nombre': 'Media' },
        'baja': { 'color': '#7FDBFF', 'nombre': 'Baja' },
        'desconocida': { 'color': '#AAAAAA', 'nombre': 'Desconocida' },
      };

      // Set default prioridad to 'desconocida'
      let prioridad = 'desconocida';

      if (solicitud.atributos && solicitud.atributos.prioridad) { 
        prioridad = solicitud.atributos.prioridad;
        if (!(prioridad in prioridades)) {
          prioridad = 'desconocida';
        }
      }

      return `<div style="color: ${prioridades[prioridad].color};"> ${prioridades[prioridad].nombre} </div>`;
    },
    getDetallePresupuesto(presupuesto){
      let detalle_ = 'Detalle del presupuesto: \n';
      if(presupuesto.desgloce && presupuesto.desgloce.items){
        for(var item of presupuesto.desgloce.items)
        {
          detalle_ += item.nombre+': $'+Intl.NumberFormat('de-DE').format(item.precio)+'\n'
        }
      }
      return detalle_;
    },
    abirModalAprobadoPresupuesto(presupuesto) {
      this.selectedPresupuesto = { ...presupuesto };
      // Initialize all items as selected
      this.selectedPresupuesto.items = this.selectedPresupuesto.desgloce.items.map(item => ({
        ...item,
        selected: true,
      }));
      this.comment = '';
      this.aprobarError = '';
      this.aprobarSuccess = false;
      this.modalAprobadoPresupuestoVisible = true;
    },
    cerrarModalAprobadoPresupuesto() {
      this.modalAprobadoPresupuestoVisible = false;
      this.selectedPresupuesto = null;
      this.comment = '';
      this.aprobarError = '';
      this.aprobarSuccess = false;
    },
    async aprobarPresupuesto() {
      // Validate if a presupuesto is selected
      if (!this.selectedPresupuesto) {
        this.aprobarError = 'No hay un presupuesto seleccionado.';
        return;
      }

      // Extract selected item IDs
      const selectedItems = this.selectedPresupuesto.items
        .filter(item => item.selected)
        .map(item => item.id);

      // Validate if at least one item is selected
      if (selectedItems.length === 0) {
        this.aprobarError = 'Debe seleccionar al menos un ítem para aprobar.';
        return;
      }

      // Initialize loading and reset error/success messages
      this.loading_aprobacion = true;
      this.aprobarError = '';
      this.aprobarSuccess = false;

      try {
        // Make the POST request to approve the presupuesto
        const response = await axios.post(`${window.hostname}api/property_manager/aprobar_presupuesto`, {
          presupuesto_id: this.selectedPresupuesto.id,
          selected_items: selectedItems,
          comment: this.comment,
        });

        // Handle successful response
        this.aprobarSuccess = true;
        
        // Refresh the solicitudes without triggering loading state
        await this.fetchSolicitudes(false);
        
        // Force Vue to re-render the component to reflect changes
        this.$forceUpdate();

        this.cerrarModalAprobadoPresupuesto()
        this.$notify({
            group: 'foo',
            title: '¡Éxito!',
            text: '¡Presupueso aprobado existosamente!',
            type:'success'
          });
        
      } catch (error) {
        // Handle error response
        if (error.response && error.response.data && error.response.data.error) {
          this.aprobarError = error.response.data.error;
        } else {
          this.aprobarError = 'Ocurrió un error al aprobar el presupuesto.';
        }
      } finally {
        // Reset the loading state
        this.loading_aprobacion = false;
      }
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return this.extractDate(dateStr).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' });
    },
    openImageModal(imageSrc) {
      this.currentModalImage = imageSrc;
      this.isImageModalVisible = true;
    },
    closeImageModal() {
      this.isImageModalVisible = false;
      this.currentModalImage = '';
    },
      abrirModalEditarSolicitud(solicitud) {
      this.contextMenuVisible = false;
      this.modalEditarSolicitudVisible = true;
      // Create a deep copy to avoid mutating the original object before saving
      this.editedSolicitud = JSON.parse(JSON.stringify(solicitud));
      // Ensure comuna_id is set
      this.editedSolicitud.comuna_id = solicitud.comuna_id;
    },

    cerrarModalEditarSolicitud() {
      this.modalEditarSolicitudVisible = false;
      this.editedSolicitud = {};
    },

    getEstadoPresupuesto(presupuesto) {
      if (!presupuesto || presupuesto.estado == null) {
        return '<span style="color:#FF4136;">Desconocido</span>';
      }

      if (presupuesto.estado === 0) {
        return '<span style="color:#AAAAAA;">Pendiente</span>';
      }else if (presupuesto.estado === 1) {
        return '<span style="color:#FFDC00;">Enviado</span>';
      } else if (presupuesto.estado > 1 && presupuesto.estado < 10) {
        return '<span style="color:#2ECC40;">Aprobado</span>';
      } else if (presupuesto.estado >= 10) {
        return '<span style="color:#FF4136;">Rechazado</span>';
      }

      // Fallback if no condition matches
      return '<span style="color:#FF4136;">Desconocido</span>';
    },

    guardarCambiosSolicitud() {
    // Validate inputs if necessary
      axios
        .post(window.hostname + 'api/property_manager/editar_solicitud', {
          ...this.editedSolicitud,
          comuna_id: this.editedSolicitud.comuna_id, // Ensure comuna_id is sent
        })
        .then(() => {
          alert('Solicitud actualizada exitosamente.');
          this.cerrarModalEditarSolicitud();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          console.log('Error al actualizar solicitud:', e);
          alert('Hubo un error al actualizar la solicitud.');
        });
    },

    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    getMontoPorCobrarAJobbing(solicitud){
      if (!solicitud || !solicitud.orden || !solicitud.orden.pagos || !this.admin_datos || !this.admin_datos.empresa) {
      return 0; // Return 0 if any required data is missing
    }

    // Get the margen_captacion value
    const margenCaptacion = this.admin_datos.empresa.margen_captacion;

    // Calculate the total sum
    const total = solicitud.orden.pagos
      .filter(pago => pago.estado === 1 && pago.recibido_por == 'jobbing' && pago.transferencia_empresa_administracion_id === null)
      .reduce((sum, pago) => sum + (pago.monto * margenCaptacion), 0);

    return total;
    },
    onPagoFileSelected(event) {
      this.pagoArchivoSeleccionado = event.target.files[0];
    },
    hasMargenCaptacion() {
      return this.admin_datos.empresa && this.admin_datos.empresa.margen_captacion != 0
    },
    extractDate(isoString) {
      try {
        const datePart = isoString.split("T")[0];
        const [year, month, day] = datePart.split("-").map(Number);
        return new Date(year, month - 1, day); // Ensure local timezone isn't applied
      } catch (e) {
        return null;
      }
    },
    getFechaCalificacion(solicitud){
      if(solicitud.orden && solicitud.orden.calificacion && solicitud.orden.calificacion.fecha_creado){
        const fecha_creado = solicitud.orden.calificacion.fecha_creado
        return new Date(fecha_creado.a, fecha_creado.m, fecha_creado.d).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' })
      }
      return 'Sin caclificación'
    }, 
    getEstadoVisita(visita) {
      const estados = {
        0: 'Pendiente',
        1: 'Agendada',
        2: 'Confirmada',
        3: 'En proceso',
        4: 'Terminada',
        10: 'Anulada por retraso'
      }
      return estados[visita.estado];
    },
    async fetchSolicitudes(load = true) {
  if (load) {
    this.loaded = false;
  }

  const params = {};
  const today = new Date();
  let startDate = null;

  // Determine the startDate based on the dateFilter
  switch (this.dateFilter) {
    case 'today':
      startDate = new Date();
      break;
    case 'last_7_days':
      startDate = new Date();
      startDate.setDate(today.getDate() - 7);
      break;
    case 'last_30_days':
      startDate = new Date();
      startDate.setDate(today.getDate() - 30);
      break;
    case 'last_2_months':
      startDate = new Date();
      startDate.setMonth(today.getMonth() - 2);
      break;
    case 'last_6_months':
      startDate = new Date();
      startDate.setMonth(today.getMonth() - 6);
      break;
    case 'last_12_months':
      startDate = new Date();
      startDate.setMonth(today.getMonth() - 12);
      break;
    case 'since_beginning':
      // No startDate set to fetch all solicitudes from the beginning
      break;
    case 'all':
    default:
      // No startDate set to fetch all solicitudes up to the current date
      break;
  }


    if (startDate && this.dateFilter !== 'since_beginning') {
      params.start_date = startDate.toISOString().split('T')[0];
    }
    params.end_date = today.toISOString().split('T')[0];

    try {
      const response = await axios.get(`${window.hostname}api/property_manager/get_solicitudes`, { params });

      // Sort by latest_activity, newest first
      this.solicitudes = response.data.solicitudes.sort((a, b) => {
        const dateA = a.latest_activity ? new Date(a.latest_activity) : new Date(0);
        const dateB = b.latest_activity ? new Date(b.latest_activity) : new Date(0);
        return dateB - dateA; // descending order
      });
    } catch (error) {
      console.error('Error al cargar solicitudes:', error);
    } finally {
      this.loaded = true;
    }
  },

    toggleDetails(id, event) {
      if (this.preventClick) {
        this.preventClick = false;
        return;
      }

      const ignoredClasses = ['download-button'];
      if (ignoredClasses.some(className => event.target.classList.contains(className))) {
        return;
      }

      const detailsRow = document.getElementById(id);
      if (detailsRow.style.display === 'table-row') {
        detailsRow.style.display = 'none';
      } else {
        detailsRow.style.display = 'table-row';
      }
    },
    obtenerURLFoto(foto) {
      return foto['link'];
    },
    getAtendidoPor(solicitud) {
      if (solicitud.orden && solicitud.orden.profesional) {
        return solicitud.orden.profesional.nombre+ ' '+solicitud.orden.profesional.apellido
      }
      return 'Aun no asignada';
    },
    getFechaVisita(solicitud) {
      if (solicitud.orden && solicitud.orden.visitas && solicitud.orden.visitas.filter(visita => ![10, 0].includes(visita.estado)).length > 0) {
        return this.extractDate(solicitud.orden.visitas[0].fecha_).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' });
      }
      return 'No hay visita';
    },
    getFechaEnvioPresupuesto(solicitud) {
      if (solicitud.orden && solicitud.orden.presupuestos && solicitud.orden.presupuestos.length > 0) {
        return this.extractDate(solicitud.orden.presupuestos[0].fecha_creado_iso).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' });
      }
      return 'No se ha enviado';
    },
    getMontoTotal(solicitud) {
      if (solicitud.orden && solicitud.orden.presupuestos && solicitud.orden.presupuestos.length > 0) {
        // Find the latest presupuesto based on fecha_enviado_iso
        const latestPresupuesto = solicitud.orden.presupuestos.reduce((latest, current) => {
          return (!latest || new Date(current.fecha_enviado_iso) > new Date(latest.fecha_enviado_iso)) 
            ? current 
            : latest;
        }, null);

        // If there's a latest presupuesto, sum up the "precio" from its desgloce.items
        if (latestPresupuesto && latestPresupuesto.desgloce && latestPresupuesto.desgloce.items) {
          const total = latestPresupuesto.desgloce.items.reduce((sum, item) => {
            return sum + (item.precio || 0);
          }, 0);

          return '$' + Intl.NumberFormat('de-DE').format(total);
        }
      }
      
      return 'No hay presupuesto subidos';
    },
    getMontoPagado(solicitud) {
      if (solicitud.orden && solicitud.orden.pagos && solicitud.orden.pagos.length > 0) {
        const totalPagado = solicitud.orden.pagos
          .filter(item => item.estado === 1) // Filter pagos with estado == 1
          .reduce((total, item) => total + (item.monto || 0), 0);
          
        return totalPagado > 0 ? '$' + Intl.NumberFormat('de-DE').format(totalPagado) : 'No hay pagos hechos';
      }
      return 'No hay pagos hechos';
    },
    getMontoPagadoNumero(solicitud) {
      if (solicitud.orden && solicitud.orden.pagos && solicitud.orden.pagos.length > 0) {
        const totalPagado = solicitud.orden.pagos
          .filter(item => item.estado === 1) // Filter pagos with estado == 1
          .reduce((total, item) => total + (item.monto || 0), 0);
          
        return totalPagado > 0 ? totalPagado : 0;
      }
      return 0;
    },


    getTotalApprovedPresupuesto(presupuesto) {
      // Define the approved estado range
      const ESTADO_MIN = 2; // estado > 1
      const ESTADO_MAX = 9; // estado < 10

      // Check if the presupuesto is in an approved estado
      if (!presupuesto || typeof presupuesto !== 'object') {
        console.warn('Invalid presupuesto object provided.');
        return '0';
      }

      if (!(presupuesto.estado > ESTADO_MIN - 1 && presupuesto.estado < ESTADO_MAX + 1)) {
        // Estado not in approved range
        return '0';
      }

      // Ensure desgloce and items exist
      if (
        !presupuesto.desgloce ||
        !Array.isArray(presupuesto.desgloce.items) ||
        presupuesto.desgloce.items.length === 0
      ) {
        console.warn('Presupuesto has no items to sum.');
        return '0';
      }

      // Sum the precio of approved items
      const total = presupuesto.desgloce.items.reduce((sum, item) => {
        if (item.aprobado === true || item.aprobado === undefined) {
          // Ensure precio is a number
          const precio = typeof item.precio === 'number' ? item.precio : parseFloat(item.precio) || 0;
          return sum + precio;
        }
        return sum;
      }, 0);

      // Format the total amount (e.g., "1.234,56")
      return total;
    },

    getTotalNoRealizadoPresupuesto(presupuesto) {
      // Define the approved estado range
      const ESTADO_MIN = 2; // estado > 1
      const ESTADO_MAX = 9; // estado < 10

      // Check if the presupuesto is in an approved estado
      if (!presupuesto || typeof presupuesto !== 'object') {
        console.warn('Invalid presupuesto object provided.');
        return '0';
      }

      if (!(presupuesto.estado > ESTADO_MIN - 1 && presupuesto.estado < ESTADO_MAX + 1)) {
        // Estado not in approved range
        return '0';
      }

      // Ensure desgloce and items exist
      if (
        !presupuesto.desgloce ||
        !Array.isArray(presupuesto.desgloce.items) ||
        presupuesto.desgloce.items.length === 0
      ) {
        console.warn('Presupuesto has no items to sum.');
        return '0';
      }

      // Sum the precio of approved items
      const total = presupuesto.desgloce.items.reduce((sum, item) => {
        if ((item.aprobado === true || item.aprobado === undefined) && item.no_realizado) {
          // Ensure precio is a number
          const precio = typeof item.precio === 'number' ? item.precio : parseFloat(item.precio) || 0;
          return sum + precio;
        }
        return sum;
      }, 0);

      // Format the total amount (e.g., "1.234,56")
      return total;
    },

    getMontoPorPagar(solicitud){
      let totalOrden = 0;
      const presupuestosAprobados = solicitud.orden.presupuestos.filter(
          p => p.estado > 1 && p.estado < 10
        );

        if (presupuestosAprobados.length === 0) {
          return 0;
        }

        // Iterate through each approved presupuesto and sum their items using the new method
        presupuestosAprobados.forEach(presupuesto => {
          const sumaPresupuesto = this.getTotalApprovedPresupuesto(presupuesto);
          const sumaPresupuestoNoRealizado = this.getTotalNoRealizadoPresupuesto(presupuesto);
          totalOrden += isNaN(sumaPresupuesto) ? 0 : sumaPresupuesto;
          totalOrden -= isNaN(sumaPresupuestoNoRealizado) ? 0 : sumaPresupuestoNoRealizado;
        });
        let totalPagado = 0;

        // Sum all "pagos" in the orden
        if (solicitud.orden && solicitud.orden.pagos && solicitud.orden.pagos.length > 0) {
          totalPagado = solicitud.orden.pagos.reduce((total, item) => {
            return total + (item.monto || 0);
          }, 0);
        }

        // Calculate remaining amount to pay
        const montoPorPagar = totalOrden - totalPagado;
        return montoPorPagar;
    },

    getMontoPorPagarString(solicitud) {
      let totalOrden = 0;

      // Ensure that there are 'presupuestos' in the 'orden'
      if (solicitud.orden && solicitud.orden.presupuestos && solicitud.orden.presupuestos.length > 0) {
        // Filter all approved presupuestos (estado > 1 && estado < 10)
        const presupuestosAprobados = solicitud.orden.presupuestos.filter(
          p => p.estado > 1 && p.estado < 10
        );

        if (presupuestosAprobados.length === 0) {
          return 'No hay presupuestos aprobados aún.';
        }

        // Iterate through each approved presupuesto and sum their items using the new method
        presupuestosAprobados.forEach(presupuesto => {
          const sumaPresupuesto = this.getTotalApprovedPresupuesto(presupuesto);
          const sumaPresupuestoNoRealizado = this.getTotalNoRealizadoPresupuesto(presupuesto);
          totalOrden += isNaN(sumaPresupuesto) ? 0 : sumaPresupuesto;
          totalOrden -= isNaN(sumaPresupuestoNoRealizado) ? 0 : sumaPresupuestoNoRealizado;
        });
      } else {
        return 'No hay presupuestos enviados aún.';
      }

      let totalPagado = 0;

      // Sum all "pagos" in the orden
      if (solicitud.orden && solicitud.orden.pagos && solicitud.orden.pagos.length > 0) {
        totalPagado = solicitud.orden.pagos.reduce((total, item) => {
          return total + (item.monto || 0);
        }, 0);
      }

      // Calculate remaining amount to pay
      const montoPorPagar = totalOrden - totalPagado;

      // Format the amount in German locale (e.g., "1.234,56")
      return '$' + Intl.NumberFormat('de-DE').format(montoPorPagar);
    },
    getFechaAprobacion(solicitud) {
      if (solicitud.orden && solicitud.orden.presupuestos && solicitud.orden.presupuestos.length > 0) {
        // Sort presupuestos by fecha_aprobado_iso in descending order (latest first)
        const sortedPresupuestos = solicitud.orden.presupuestos
          .filter(p => p.fecha_aprobado_iso) // Filter out presupuestos without fecha_aprobado_iso
          .sort((a, b) => new Date(b.fecha_aprobado_iso) - new Date(a.fecha_aprobado_iso));

        const presupuesto = sortedPresupuestos.length > 0 ? sortedPresupuestos[0] : null;

        if (presupuesto) {
          return '<span style="color:#2ECC40;">' +
            this.extractDate(presupuesto.fecha_aprobado_iso).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' }) +
            '</span>';
        }
        return '<span style="color:#FFDC00;">No aprobado</span>';
      }
      return '<span style="color:#FF4136;">No enviado</span>';
    },
    getFechaInicio(solicitud) {
      if (solicitud.orden && solicitud.orden.presupuestos && solicitud.orden.presupuestos.find(presupuesto => presupuesto.estado === 2)) {
        var fecha_aprobado = this.extractDate(solicitud.orden.presupuestos.find(presupuesto => presupuesto.estado === 2).fecha_aprobado_iso)
        fecha_aprobado.setDate(fecha_aprobado.getDate() + 1);
        return fecha_aprobado.toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' });
      }
      return '<span style="white-space:nowrap;">No hay fecha</span> de inicio';
    },

    formatFecha(fecha) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return new Date(fecha).toLocaleDateString('es-ES', options);
    },
    hasFotos(solicitud) {
      return solicitud.orden && solicitud.orden.fotos && solicitud.orden.fotos.length > 0;
    },
    getFotosAntes(solicitud) {
      if (this.hasFotos(solicitud)) {
        return solicitud.orden.fotos.filter((foto) => foto.tipo === 1);
      }
      return [];
    },
    getFotosDespues(solicitud) {
      if (this.hasFotos(solicitud)) {
        return solicitud.orden.fotos.filter((foto) => foto.tipo === 2);
      }
      return [];
    },
    getComprobantePago(solicitud) {
      if (
        solicitud.orden &&
        solicitud.orden.archivos &&
        solicitud.orden.archivos.some((archivo) => archivo.categoria === 'Comprobante de Pago')
      ) {
        return 'Descargar';
      }
      return 'No tiene';
    },
    getFacturaHaciaJobbing(solicitud) {
      if (
        solicitud.orden &&
        solicitud.orden.archivos &&
        solicitud.orden.archivos.some((archivo) => archivo.categoria === 'Factura hacia Jobbing')
      ) {
        return 'Descargar';
      }
      return 'No tiene';
    },
    getFacturaCliente(solicitud) {
      if (
        solicitud.orden &&
        solicitud.orden.archivos &&
        solicitud.orden.archivos.some((archivo) => archivo.categoria === 'Factura al Cliente')
      ) {
        return 'Descargar';
      }
      return 'No tiene';
    },
    getVideo(solicitud) {
      if (
        solicitud.orden &&
        solicitud.orden.archivos &&
        solicitud.orden.archivos.some((archivo) => archivo.categoria === 'Video')
      ) {
        return 'Descargar';
      }
      return 'No tiene';
    },
    descargarArchivo(solicitud, categoria) {
      if(solicitud.orden && solicitud.orden.archivos) 
      {     
        const archivo = solicitud.orden.archivos.find(
          (archivo) => archivo.categoria === categoria
        );
        if (archivo) {
          const url = `${window.hostname}api/property_manager/descargar_archivo/${archivo.id}`;
          window.open(url, '_blank');
        }
      }
      },
      descargarArchivoPorID(archivo) {
        if (archivo) {
          const url = `${window.hostname}api/property_manager/descargar_archivo/${archivo.id}`;
          axios.get(url)
        .then(response => {
          const presignedUrl = response.data.url;
          window.open(presignedUrl, '_blank');
        })
        .catch(error => {
          console.error('Error al obtener la URL de descarga:', error);
          alert('No se pudo descargar el archivo. Por favor, inténtalo de nuevo.');
        });
        }
      
    },
    handleClickArchivo(event, archivo) {
    if (event.ctrlKey && this.admin_datos.user_type === 'jobbing_admin') {
      if (confirm(`¿Seguro que quieres eliminar el archivo ${archivo.id}?`)) {
        this.eliminarArchivo(archivo.id);
      }
    } else {
      this.descargarArchivoPorID(archivo);
    }
  },
  async eliminarArchivo(id) {
      try {
        await axios.delete(`${window.hostname}api/property_manager/eliminar_archivo/${id}`);
        
        // Refresh the solicitudes without triggering the loading state
        await this.fetchSolicitudes(false);
        // Force Vue to re-render the component to reflect changes
        this.$forceUpdate();
        
        // Schedule the alert after the refresh
        setTimeout(() => {
          alert(`Archivo ${id} eliminado exitosamente.`);
        }, 0);
      } catch (error) {
        console.error('Error al eliminar el archivo:', error);
        
        // Refresh UI even on error
        await this.fetchSolicitudes(false);
        this.$forceUpdate();
        
        setTimeout(() => {
          alert('No se pudo eliminar el archivo.');
        }, 0);
      }
    },
    // New Methods for Context Menu and Modal
    openContextMenu(event, solicitud) {
    const scaleFactor = 0.75; // Replace with your zoom/scale level
    this.contextMenuVisible = true;

    // Adjust coordinates based on the scale factor
    this.contextMenuX = event.clientX / scaleFactor;
    this.contextMenuY = event.clientY / scaleFactor;

    this.selectedSolicitud = solicitud;
  },
    abrirModalAgregarComentario(solicitud) {
      this.contextMenuVisible = false;
      this.modalVisible = true;
      this.nuevoComentario = '';
    },
    cerrarModal() {
      this.modalVisible = false;
    },
    agregarComentario() {
      if (this.nuevoComentario.trim() === '') {
        alert('El comentario no puede estar vacío.');
        return;
      }
      // Assuming there's an API endpoint to add a comment to a solicitud
      axios
        .post(window.hostname + 'api/property_manager/agregar_comentario', {
          solicitud_id: this.selectedSolicitud.id,
          comentario: this.nuevoComentario,
        })
        .then(() => {
          alert('Comentario agregado exitosamente.');
          this.cerrarModal();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          console.log('Error al agregar comentario:', e);
          alert('Hubo un error al agregar el comentario.');
        });
    },
    abrirModalSubirArchivo(solicitud, tipo) {
      this.contextMenuVisible = false;
      this.selectedSolicitud = solicitud;
      this.archivoTipo = tipo;
      this.archivoSeleccionado = null;
      this.modalSubirArchivoVisible = true;
    },
    cerrarModalSubirArchivo() {
      this.modalSubirArchivoVisible = false;
      this.archivoSeleccionado = null;
    },
    onFileSelected(event) {
      this.archivoSeleccionado = event.target.files[0];
    },
    subirArchivo() {
      if (!this.archivoSeleccionado) {
        alert('Por favor, selecciona un archivo.');
        return;
      }

      const formData = new FormData();

      // Create the JSON document
      const jsonDocument = {
        nombre: this.archivoSeleccionado.name,
        solicitud_id: this.selectedSolicitud.id,
        categoria: this.archivoTipo,
      };

      // Convert JSON document to a Blob
      const jsonBlob = new Blob([JSON.stringify(jsonDocument)], { type: 'application/json' });

      // Append 'document' and 'file' to FormData
      formData.append('document', jsonBlob, 'document.json');
      formData.append('file', this.archivoSeleccionado);

      axios
        .post(window.hostname + 'api/property_manager/subir_archivo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          alert('Archivo subido exitosamente.');
          this.cerrarModalSubirArchivo();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          console.log('Error al subir archivo:', e);
          alert('Hubo un error al subir el archivo.');
        });
    },
        abrirModalAgregarPago(solicitud) {
          this.contextMenuVisible = false;
          this.selectedSolicitud = solicitud;
          this.modalAgregarPagoVisible = true;
          this.nuevoPagoMonto = null;
          this.nuevoPagoFecha = null;
          this.nuevoPagoDescripcion = '';
        },


      async marcarSolicitudInactiva(solicitudId) {
      // Solicitar confirmación antes de continuar
      if (!confirm("¿Estás seguro de que deseas marcar esta solicitud como inactiva?")) {
        return; // Salir si el usuario cancela
      }

      try {
        // Enviar la solicitud POST con solicitud_id en el cuerpo de la solicitud
        const response = await axios.post(window.hostname + '/api/property_manager/marcar_solicitud_inactiva', {
          solicitud_id: solicitudId
        });

        // Manejar respuesta exitosa
        if (response.status === 200) {
          this.fetchSolicitudes(false); 
          alert("La solicitud ha sido marcada como inactiva.");
        }
      } catch (error) {
        // Manejar errores
        if (error.response && error.response.status === 401) {
          alert("No estás autorizado para marcar esta solicitud como inactiva.");
        } else if (error.response && error.response.status === 400) {
          alert("Solicitud inválida. Por favor verifica el ID de la solicitud.");
        } else {
          alert("Ocurrió un error al procesar tu solicitud.");
        }
      }
    },
    cerrarModalAgregarPago() {
      this.modalAgregarPagoVisible = false;
    },
    agregarPago() {
      // Validate the input
      if (!this.nuevoPagoMonto || !this.nuevoPagoFecha || !this.nuevoPagoRecibidoPor) {
        alert('Por favor, completa todos los campos requeridos.');
        return;
      }

      const formData = new FormData();

      // Create the JSON document
      const pagoData = {
        solicitud_id: this.selectedSolicitud.id,
        monto: this.nuevoPagoMonto,
        fecha_pago: this.nuevoPagoFecha,
        recibido_por: this.nuevoPagoRecibidoPor,
      };

      // Convert JSON document to a Blob
      const jsonBlob = new Blob([JSON.stringify(pagoData)], { type: 'application/json' });

      // Append 'document' and optional 'comprobante_pago' to FormData
      formData.append('document', jsonBlob, 'document.json');

      if (this.pagoArchivoSeleccionado) {
        formData.append('comprobante_pago', this.pagoArchivoSeleccionado);
      }

      // Send the data to the server
      axios
        .post(window.hostname + 'api/property_manager/agregar_pago', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          alert('Pago agregado exitosamente.');
          this.cerrarModalAgregarPago();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          console.log('Error al agregar pago:', e);
          alert('Hubo un error al agregar el pago.');
        });
    },
    abrirModalRechazarPresupuesto(presupuesto) {
      this.selectedPresupuesto = { ...presupuesto };
      // Initialize all items as selected
      this.selectedPresupuesto.items = this.selectedPresupuesto.desgloce.items.map(item => ({
        ...item,
        selected: true,
      }));
      this.modalRechazarPresupuestoComentario = '';
      this.modalRechazarPresupuestoVisible = true;

    },

    abrirModalEnviarPresupuesto(solicitud) {
      this.contextMenuVisible = false;
      this.selectedSolicitud = solicitud;

      if (!solicitud.orden) {
        alert('No se puede enviar presupuesto porque la solicitud no tiene una orden asociada.');
        return;
      }

      if (solicitud.orden.presupuestos && solicitud.orden.presupuestos.length > 0) {
        // Ask the user if they really want to send that "presupuesto".
        if (confirm('Ya existe un presupuesto. ¿Deseas enviar uno nuevo?')) {
          this.preparePresupuestoModal();
        } 
      } else {
        // No existing presupuesto, proceed to create a new one
        this.preparePresupuestoModal();
      }
    },
    eliminarPago(pago) {

      if (confirm('¿Seguro que deseas eliminar este pago?')) {
        axios.post(window.hostname + 'api/superadmin/eliminar_pago', {pago_id:pago.id})
        .then(() => {
          alert('Pago eliminado exitosamente.');
          this.cerrarModalEnviarPresupuesto();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          alert('Hubo un error al eliminar el pago.');
        });
      } 
    },

    preparePresupuestoModal() {
      this.modalEnviarPresupuestoVisible = true;
      this.presupuestoNombre = '';
      this.presupuestoNotasAdicionales = '';
      this.presupuestoItems = [];
      if (this.selectedSolicitud && this.selectedSolicitud.orden && this.selectedSolicitud.orden.servicios) {
        this.presupuestoItems = [...this.selectedSolicitud.orden.servicios];
      }
    },
    cerrarModalEnviarPresupuesto() {
      this.modalEnviarPresupuestoVisible = false;
      this.presupuestoNombre = '';
      this.presupuestoNotasAdicionales = '';
      this.presupuestoItems = [];
    },
    async vistaPreviaPresupuesto ()  {
      if (!this.presupuestoNombre.trim()) {
        alert('Por favor, ingrese un nombre para el presupuesto.');
        return;
      }
      if (this.presupuestoItems.length === 0) {
        alert('Por favor, seleccione al menos un item.');
        return;
      }

      const presupuestoData = {
        orden_id: this.selectedSolicitud.orden.id,
        nombre: this.presupuestoNombre,
        notas_adicionales: this.presupuestoNotasAdicionales,
        items: this.presupuestoItems,
      };
      try {
        const response = await axios.post(window.hostname+'api/property_manager/vista_previa_presupuesto', presupuestoData, {
          responseType: 'blob'
        });



        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'cotizacion.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading the PDF', error);
      }
    },
    async descargarPresupuesto (presupuesto)  {


      const presupuestoData = {
        orden_id: presupuesto.orden_id,
        nombre: presupuesto.nombre,
        notas_adicionales: presupuesto.desgloce.notas_adicionales,
        items: presupuesto.desgloce.items,
      };
      try {
        const response = await axios.post(window.hostname+'api/property_manager/vista_previa_presupuesto', presupuestoData, {
          responseType: 'blob'
        });



        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'cotizacion.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading the PDF', error);
      }
    },
    enviarPresupuesto() {
      if (!this.presupuestoNombre.trim()) {
        alert('Por favor, ingrese un nombre para el presupuesto.');
        return;
      }
      if (this.presupuestoItems.length === 0) {
        alert('Por favor, seleccione al menos un item.');
        return;
      }

      const presupuestoData = {
        orden_id: this.selectedSolicitud.orden.id,
        nombre: this.presupuestoNombre,
        notas_adicionales: this.presupuestoNotasAdicionales,
        items: this.presupuestoItems,
      };
      

      axios.post(window.hostname + 'api/property_manager/crear_y_enviar_presupuesto', presupuestoData)
        .then(() => {
          alert('Presupuesto enviado exitosamente.');
          this.cerrarModalEnviarPresupuesto();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          console.log('Error al enviar presupuesto:', e);
          alert('Hubo un error al enviar el presupuesto.');
        });
    },
    copiarLinkPresupuesto(presupuesto) {
    axios.post(window.hostname + 'api/property_manager/obtener_link_presupuesto', { presupuesto_id: presupuesto.id })
      .then((response) => {
        const link = response.data.link; // Assuming 'link' is returned in response
        navigator.clipboard.writeText(link)
          .then(() => {
            alert('Link copiado.');
          })
          .catch((err) => {
            console.log('Error copiando link:', err);
            alert('Error copiando link.');
          });
      })
      .catch((error) => {
        console.log('Error fetching link:', error);
        alert('There was an error fetching the link.');
      });
  },
    reenviarPresupuesto(presupuesto) {
      axios.post(window.hostname + 'api/property_manager/reenviar_presupuesto', {presupuesto_id:presupuesto.id})
        .then(() => {
          alert('Presupuesto enviado exitosamente.');
          this.cerrarModalEnviarPresupuesto();
          this.fetchSolicitudes(false); // Refresh data
        })
        .catch((e) => {
          console.log('Error al enviar presupuesto:', e);
          alert('Hubo un error al enviar el presupuesto.');
        });
    },
    rechazarPresupuesto(presupuesto) {
    axios.post(window.hostname + 'api/property_manager/rechazar_presupuesto', {
      presupuesto_id: presupuesto.id,
      comment: this.modalRechazarPresupuestoComentario // Optional comment from modal
    })
    .then(() => {
      alert('Presupuesto rechazado exitosamente.');
      this.cerrarModalRechazarPresupuesto();
      this.fetchSolicitudes(false); // Refresh data
    })
    .catch((e) => {
      console.log('Error al rechazar presupuesto:', e);
      alert('Hubo un error al rechazar el presupuesto.');
    });
  },
  cerrarModalRechazarPresupuesto() {
    this.modalRechazarPresupuestoVisible = false;
    this.modalRechazarPresupuestoComentario = '';
  },


    // Event handler for closing the context menu
    handleWindowClick(event) {
      const ignoredClasses = ['context-menu'];
      if (!ignoredClasses.some(className => event.target.classList.contains(className))) {
        this.contextMenuVisible = false;
      }

      if (!this.$el.contains(event.target)) {
        this.contextMenuVisible = false;
      }
    },
    dragStart(event) {
      this.isDragging = true;
      this.preventClick = false;
      this.$refs.tableContainer.classList.add('active');
      if (event.type === 'touchstart') {
        this.startX = event.touches[0].pageX - this.$refs.tableContainer.offsetLeft;
      } else {
        this.startX = event.pageX - this.$refs.tableContainer.offsetLeft;
      }
      this.scrollLeft = this.$refs.tableContainer.scrollLeft;
    },
    dragMove(event) {
      if (!this.isDragging) return;
      event.preventDefault();
      let x;
      if (event.type === 'touchmove') {
        x = event.touches[0].pageX - this.$refs.tableContainer.offsetLeft;
      } else {
        x = event.pageX - this.$refs.tableContainer.offsetLeft;
      }
      const deltaX = x - this.startX;
      if (Math.abs(deltaX) > 5) {
        this.preventClick = true;
      }
      const walk = deltaX * 1;
      this.$refs.tableContainer.scrollLeft = this.scrollLeft - walk;
    },
    dragEnd() {
      this.isDragging = false;
      this.$refs.tableContainer.classList.remove('active');
    },
  },
  created() {
    if (!this.admin_datos || (this.admin_datos.user_type !== 'property_manager' && this.admin_datos.user_type !== 'jobbing_admin')) {
      this.$router.push('/');
    } else {
      this.fetchSolicitudes();
    }
    window.addEventListener('click', this.handleWindowClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleWindowClick);
  },
};
</script>

<style scoped>
.tooltip-box {
  position: fixed;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  white-space: pre-wrap;
}
.custom-tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.custom-tooltip-wrapper .custom-tooltip-text {
  visibility: hidden;
  font-size: 14px;

  background-color: rgb(40,40,40);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  position: absolute;
  bottom: 110%; /* Adjusted position */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-tooltip-wrapper .custom-tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%; /* Position the arrow at the bottom of the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: rgb(40,40,40) transparent transparent transparent; /* Black arrow pointing up */
}

.custom-tooltip-wrapper:hover .custom-tooltip-text {
  visibility: visible;
  opacity: 1;
}
.tabs {
  display: flex;

}

.tabs button {
  padding: 10px 20px;
  border: none;
  background-color: #f1f1f1;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s;
}

.tabs button.active-tab {
  background-color: #007BFF;
  color: white;
}

.tabs button:hover {
  background-color: #ddd;
}
.nowrap{
  white-space:nowrap;
}
/* Estilo para el contenedor de encabezado */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Estilo para el contenedor del filtro de fecha */
.date-filter-container {
  display: flex;
  align-items: center;
  justify-content: center;

}

/* Estilo para la etiqueta del filtro de fecha */
.date-filter-label {
  margin-right: 5px;
  font-weight: bold;
  margin-top: 8px;
}

/* Estilo para el selector de fecha */
.date-filter-select {
  padding: 5px;
  font-size: 14px;
}


.star{
  cursor: pointer;
  margin-right:4px;
  height:18px;
  width: 18px;
  color:#DAA520;
}

.sectionv{
  /* background-color: rgb(250,250,250); */
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

h2{
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 30px;
}
h3{
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}

.yellowish {
 color:#FFDC00;
 font-weight: 700;
}
.table-container.active {
  cursor: grabbing;
}

.table-container {
  cursor: grab;
  overflow-x: auto;
  /* user-select: none; */
}

.tablex {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  margin-top:0px;
}

.tablex th,
.tablex td {
  padding: 12px 15px;
  text-align: left;
}

.tablex thead th {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
}

.tablex tbody tr {
  border-bottom: 1px solid #ddd;
}

.tablex tbody tr:hover {
  /* background-color: #f9f9f9; */
}

.details-container {
  padding: 15px;
  /* background-color: #f9f9f9; */
}

.details-container h3 {
  margin-top: 0;
}

.fotos-container {
  display: flex;
  flex-wrap: wrap;
}

.fotos-container img {
  width: 150px;
  height: auto;
  margin: 5px;
  border: 1px solid #ccc;
}

.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 80px;
  padding-left: 260px;
  width: 100%;
}

.main-container {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
}

@media (max-width: 800px) {
  .column-right {
    padding-left: 0px;
  }
}
/* Context Menu Styles */
.context-menu {
  position: fixed;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  font-size: 14px;
  width: 200px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

.context-menu ul {
  list-style: none;
  margin: 0;
  padding: 5px 0;
}

.context-menu ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu ul li:hover {
  background-color: #f4f4f4;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1100; /* Increased z-index to ensure it's above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modall {
  background-color: white;
  padding: 20px;
  width: 600px;
  max-width: 90%;
  border-radius: 5px;
  z-index: 11001;
}

.modall h3 {
  margin-top: 0;
}

.modall textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
}

.modal-buttons {
  margin-top: 15px;
  text-align: right;
}

.modal-buttons button {
  padding: 8px 12px;
  margin-left: 10px;
}

/* Ensure context menu is above other elements */
.table-container {
  position: relative;
}
.download-button {
  background-color: #007bff; /* Green background */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 8px 16px; /* Button padding */
  font-size: 14px; /* Font size */
  cursor: pointer; /* Pointer on hover */
  border-radius: 4px; /* Rounded corners */
  text-decoration: none; /* Remove underline for link buttons */
  display: inline-block;
  white-space: nowrap;
}
.red-button {
  background-color: #FF4136; /* Green background */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 8px 16px; /* Button padding */
  font-size: 14px; /* Font size */
  cursor: pointer; /* Pointer on hover */
  border-radius: 4px; /* Rounded corners */
  text-decoration: none; /* Remove underline for link buttons */
  display: inline-block;
  white-space: nowrap;
}
.red-button:hover{
  background-color: #ff7871;
}

.download-button:hover {
  background-color: #3589e4; /* Darker green on hover */
}

.fotos-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.image-wrapper {
  position: relative;
  display: inline-block;
}

.image-wrapper img {
  width: 150px;
  height: auto;
  margin: 5px;
  border: 1px solid #ccc;
}

.hover-message {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 14px;
}

.image-wrapper:hover .hover-message {
  opacity: 1;
}
</style>
