<template>
  <div>
    
<transition name="slide">

  <div v-if="window.width >= 800 || sideMenu" class="sidenav-main">
    <img :src="admin_datos.empresa && admin_datos.empresa.atributos && admin_datos.empresa.atributos.logo_filename ? admin_datos.empresa.atributos.logo_filename : require('@/assets/logolight.webp')"  style="height:40px; z-index:10003; transform: translate(20px, -55px);" alt="Imagen">
    <div v-if="admin_datos.user_type == 'jobbing_admin'" class="main-scrollable">


      <div class="nav-label">
        Navegación
      </div>
      <ul>
        <router-link v-if="admin_datos.superadmin" tag="li" to="/Dashboard" ><b-icon icon="layout-split" class="arrows" />Dashboard</router-link>

        <router-link v-else tag="li" to="/" ><b-icon icon="layout-split" class="arrows" />Inicio </router-link>
        <router-link  tag="li" to="/Reportes" ><b-icon icon="graph-up" class="arrows" />Estadísticas </router-link>
        <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/Mailing" ><b-icon icon="mailbox" class="arrows" />Mailing</router-link>

        <router-link  tag="li" to="/VerProfesionales" ><b-icon icon="person-fill" class="arrows" />Profesionales </router-link>
        <router-link  tag="li" to="/Ordenes" ><b-icon icon="cash" class="arrows" />Ordenes </router-link>
        <router-link  tag="li" to="/Mapa" ><b-icon icon="map" class="arrows" />Mapa </router-link>
        <router-link  tag="li" to="/ObtenerApp" ><b-icon icon="phone" class="arrows" />Obtener App </router-link>
<!--         <li><b-icon icon="globe" class="icons" />Información general</li> -->
      </ul>
      <div class="nav-label">
        Empresa
      </div>
      <ul>
          <!-- <li><b-icon icon="person" class="icons" />Por profesional</li>-->
        <!--<li><b-icon icon="gear" class="icons" />Por Máquina</li>-->
<!--          <router-link tag="li" to="/Profile" ><b-icon icon="person" class="arrows" />Perfil </router-link> -->
         <router-link v-if="admin_datos.superadmin" tag="li" to="/AgendaManual" ><b-icon icon="pencil" class="arrows" />Agenda manual</router-link>
         <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/Pagos" ><b-icon icon="truck" class="arrows" />Pagos a proovedores</router-link>
         <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/Transferencias" ><b-icon icon="cash-coin" class="arrows" />Transferencias</router-link>
         <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/ResponderSolicitudes" ><b-icon icon="reply" class="arrows" />Responder solicitudes</router-link>
         <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/CotizacionManual" ><b-icon icon="newspaper" class="arrows" />Cotización Manual</router-link>
         <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/Blog" ><b-icon icon="newspaper" class="arrows" />Blog</router-link>
         <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/Aplicante" ><b-icon icon="person" class="arrows" />Aplicantes</router-link>
         <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/Encuestas" ><b-icon icon="chat-right-text" class="arrows" />Encuestas</router-link>
         </ul>
         <div class="nav-label">
          Administradores de Propiedades
      </div>
      <ul>
         <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/PropertyManagerManagement" ><b-icon icon="house" class="arrows" />Administradores</router-link>
         <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/EmpresaAdministracionManagement" ><b-icon icon="building" class="arrows" />Empresas</router-link>
         <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/AsignarProfesionales" ><b-icon icon="person" class="arrows" />Asignar profesionales</router-link>
         <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/PagoEmpresasAdministracion" ><b-icon icon="truck" class="arrows" />Pago a Empresa</router-link>
         <router-link  tag="li" to="/administrador-propiedades/Solicitudes" ><b-icon icon="clipboard-data" class="arrows" />Datos </router-link>
      </ul>
      <br/>
<!--
      <div >
       <div class="nav-label">
        Configuración
      </div>
      <ul>
        <li :class="sucursales ? 'liclicked': ''" v-on:click="sucursales=!sucursales"><b-icon icon="building" class="icons" />Sucursales <b-icon icon="caret-right" :class="sucursales ? 'miniarrowActive': 'miniarrow'"  /></li>
        <div class="subitems" :class="sucursales ? 'isActive':'isInActive'"> 
          <ul class="subul">
          <router-link tag="li" to="/AdministrarSucursal" ><b-icon icon="arrow-right-short" class="arrows" />Ver sucursales </router-link>          
          <router-link tag="li" to="/AgregarSucursal" ><b-icon icon="arrow-right-short" class="arrows" />Crear sucursales  </router-link>

          </ul> 
        </div>
        
        <router-link tag="li" to="/AdministrarPrestaciones" ><b-icon icon="journals" class="icons" />Prestaciones</router-link>
        <router-link tag="li" to="/AgregarFunciones" ><b-icon icon="tablet" class="arrows" />Funciones</router-link>
        <router-link tag="li" to="/AdministrarPersonal" ><b-icon icon="person" class="icons" />Personal</router-link> 
        <router-link tag="li" to="/AdministrarMaquinas" ><b-icon icon="tv" class="icons" />Máquinas</router-link>  
        <router-link tag="li" to="/AdministrarServicios" ><b-icon icon="shop-window" class="icons" />Servicios</router-link>
        <router-link tag="li" to="/Instituciones" ><b-icon icon="briefcase" class="icons" />Instituciones</router-link>      
        <router-link tag="li" to="/AdministrarPrecios" ><b-icon icon="cash-stack" class="icons" />Precios</router-link>           
        <router-link tag="li" to="/Configuracion" ><b-icon icon="gear" class="icons" />Configuraciones</router-link>  
        
      </ul>
      </div>
-->
    </div>
    <div v-else-if="admin_datos.user_type == 'property_manager'" class="main-scrollable">


<div class="nav-label">
  Navegación
</div>
<ul>
  <router-link tag="li" to="/" ><b-icon icon="layout-split" class="arrows" />Inicio </router-link>

  <router-link  tag="li" to="/Reportes" ><b-icon icon="graph-up" class="arrows" />Estadísticas </router-link>
  <router-link v-if="admin_datos.superadmin" tag="li" to="/superadmin/Mailing" ><b-icon icon="mailbox" class="arrows" />Mailing</router-link>

  <router-link  tag="li" to="/administrador-propiedades/Solicitudes" ><b-icon icon="clipboard-data" class="arrows" />Datos </router-link>
  <router-link  tag="li" to="/administrador-propiedades/TransferenciasEmpresa" ><b-icon icon="currency-dollar" class="arrows" />Transferencias </router-link>
  <router-link  tag="li" to="/administrador-propiedades/ProfesionalesAdmPropiedades" ><b-icon icon="person" class="arrows" />Profesionales </router-link>
  <router-link  tag="li" to="/administrador-propiedades/CrearSolicitud" ><b-icon icon="star" class="arrows" />Nueva Solicitud </router-link>


  <!-- <li><b-icon icon="globe" class="icons" />Información general</li> -->
</ul>


</div>
  </div>
  </transition>
  <div v-on:click="CambiarMenuAction({})" v-if="window.width < 800 && sideMenu" style="position:fixed; width:133vw; height:133vh; background:rgba(0,0,0,0.4); z-index:9999; " ></div>
</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
export default {
  name: 'SideNav',
  components:{
  },
    data(){

    return{
      sucursales: false,
      prestaciones: false,
      funciones: false,
      recursos: false,
      presonal: false,
      maquinas: false,
      window: {
        width: 0,
        height: 0
      },
    }
  },
  mounted() {
  },
  computed:{
    ...mapGetters(['admin_datos', 'isLoggedIn', 'sideMenu']),
  },
  created () {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
    destroyed() {
    window.removeEventListener('resize', this.handleResize);

  },
  methods: {
    ...mapActions(['CambiarMenuAction']),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.miniarrow{
  float:right;
  margin-right:20px;
  transition-duration: 0.25s;
}
.miniarrowActive{
  float:right;
  margin-right:20px;
  color:white;
  transform:rotate(90deg);
  transition-duration: 0.25s;
}

.liclicked{
  background-color: rgb(29, 37, 48) !important;
  color:white !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(38, 53, 67);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(23, 32, 40);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(23, 32, 40);
}

.arrows{
  font-size: 20px;
  margin-right:10px;
  transform: translate(0px, 3px);
}
.subul{
  padding:0px;
}
.main-scrollable{
  overflow-y: scroll;
  height:100%;
  padding-top:20px;
  position:relative;
   background-color: rgb(38, 53, 67);
  z-index: 100;
}

.subitems{
  background-color: rgb(34, 45, 58) !important;
  overflow: hidden; 
  border-left: 4px solid white;
}
.subitems ul{
  background-color: rgb(34, 45, 58) !important;
}
.subitems li{
  background-color: rgb(34, 45, 58) !important;
  padding:10px 0px 10px 20px !important;

}
.isActive{
  max-height:450px;
  transition: max-height 0.25s ease-in;
}
.isInActive{
  max-height:0px;
  padding:0px;
  transition: max-height 0.15s ease-out;
}

.icons{
  margin: 0px 10px 0px 0px;
  font-size: 20px;
}
.sidenav-main{
  width:250px;
  height:100%;
  text-align: left;
  background-color: rgb(38, 53, 67);
  padding-top:80px;
  position:fixed;
  z-index: 10000;


}
.nav-label{
  color: white;
  font-size: 12px;
  padding: 0px 0px 0px 14px;
   background-color: rgb(38, 53, 67);
}
.sidenav-main ul{
  padding: 10px 0px 10px 0px;
  list-style-type: none;
  color: rgba(255,255,255,0.5);
  background-color: rgb(38, 53, 67);
  font-size: 14px;
}
.sidenav-main li:hover{


  color: rgba(255,255,255,1);

}

.sidenav-main li{
  padding: 10px 0px 10px 20px;
  cursor: pointer;
  background-color: rgb(38, 53, 67);
  
}
h3 {
  margin: 40px 0 0;
}

a {
  color: #42b983;
}
.slide-enter-active,
.slide-leave-active {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: slide;
}

.slide-leave-active {
  animation-direction: reverse;
}

@keyframes slide {
  from {
    opacity: 1;
    transform: translateX(-300px);
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 800px) {
  .sidenav-main {
    position: fixed !important;
    top:0px;
    left:0px;
    
    max-width:220px;
  }

}


</style>
