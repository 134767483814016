<template>
  <div class="app-container">
    <TopNav />
    <div class="layout">
      <SideNav class="side-nav" />
      <div class="main-content">
        <ModalNuevoProfesional ref="modalnuevoprofesional" />
        <div class="main-container">
          <div v-if="loaded" class="content-card">
            <div class="card-body">
              <table class="table table-hover">
                <thead class="table-light">
                  <tr>
                    <th>Nombre</th>
                    <th>Calificación</th>
                    <th>Estado</th>
                    <!-- New column shows only the accepted ratio -->
                    <th>Presupuestos</th>
                    <th>Contacto</th>
                    <th class="text-end">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr 
                    v-for="p in profesionales" 
                    :key="p.profesional.id" 
                    :class="{'suspended-row': p.profesional.suspendido == 1}"
                  >
                    <td>
                      <img 
                        class="avatar" 
                        :src="p.profesional.foto ? p.profesional.foto : 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'" 
                        alt="Imagen profesional"
                      >
                      <router-link :to="'VerProfesional/'+p.profesional.id" class="prof-name">
                        {{ p.profesional.nombre + ' ' + p.profesional.apellido }}
                      </router-link>
                    </td>
                    <td>
                      <div v-if="p.clasificacion[0] === 0">
                        <em>Sin calificaciones</em>
                      </div>
                      <div v-else>
                        <span 
                          v-for="s in Math.trunc(p.clasificacion[1] / p.clasificacion[0])" 
                          :key="s"
                        >
                          <b-icon class="star" icon="star-fill"></b-icon>
                        </span>
                        <span v-if="(p.clasificacion[1] / p.clasificacion[0]) % 1 >= 0.5">
                          <b-icon class="star" icon="star-half"></b-icon>
                        </span>
                        <span 
                          v-for="s in Math.floor(5 - (p.clasificacion[1] / p.clasificacion[0]))" 
                          :key="s"
                        >
                          <b-icon class="star" icon="star"></b-icon>
                        </span>
                        <span>
                          ({{ (p.clasificacion[1] / p.clasificacion[0]).toFixed(2) }}, {{ p.clasificacion[0] }} órdenes)
                        </span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span 
                          v-if="p.profesional.atendiendo_orden" 
                          class="badge badge-success"
                        >
                          Atendiendo <b-icon icon="eye"></b-icon>
                        </span>
                        <span 
                          v-else 
                          class="badge badge-info"
                        >
                          Libre
                        </span>
                        <span 
                          v-if="p.profesional.suspendido == 1" 
                          class="badge badge-warning"
                        >
                          Suspendido
                        </span>
                        <span 
                          v-if="p.profesional.oculto == 1" 
                          class="badge badge-secondary"
                        >
                          Oculto
                        </span>
                        <span 
                          v-if="p.profesional.visibilidad_restringida == 1" 
                          class="badge badge-dark"
                        >
                          Visibilidad Restringida
                        </span>
                      </div>
                    </td>
                    <!-- New Presupuestos column: display ratio and tooltip -->
                    <td>
                      <div v-if="p.profesional.presupuesto_count > 0" class="css-tooltip">
                        <span>{{ ratioText(p.profesional) }}</span>
                        <div class="tooltip-content">
                          {{ tooltipContent(p.profesional) }}
                        </div>
                      </div>
                      <span v-else>
                        Sin presupuestos
                      </span>
                    </td>
                    <td>
                      <div class="contact-icons">
                        <b-icon icon="whatsapp"></b-icon>
                        <b-icon icon="letter"></b-icon>
                      </div>
                    </td>
                    <td class="text-end">
                      <router-link :to="'VerProfesional/'+p.profesional.id" class="btn btn-icon" title="Editar">
                        <b-icon icon="gear"></b-icon>
                      </router-link>
                      <button
                        class="btn btn-icon"
                        @click="resetPassword(p.profesional)"
                        title="Resetear Contraseña">
                        <b-icon icon="key"></b-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <div class="footer-info">
                Mostrando {{ profesionales.length }} profesionales de los {{ profesionales.length }} encontrados
              </div>
              <div class="footer-actions">
                <button @click="showCrearModal" class="btn btn-primary">
                  Crear un nuevo profesional
                </button>
                <button @click="openGenerateContractModal" class="btn btn-secondary">
                  Generar Contrato
                </button>
              </div>
            </div>
          </div>
          <div v-else class="loading-container">
            Cargando...
          </div>
        </div>
      </div>
    </div>

    <!-- Generate Contract Modal -->
    <div 
      v-if="isGenerateModalVisible" 
      class="custom-modal-overlay" 
      @click.self="closeGenerateContractModal"
    >
      <div class="custom-modal">
        <h3>Generar Contrato</h3>
        <form @submit.prevent="generateContract">
          <div class="form-group">
            <label for="nombreProfesional">Nombre del Profesional:</label>
            <input 
              type="text" 
              id="nombreProfesional" 
              v-model="contractForm.nombre_profesional" 
              class="form-control" 
              placeholder="Ingrese el nombre completo del profesional" 
              required
            >
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-primary" :disabled="loading">
              Descargar Contrato
            </button>
            <button 
              type="button" 
              class="btn btn-secondary" 
              @click="closeGenerateContractModal" 
              :disabled="loading"
            >
              Cancelar
            </button>
          </div>
        </form>
        <div v-if="loading" class="loading-indicator">
          Cargando...
        </div>
        <div v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import ModalNuevoProfesional from '@/components/ModalNuevoProfesional.vue';

export default {
  name: 'Profesionales',
  props: ['id'],
  components: {
    SideNav,
    TopNav,
    ModalNuevoProfesional,
  },
  data() {
    return {
      loaded: false,
      profesionales: [],
      isGenerateModalVisible: false,
      contractForm: {
        nombre_profesional: '',
      },
      loading: false,
      errorMessage: '',
    };
  },
  created() {
    this.initialize_();
  },
  methods: {
    ...mapActions(['initialize']),
    showCrearModal() {
      this.$refs.modalnuevoprofesional.show();
    },
    initialize_() {
      this.loaded = false;
      axios.get(`${window.hostname}api/admin/getprofesionales`)
        .then(response => {
          this.loaded = true;
          this.profesionales = response.data.profesionales;
        })
        .catch(e => {
          this.loaded = true;
          console.error("Error al cargar profesionales:", e);
        });
    },
    openGenerateContractModal() {
      this.contractForm.nombre_profesional = '';
      this.isGenerateModalVisible = true;
      this.errorMessage = '';
    },
    closeGenerateContractModal() {
      this.isGenerateModalVisible = false;
      this.loading = false;
      this.errorMessage = '';
    },
    generateContract() {
      const nombre_profesional = this.contractForm.nombre_profesional.trim();
      if (!nombre_profesional) {
        this.errorMessage = "El nombre del profesional es requerido.";
        return;
      }
      this.loading = true;
      this.errorMessage = '';
      const apiUrl = `${window.hostname}api/superadmin/generate_contract?nombre_profesional=${encodeURIComponent(nombre_profesional)}`;
      axios.get(apiUrl, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const filename = `acuerdo_${nombre_profesional.replace(/ /g, '_')}.pdf`;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.closeGenerateContractModal();
      })
      .catch(error => {
        console.error("Error al generar el contrato:", error);
        if (error.response && error.response.data) {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const json = JSON.parse(reader.result);
              this.errorMessage = json.error || "Ocurrió un error al generar el contrato.";
            } catch (e) {
              this.errorMessage = "Ocurrió un error al generar el contrato.";
            }
          };
          reader.readAsText(error.response.data);
        } else {
          this.errorMessage = "Ocurrió un error al generar el contrato. Por favor, inténtalo de nuevo.";
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },
    // Returns the ratio text for presupuestos accepted vs total
    ratioText(prof) {
      return `${prof.presupuesto_aceptado_count} / ${prof.presupuesto_count} (${(prof.presupuesto_aceptado_count / prof.presupuesto_count).toFixed(3)*100}%)`;
    },
    // Returns a tooltip string with detailed presupuesto info
    tooltipContent(prof) {
      return `Enviados: ${prof.presupuesto_enviado_count}, Aceptados: ${prof.presupuesto_aceptado_count}, Rechazados: ${prof.presupuesto_rechazado_count}, Total: ${prof.presupuesto_count}`;
    },
    // New method to reset a professional's password
    resetPassword(profesional) {
      if (!confirm(`¿Estás seguro de que deseas restablecer la contraseña de ${profesional.nombre} ${profesional.apellido}?`)) {
        return;
      }
      axios.post(`${window.hostname}api/admin/reset_profesional_password`, { email: profesional.email })
        .then(response => {
          alert("Contraseña restablecida correctamente. Se ha enviado un correo con la nueva contraseña.");
        })
        .catch(error => {
          console.error("Error reseteando la contraseña:", error);
          alert("Hubo un error al restablecer la contraseña. Inténtalo de nuevo.");
        });
    },
  },
  mounted() {
    this.$root.$on('update-profesionales', () => {      
      this.initialize_();
    });
  },
};
</script>

<style scoped>
.css-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-content {
  visibility: hidden;
  width: max-content;
  max-width: 200px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  padding: 5px 8px;
  border-radius: 4px;
  position: absolute;
  z-index: 100;
  bottom: 125%; /* positions tooltip above the text */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

/* Optional arrow for the tooltip */
.tooltip-content::after {
  content: "";
  position: absolute;
  top: 100%; /* bottom of tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.css-tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.layout {
  display: flex;
}

.side-nav {
  width: 250px;
}

.main-content {
  flex: 1;
  background-color: #f2f7fb;
  padding: 80px 20px 20px 20px;
}

.main-container {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.content-card {
  margin-bottom: 20px;
}

.card-header {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.card-body {
  overflow-x: auto;
}

.table {
  width: 100%;
  margin-bottom: 0;
}

.avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.prof-name {
  font-weight: 600;
  vertical-align: middle;
}

.star {
  color: #DAA520;
  margin-right: 2px;
}

.badge {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  margin-right: 5px;
}

.badge-success {
  background-color: #2ECC40;
  color: #fff;
}

.badge-info {
  background-color: #0074D9;
  color: #fff;
}

.badge-warning {
  background-color: #e74c3c;
  color: #fff;
}

.badge-secondary {
  background-color: #422db4;
  color: #fff;
}

.badge-dark {
  background-color: #282828;
  color: #fff;
}

.suspended-row {
  background-color: #f8d7da;
}

.text-end {
  text-align: right;
}

.footer-info {
  font-size: 14px;
  color: #777;
}

.footer-actions {
  margin-top: 15px;
  display: flex;
  gap: 10px;
}

.btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
}

.btn-icon {
  background: none;
  border: none;
  cursor: pointer;
}

.loading-container {
  text-align: center;
  padding: 20px;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.custom-modal h3 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.loading-indicator,
.error-message {
  text-align: center;
  margin-top: 10px;
}

.error-message {
  color: #dc3545;
}
</style>
