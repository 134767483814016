<template>
<div class="main-n">
  
     <div  v-if="dropdownMenu && isLoggedIn"  class="dropdown-menu-x">
       
     <div v-if="!isLoggingOut">
     <div style="text-align:center; width:100%;">
<div class="numberCircle" style="margin: 20px auto;">{{admin_datos.nombre[0]}}</div>
</div>
<h4 style="font-size: 20px;"></h4>
<p style="font-size: 14px; color:rgba(17,17,17,0.6);"></p>

<hr />

<div style="padding: 10px 10px;">
  Iniciado como {{admin_datos.nombre}} {{admin_datos.apellido}} <br/>
  
</div>
<p style="cursor:pointer; text-align:center;" class="linky" v-on:click="sendLogOut">Salir</p>
<hr />
<p style="font-size:13px; text-align:center;">Sobre Jobbing.cl | Más información</p>
  
  </div>
  <div v-else style="min-height:445px;">
    <div class="loader-wrapper">
 
    <div class="spinner-border text-secondary" style="margin: 0 auto; " role="status"></div> <div>Cerrando sesión...</div>

     
    </div>
  </div>

   </div>


  <div class="main-top-nav">

      <div class="logo">
        
        <div v-on:click="CambiarMenuAction({})" style="font-size:14px; display:inline-block;">
                <div class="hamburguesa" v-if="window.width < 800">
      <label for="check">
         
        <span :class="{'checkeado1': sideMenu}" :style="{'background':  sideMenu ? 'rgb(120,120,120)' : 'black'}"></span>
        <span :class="{'checkeado2': sideMenu}" :style="{'background':  sideMenu ? 'rgb(120,120,120)' : 'black'}"></span>
        <span :class="{'checkeado3': sideMenu}" :style="{'background':  sideMenu ? 'rgb(120,120,120)' : 'black'}"></span>

      </label>
      </div>
          </div>
          
        <img :src="admin_datos.empresa && admin_datos.empresa.atributos && admin_datos.empresa.atributos.logo_filename ? admin_datos.empresa.atributos.logo_filename : require('@/assets/logolight.webp')" style="height:40px; z-index:100003;" alt="Imagen">
        <transition name="slide">
        <div v-if="window.width >= 800 || sideMenu" class="logo-c" ></div>
        </transition>
      </div>
      

      

        <!--
      <div class="buscar-barra">
        <input type="text" class="input-buscar dontClose" placeholder="Busca un paciente por nombre o rut" v-model="buscando">

        <div v-if="mostrando" class="buscar-resultados">
          <div v-if="!cargando">
            <div v-if="resultados.length>0" class="resultado" v-for="r, i in resultados">
              <router-link tag="div" style="cursor:pointer;" class="" :to="'/Paciente/'+r.id">   
              <b-icon icon="person" style="" class="buscandoIcons" /> {{r.nombre}} {{r.apellido1}} <div style="float:right; margin-top:5px;">{{r.rut}}-{{dgv(r.rut)}}</div> <hr v-if="i != resultados.length-1"/>
              </router-link>
            </div>
          <div v-if="resultados.length == 0" class="resultado">
                <span>¡No se encontraron pacientes!</span>
          </div>  
          </div>
          <div v-if="cargando">
            <div style="text-align:center;"><div class="spinner-border text-secondary" style=" " role="status"></div> Cargando... </div>
          </div>
        </div>
        <b-icon style="transform:translateX(-15px);" icon="search"></b-icon>
      </div>
      -->

        <div class="usuario">
          <div style="margin-right:20px;" @click.prevent="toggleDropdown"><div class="numberCircle" style="margin: 18px auto;">{{admin_datos.nombre[0]}}</div><div style="transform:translate(4px, -2px); display:inline-block;"> </div>  <b-icon icon="chevron-down" style="margin-left:5px; transform:translate(0px, -2px)" class="editIcons" /></div>
        </div>
      

  </div>
  
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import axios from 'axios'

export default {
  
  name: 'TopNav',
  data(){
    return{
      dropdownMenu:false,
      isLoggingOut:false,
      buscando:'',
      resultados:[],
      mostrando: false,
      cargando:false,
       window: {
        width: 0,
        height: 0
      },
    }
  },
  components:{
  },
  watch:{


  },
    created () {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
    destroyed() {
    window.removeEventListener('resize', this.handleResize);

  },
  methods:{
    ...mapActions(['logoutpro', 'CambiarMenuAction']),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },


    sendLogOut(){
      this.isLoggingOut = true;
      setTimeout(()=>{this.isLoggingOut = false; this.logoutpro().then(this.$router.push('/Login'))}, 1500)
    },
    toggleDropdown (e) {
      this.dropdownMenu = !this.dropdownMenu
    },
    close (e) {
      if (!this.$el.contains(e.target)) { /*Si clickeo (e.target) fuera de este componente (this.$el), se cierra*/
        if (!(e.target.classList.contains('linky')))
          this.dropdownMenu = false
      }
      if (!(e.target.classList.contains('dontClose')))
      {
        this.mostrando = false
        this.buscando = '';
      }
    },
  },
    computed: {
  ...mapGetters(['admin_datos', 'isLoggedIn', 'sideMenu']),
  },
  mounted() {
    document.addEventListener('click', this.close)    
  },
  beforeDestroy () {
    document.removeEventListener('click',this.close)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-n{
  z-index: 10001;

  background-color: red;
}
.logo{
  height:100%;
  width:250px;
  display:block;
  float:left;  
}
.logo img{
  z-index: 9999;
}
.logo-c{
  height:100%;
  width:250px;
  position:absolute;
  top:0px;
  left:0px;
  z-index: -1;

  background-color: rgb(38, 53, 67);
}
.buscar-resultados{
  background: white;
  border: 1px solid rgba(0,0,0,0.2);
  width:300px;
  position: absolute;
  top: 56px;
  padding-bottom:15px;
  padding-top:15px;
  max-height:400px;
  overflow-y: scroll;

}

.buscandoIcons{
  color:#00B4CC;
  height:24px;
  width:24px;
  transform:translate(-3px, 3px);
}
.resultado{
  text-decoration: none;
  padding:5px 10px 5px 10px;
  /*border-top: 1px solid black;*/
}

.input-buscar{
  width:300px;
  border: 1px solid #00B4CC;
  border-top: none;
  border-right: none;
  border-left: none;

  padding: 15px 15px 15px 5px;
  height: 20px;
  outline: none;
}
.input-buscar::placeholder{
  color:rgba(0,0,0,0.4)
}
.buscar-barra{
  position:relative;
  padding: 25px 0;
  height:100%;
  vertical-align: middle;
  margin: auto;
  padding-left: 40px;
}
.logo img{
  margin-top:25px;
  margin-left:20px;
}
.usuario{
  position:absolute;
  right:4px;
  top:0px;
  height:100%;
  padding-right:20px;
  cursor: pointer;

}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.right{
  background-color: white;
  display:block;
  height:100%;
  width: Calc(100% - 250px);
  text-align: left;
  margin-left:0px;
  float:left;

}
.main-top-nav{
  position:fixed;
  height:80px;
  width:100%;
      -webkit-box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  background: white;
  z-index:999;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.numberCircle {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    padding: 8px;

    background: #00B4CC;
    border: 2px solid #fff;
    color: #fff;
    text-align: center;
    display:inline-block;

    font-size: 18px;
}
.dropdown-menu-x{
  position:fixed;
   height:auto;
   width:300px;
   max-width:90%;
   right:20px;
   top:90px;
   z-index: 10000;
   background: white;
   border-radius: 5px 5px 5px 5px;
   border: solid 1px rgba(0,0,0,0.2);
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.loader-wrapper{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/*Icono hamburguesa*/
.hamburguesa{
  position: absolute;
  top:25px;
  left:120px;
  z-index: 10;
}
label{
 display:flex;
  flex-direction:column;
  width:30px;
  cursor:pointer;
}

label span{
  background: #fff;
  border-radius:10px;
  height:3px;
  margin: 3px 0;
  transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

}


span:nth-of-type(1){
  width:50%;
  
}

span:nth-of-type(2){
  width:100%;
}


span:nth-of-type(3){
  width:75%;
 
}





.checkeado1{
  transform-origin:bottom;
  transform:rotatez(45deg) translate(3px,0px)
}


.checkeado2{
  
  transform-origin:top;
  transform:rotatez(-45deg)
}


.checkeado3{
  
  transform-origin:bottom;
  width:50%;
  transform: translate(7px,-7px) rotatez(45deg);

}
/*Termina Icono Hamburguesa*/


@media (max-width: 800px) {
  .logo{
    width:220px;
  }
  .logo-c{
    width:220px;
  }
  .main-n{
    position: fixed;
    top:0px;
  }
}

.slide-enter-active,
.slide-leave-active {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: slide;
}

.slide-leave-active {
  animation-direction: reverse;
}

@keyframes slide {
  from {
    opacity: 1;
    transform: translateX(-300px);
  }

  100% {
    opacity: 1;
  }
}
</style>
